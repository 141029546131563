// src/components/Invoices/InvoiceManager/InvoiceDashboard.jsx
import React from 'react';

const InvoiceDashboard = () => {
  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Invoice Dashboard</h1>
      {/* Dashboard content will go here */}
    </div>
  );
};

export default InvoiceDashboard;