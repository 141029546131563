// src/App.js
import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import {
  AuthProvider,
  useAuth,
  useRequireCleaner,
  useRequireMember,
} from "./context/AuthContext"; // Added imports here
import { TaskBoardProvider } from "./context/TaskBoardContext";
import { JobProvider } from "./context/JobContext";
import { GoogleMapsProvider } from "./components/GoogleMapsProvider";
import ClientSalesTools from "./components/ClientSalesTools";
import Layout from "./components/Layout";
import TaskBoard from "./components/TaskBoard";
import OrgSettings from "./components/OrgSettings";
import { TimezoneProvider } from "./context/TimeZoneContext";
import CleanerJobView from "./components/CleanerJobView";
import CleanerJobsOverview from "./components/CleanerJobsOverview";
import CleanerDashboard from "./components/CleanerDashboard";
import CreateJobForm from "./components/CreateJobForm";
import CustomerProfileManagement from "./components/CustomerProfileManagement";
import CleanerProfile from "./components/CleanerProfile";
import CustomerPortalLogin from './components/CustomerPortalLogin';
import ClientPortalWrapper from './components/ClientPortalWrapper';
import MobileNavigation from "./components/MobileNavigation";
import TimeOffRequestsDashboard from "./components/TimeOffRequestsDashboard";
import TimeOffRequest from "./components/TimeOffRequest";
import ExpenseSubmission from "./components/ExpenseSubmission";
import ExpenseRequests from "./components/ExpenseRequests";
import CleanersReporting from "./components/CleanersReporting";
import { ModalProvider } from "./context/ModalContext";
import { ScheduleProvider } from "./context/ScheduleContext";
import LeadAnalysis from "./components/LeadAnalysis";
import TimeClockManagement from "./components/timeclock/TimeClockManagement";
import InvoiceManager from "./components/Invoices/InvoiceManager/InvoiceManager";
import store from "./store";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import SignUp from "./components/SignUp";
import "./styles/global.css";
import axios from "axios";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { lightTheme, darkTheme } from "./theme";
import config from "./config";

axios.defaults.baseURL = config.serviceBaseUrl;

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user ? children : <Navigate to="/login" />;
};

const CleanerRoute = ({ children }) => {
  const { user, loading } = useRequireCleaner();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user?.role === "cleaner" ? children : null;
};

const CustomerRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user && user.role !== 'customer') {
      // If user is not a customer, redirect to customer login
      navigate('/customer-login');
    }
  }, [user, loading, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Only render children if user is a customer
  return user?.role === 'customer' ? children : null;
};

const MemberRoute = ({ children }) => {
  const { user, loading } = useRequireMember();

  if (loading) {
    return <div>Loading...</div>;
  }

  return user && user.role !== "cleaner" ? children : null;
};

const CleanersReportingWrapper = () => {
  const { orgId } = useParams();
  return <CleanersReporting orgId={orgId} />;
};

const ExpenseRequestsWrapper = () => {
  const { orgId } = useParams();
  return <ExpenseRequests orgId={orgId} />;
};

const TaskBoardWithProvider = () => {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <TaskBoardProvider organizationId={user.uid}>
      <TaskBoard />
    </TaskBoardProvider>
  );
};

const App = () => {
  const [isDarkMode, setDarkMode] = useState(() => {
    return localStorage.getItem("darkMode") === "true";
  });

  console.log("serviceBaseUrl", config.serviceBaseUrl);

  // Sync theme mode with body class and CSS variables
  useEffect(() => {
    const root = document.documentElement;
    root.setAttribute("data-mui-color-scheme", isDarkMode ? "dark" : "light");
    document.body.classList.toggle("dark-mode", isDarkMode);
  }, [isDarkMode]);

  // Toggle theme mode
  const toggleMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", newMode);
      return newMode;
    });
  };

  const theme = useMemo(
    () => (isDarkMode ? darkTheme : lightTheme),
    [isDarkMode]
  );

  return (
    <Provider store={store}>
      <AuthProvider>
        <JobProvider>
          <TimezoneProvider>
            <ScheduleProvider>
            <ModalProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GoogleMapsProvider>
                <Router>
                  <Routes>
                    {/* Auth Routes */}
                    <Route path="/login" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/signup" element={<SignUp />} />

                    {/* Cleaner Routes */}
                    <Route
                      path="/cleaner-dashboard/:cleanerId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <CleanerDashboard />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cleaner-jobs/:cleanerId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <CleanerJobsOverview />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cleaner-timeoff/:cleanerId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <TimeOffRequestsDashboard />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cleaner-timeoff/:cleanerId/new"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <TimeOffRequest />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    {/* Cleaner Expense Routes */}
                    <Route
                      path="/cleaner-expenses/:cleanerId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <ExpenseSubmission />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/cleaner-expenses/:cleanerId/new"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <ExpenseSubmission />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/org/:orgId/job/:jobId"
                      element={
                        <PrivateRoute>
                          <CleanerRoute>
                            <CleanerJobView />
                            <MobileNavigation
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </CleanerRoute>
                        </PrivateRoute>
                      }
                    />

                    {/* Customer Portal Routes */}
                    <Route 
                      path="/customer-login" 
                      element={
                        <CustomerPortalLogin 
                          isDarkMode={isDarkMode} 
                          toggleDarkMode={toggleMode}
                        />
                      } 
                    />

                    <Route
                      path="/customer-portal/*" 
                      element={
                        <PrivateRoute>
                          <CustomerRoute>
                            <Routes>
                              <Route index element={<ClientPortalWrapper />} /> {/* Using the wrapper here */}
                              {/* Add any other customer portal routes here */}
                              <Route path="*" element={<Navigate to="/customer-portal" replace />} />
                            </Routes>
                          </CustomerRoute>
                        </PrivateRoute>
                      }
                    />


                    {/* Member/Admin Routes */}
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <MemberRoute>
                            <Layout
                              isDarkMode={isDarkMode}
                              toggleDarkMode={toggleMode}
                            />
                          </MemberRoute>
                        </PrivateRoute>
                      }
                    >
                      <Route index element={<ClientSalesTools />} />
                      <Route
                        path="/task-board"
                        element={<TaskBoardWithProvider />}
                      />
                      <Route path="/org-settings" element={<OrgSettings />} />
                      <Route path="/jobs/*" element={<CreateJobForm />} />
                      <Route
                        path="/customer/:customerId"
                        element={<CustomerProfileManagement />}
                      />
                      <Route
                        path="/organizations/:orgId/cleaners/:cleanerId"
                        element={<CleanerProfile />}
                      />
                      <Route
                        path="/lead-analysis"
                        element={<LeadAnalysis />}
                      />
                      <Route path="/cleaners" element={<CleanersReportingWrapper />} />
                      <Route
                        path="/organizations/:orgId/expenses"
                        element={<ExpenseRequestsWrapper />}
                      />
                      <Route path="/time-clock" element={<TimeClockManagement />} />
                      <Route path="/invoices/*" element={<InvoiceManager />} />
                    </Route>


                    {/* Catch-all redirect */}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Router>
              </GoogleMapsProvider>
            </ThemeProvider>
            </ModalProvider>
            </ScheduleProvider>
          </TimezoneProvider>
        </JobProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
