import styles from "../../styles/JobOverview/FilterCenter.module.css";

import useJobOverview from "../../hooks/useJobOverview";

const FilterCenter = () => {
  const { cleaners, statusList, updateStatus, showingCleaners, setShowingCleaners } = useJobOverview();

  return (
    <div className={styles.filterCenter}>
      <div className={styles.statusSection}>
        <h3>Status</h3>
        <ul>
          {statusList.map(status => (
              <li key={status.label}>
              <label>
                <input
                  type="checkbox"
                  checked={status.active}
                  onChange={() => updateStatus(status.label)}
                />
                {status.label}
              </label>
            </li>
            )
          )}
        </ul>
      </div>
      <div className={styles.statusSection}>
        <h3>Cleaners</h3>
        <ul>
          {cleaners?.map((cleanner, index) => (
            <li key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={showingCleaners.includes(cleanner.id)}
                  onChange={() => {
                    var updatedList = [...showingCleaners];

                    if (updatedList.includes(cleanner.id)) {
                      updatedList = updatedList.filter(cleannerId => cleannerId !== cleanner.id);
                    } else {
                      updatedList.push(cleanner.id);
                    }

                    setShowingCleaners(updatedList);
                  }}                  
                />
                {cleanner.firstName + cleanner.lastName}
              </label>
            </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
}

export default FilterCenter;