import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  CardContent,
  Divider,
  Switch,
  FormControlLabel,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { StyledCard, StyledTextField, SaveButton } from './EmailStyledComponents';
import EmailPreview from './EmailPreview';
import SettingsNavigation from '../SettingsNavigation';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { useAuth } from '../../../../context/AuthContext';

const EmailSettings = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [orgData, setOrgData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const [templates, setTemplates] = useState({
    newInvoice: {
      subject: 'New Invoice from {{organization.name}}',
      addressAs: 'First Name',
      headerText: 'You have been sent an invoice {{invoice.number}} for {{invoice.amount}} that\'s due on {{invoice.dueDate}}',
      footerText: 'Thank you for your business',
      buttonText: 'Pay Invoice'
    },
    reminder: {
      subject: 'Payment Reminder from {{organization.name}}',
      addressAs: 'First Name',
      headerText: 'This is a reminder for invoice {{invoice.number}} for {{invoice.amount}} due on {{invoice.dueDate}}',
      footerText: 'Please process this payment at your earliest convenience',
      buttonText: 'Pay Now'
    },
    receipt: {
      subject: 'Payment Receipt for Invoice {{invoice.number}}',
      addressAs: 'First Name',
      headerText: 'Thank you for your payment of {{invoice.paymentAmount}} for invoice {{invoice.number}}',
      footerText: 'We appreciate your business',
      buttonText: 'View Receipt'
    },
    declined: {
      subject: 'Payment Declined for Invoice {{invoice.number}}',
      addressAs: 'First Name',
      headerText: 'The payment for invoice {{invoice.number}} was declined',
      footerText: 'Please update your payment information and try again',
      buttonText: 'Update Payment'
    }
  });

  useEffect(() => {
    const loadOrgData = async () => {
      if (!user?.orgId) return;
      
      try {
        setLoading(true);
        // Get organization data
        const orgDoc = await getDoc(doc(db, 'organizations', user.orgId));
        if (orgDoc.exists()) {
          setOrgData(orgDoc.data());
        }

        // Get email settings
        const emailSettingsDoc = await getDoc(
          doc(db, 'organizations', user.orgId, 'emailSettings', 'default')
        );
        
        if (emailSettingsDoc.exists()) {
          setTemplates(prev => ({
            ...prev,
            ...emailSettingsDoc.data()
          }));
        }
        
        setError(null);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load settings');
      } finally {
        setLoading(false);
      }
    };

    loadOrgData();
  }, [user?.orgId]);

  const handleSave = async () => {
    if (!user?.orgId) return;

    try {
      await setDoc(
        doc(db, 'organizations', user.orgId, 'emailSettings', 'default'),
        {
          ...templates,
          updatedAt: serverTimestamp(),
          updatedBy: user.uid
        },
        { merge: true }
      );
      setError(null);
    } catch (err) {
      console.error('Error saving settings:', err);
      setError('Failed to save settings');
    }
  };

  // Get organization info for preview
  const getPreviewData = () => {
    const companyInfo = orgData?.companyInfo || {};
    return {
      customer: {
        name: "John Smith",
        email: "john@example.com",
        phone: "(555) 987-6543",
        address: "456 Customer Ave, City, ST 54321",
      },
      organization: {
        name: orgData?.name || companyInfo?.name || "Your Business Name",
        email: companyInfo?.email || "contact@business.com",
        phone: companyInfo?.phoneNumber || "(555) 123-4567",
        address: `${companyInfo?.address || ''} ${companyInfo?.city || ''}, ${companyInfo?.state || ''} ${companyInfo?.zipCode || ''}`.trim(),
        logoUrl: companyInfo?.logoUrl || "",
      },
      invoice: {
        number: "INV-2024-001",
        amount: "$250.00",
        dueDate: "2024-04-15",
        paymentLink: "https://pay.example.com/inv/2024001",
        paymentAmount: "$250.00",
        paymentMethod: "Credit Card",
        remainingBalance: "$0.00"
      }
    };
  };

  const getCurrentTemplate = () => {
    const templateTypes = ['newInvoice', 'reminder', 'receipt', 'declined'];
    return templates[templateTypes[activeTab]];
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <SettingsNavigation />
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SettingsNavigation />
      
      {error && (
        <Box sx={{ mb: 2, p: 2, bgcolor: 'error.light', borderRadius: 1 }}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}

      <Box sx={{ mb: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="New Invoice" />
          <Tab label="Payment Reminder" />
          <Tab label="Receipt" />
          <Tab label="Payment Declined" />
        </Tabs>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Email Template Settings
              </Typography>
              
              <Box sx={{ mb: 3 }}>
                <StyledTextField
                  fullWidth
                  label="Subject"
                  value={getCurrentTemplate().subject}
                  onChange={(e) => {
                    const templateType = Object.keys(templates)[activeTab];
                    setTemplates(prev => ({
                      ...prev,
                      [templateType]: {
                        ...prev[templateType],
                        subject: e.target.value
                      }
                    }));
                  }}
                  helperText="Available variables: {{invoice.number}}, {{organization.name}}"
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <FormControl fullWidth>
                  <InputLabel>Address Customer as</InputLabel>
                  <Select
                    value={getCurrentTemplate().addressAs}
                    onChange={(e) => {
                      const templateType = Object.keys(templates)[activeTab];
                      setTemplates(prev => ({
                        ...prev,
                        [templateType]: {
                          ...prev[templateType],
                          addressAs: e.target.value
                        }
                      }));
                    }}
                  >
                    <MenuItem value="First Name">First Name</MenuItem>
                    <MenuItem value="Full Name">Full Name</MenuItem>
                    <MenuItem value="Company Name">Company Name</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Box sx={{ mb: 3 }}>
                <StyledTextField
                  fullWidth
                  label="Header Text"
                  multiline
                  rows={3}
                  value={getCurrentTemplate().headerText}
                  onChange={(e) => {
                    const templateType = Object.keys(templates)[activeTab];
                    setTemplates(prev => ({
                      ...prev,
                      [templateType]: {
                        ...prev[templateType],
                        headerText: e.target.value
                      }
                    }));
                  }}
                  helperText="Available variables: {{invoice.number}}, {{invoice.amount}}, {{invoice.dueDate}}"
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <StyledTextField
                  fullWidth
                  label="Footer Text"
                  multiline
                  rows={2}
                  value={getCurrentTemplate().footerText}
                  onChange={(e) => {
                    const templateType = Object.keys(templates)[activeTab];
                    setTemplates(prev => ({
                      ...prev,
                      [templateType]: {
                        ...prev[templateType],
                        footerText: e.target.value
                      }
                    }));
                  }}
                />
              </Box>

              <Box sx={{ mb: 3 }}>
                <StyledTextField
                  fullWidth
                  label="Button Text"
                  value={getCurrentTemplate().buttonText}
                  onChange={(e) => {
                    const templateType = Object.keys(templates)[activeTab];
                    setTemplates(prev => ({
                      ...prev,
                      [templateType]: {
                        ...prev[templateType],
                        buttonText: e.target.value
                      }
                    }));
                  }}
                />
              </Box>

              <SaveButton
                variant="contained"
                onClick={handleSave}
                fullWidth
              >
                Save Changes
              </SaveButton>
            </CardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <EmailPreview 
            template={getCurrentTemplate()}
            organization={orgData}
            previewData={getPreviewData()}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailSettings;