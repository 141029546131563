// src/components/Invoices/Settings/TaxSettings/index.jsx
import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  CardHeader,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import { collection, query, onSnapshot, doc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import SettingsNavigation from './SettingsNavigation';
import TaxModal from './TaxModal';

const TaxSettings = () => {
  const [taxRates, setTaxRates] = useState([]);
  const [open, setOpen] = useState(false);
  const [editTax, setEditTax] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    rate: '',
    active: true
  });

  const { user } = useAuth();

  useEffect(() => {
    if (!user?.orgId) return;

    const q = query(collection(db, 'organizations', user.orgId, 'taxRates'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const taxesData = [];
      snapshot.forEach((doc) => {
        taxesData.push({ id: doc.id, ...doc.data() });
      });
      setTaxRates(taxesData);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  const handleOpen = (tax = null) => {
    if (tax) {
      setEditTax(tax);
      setFormData({
        name: tax.name,
        description: tax.description || '',
        rate: tax.rate,
        active: tax.active
      });
    } else {
      setEditTax(null);
      setFormData({
        name: '',
        description: '',
        rate: '',
        active: true
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditTax(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user?.orgId) return;

    const taxData = {
      ...formData,
      rate: Number(formData.rate),
      updatedAt: serverTimestamp(),
      updatedBy: user.uid
    };

    if (!editTax) {
      taxData.createdAt = serverTimestamp();
      taxData.createdBy = user.uid;
    }

    try {
      const taxRef = editTax 
        ? doc(db, 'organizations', user.orgId, 'taxRates', editTax.id)
        : doc(collection(db, 'organizations', user.orgId, 'taxRates'));
      
      await setDoc(taxRef, taxData, { merge: true });
      handleClose();
    } catch (error) {
      console.error('Error saving tax rate:', error);
      // Add error handling/notification here
    }
  };

  const handleDelete = async (taxId) => {
    if (!user?.orgId || !window.confirm('Are you sure you want to delete this tax rate?')) return;

    try {
      await deleteDoc(doc(db, 'organizations', user.orgId, 'taxRates', taxId));
    } catch (error) {
      console.error('Error deleting tax rate:', error);
      // Add error handling/notification here
    }
  };

  return (
    <>
      <SettingsNavigation />
      <Card>
        <CardHeader 
          title="Tax Rates" 
          action={
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleOpen()}
            >
              Add Tax Rate
            </Button>
          }
        />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Rate (%)</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {taxRates.map((tax) => (
                  <TableRow key={tax.id}>
                    <TableCell>{tax.name}</TableCell>
                    <TableCell>{tax.description}</TableCell>
                    <TableCell>{tax.rate.toFixed(2)}%</TableCell>
                    <TableCell>{tax.active ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpen(tax)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(tax.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TaxModal
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            formData={formData}
            setFormData={setFormData}
            editTax={editTax}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default TaxSettings;