// src/components/Invoices/Settings/ProductSettings/index.jsx
import React, { useState, useEffect } from 'react';
import { 
  Card, 
  CardContent, 
  CardHeader,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  MenuItem
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useAuth } from '../../../context/AuthContext';
import { collection, query, onSnapshot, doc, setDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import SettingsNavigation from './SettingsNavigation';
import ProductModal from './ProductModal';

const ProductSettings = () => {
  const [products, setProducts] = useState([]);
  const [open, setOpen] = useState(false);
  const [editProduct, setEditProduct] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    category: '',
    active: true,
    taxable: true,
    pricingType: 'fixed',
    priceSource: 'fixed',
    pricingPer: 'job'
  });

  const { user } = useAuth();

  useEffect(() => {
    if (!user?.orgId) return;

    const q = query(collection(db, 'organizations', user.orgId, 'products'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const productsData = [];
      snapshot.forEach((doc) => {
        productsData.push({ id: doc.id, ...doc.data() });
      });
      setProducts(productsData);
    });

    return () => unsubscribe();
  }, [user?.orgId]);

  const handleOpen = (product = null) => {
    if (product) {
      setEditProduct(product);
      setFormData({
        name: product.name,
        description: product.description || '',
        price: product.price,
        category: product.category || '',
        active: product.active,
        taxable: product.taxable,
        pricingType: product.pricingType || 'fixed',
        priceSource: product.priceSource || 'fixed',
        pricingPer: product.pricingPer || 'job'
      });
    } else {
      setEditProduct(null);
      setFormData({
        name: '',
        description: '',
        price: '',
        category: '',
        active: true,
        taxable: true,
        pricingType: 'fixed',
        priceSource: 'fixed',
        pricingPer: 'job'
      });
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditProduct(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user?.orgId) return;

    const productData = {
      ...formData,
      price: Number(formData.price),
      updatedAt: serverTimestamp(),
      updatedBy: user.uid
    };

    if (!editProduct) {
      productData.createdAt = serverTimestamp();
      productData.createdBy = user.uid;
    }

    try {
      const productRef = editProduct 
        ? doc(db, 'organizations', user.orgId, 'products', editProduct.id)
        : doc(collection(db, 'organizations', user.orgId, 'products'));
      
      await setDoc(productRef, productData, { merge: true });
      handleClose();
    } catch (error) {
      console.error('Error saving product:', error);
      // Add error handling/notification here
    }
  };

  const handleDelete = async (productId) => {
    if (!user?.orgId || !window.confirm('Are you sure you want to delete this product?')) return;

    try {
      await deleteDoc(doc(db, 'organizations', user.orgId, 'products', productId));
    } catch (error) {
      console.error('Error deleting product:', error);
      // Add error handling/notification here
    }
  };

  return (
    <>
      <SettingsNavigation />
      <Card>
        <CardHeader 
          title="Products" 
          action={
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleOpen()}
            >
              Add Product
            </Button>
          }
        />
        <CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Pricing Type</TableCell>
                  <TableCell>Price Source</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Taxable</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell>${product.price.toFixed(2)}</TableCell>
                    <TableCell>{product.category}</TableCell>
                    <TableCell>{product.pricingType}</TableCell>
                    <TableCell>{product.priceSource}</TableCell>
                    <TableCell>{product.active ? 'Active' : 'Inactive'}</TableCell>
                    <TableCell>{product.taxable ? 'Yes' : 'No'}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpen(product)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDelete(product.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <ProductModal
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            formData={formData}
            setFormData={setFormData}
            isEditing={!!editProduct}
          />
        </CardContent>
      </Card>
    </>
  );
};

export default ProductSettings;