// src/theme.js

import { createTheme } from '@mui/material/styles';

// Utility function to fetch CSS variable values
const getCSSVar = (varName) => getComputedStyle(document.documentElement).getPropertyValue(varName).trim();

const inputStyles = {
  root: {
    backgroundColor: 'var(--light-foreground)',
    '[data-mui-color-scheme="dark"] &': {
      backgroundColor: 'var(--dark-dropdowns)',
    },
    '&:hover': {
      backgroundColor: 'var(--light-foreground)',
      '[data-mui-color-scheme="dark"] &': {
        backgroundColor: 'var(--dark-dropdowns)',
      },
    },
    '&.Mui-focused': {
      backgroundColor: 'var(--light-foreground)',
      '[data-mui-color-scheme="dark"] &': {
        backgroundColor: 'var(--dark-dropdowns)',
      },
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
};

// Common component overrides for both themes
const commonComponents = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        margin: 0,
        padding: 0,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: 'var(--dark-foreground)',
        backgroundImage: 'none',
        '[data-mui-color-scheme="light"] &': {
          backgroundColor: 'var(--light-foreground)'
        }
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        fontWeight: 500,
        padding: '8px 16px',
        height: '36px',
        minWidth: '40px',
        fontSize: '14px',
        backgroundColor: 'var(--dark-primary)',
        color: 'white',
        '&:hover': {
          backgroundColor: 'var(--dark-accent)',
        },
        '[data-mui-color-scheme="light"] &': {
          backgroundColor: 'var(--light-primary)',
          '&:hover': {
            backgroundColor: 'var(--light-accent)',
          },
        },
        transition: 'background-color 0.2s ease',
      },
      customCancel: {
        backgroundColor: 'var(--error)',
        color: 'white',
        '&:hover': {
          backgroundColor: 'var(--error-accent)',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        borderRadius: '4px',
        padding: '8px',
        height: '36px',
        minWidth: '40px',
        backgroundColor: 'var(--dark-primary)',
        color: 'white',
        '&:hover': {
          backgroundColor: 'var(--dark-accent)',
        },
        '[data-mui-color-scheme="light"] &': {
          backgroundColor: 'var(--light-primary)',
          '&:hover': {
            backgroundColor: 'var(--light-accent)',
          },
        },
        '& + &': {
          marginLeft: '8px',
        },
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.2s ease',
      },
      sizeSmall: {
        padding: '6px',
        '& > *:first-of-type': {
          fontSize: '20px',
        },
      },
      sizeMedium: {
        padding: '8px',
        '& > *:first-of-type': {
          fontSize: '24px',
        },
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      ...inputStyles,
      root: {
        backgroundColor: 'var(--light-foreground)',
        '[data-mui-color-scheme="dark"] &': {
          backgroundColor: 'var(--dark-text-area)',
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '& > *:first-of-type': {
          // Move any first-child styles here
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          margin: '8px 0',
        },
        '& > *:first-of-type': {
          // Move any first-child styles here
        },
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '1.25rem',
        fontWeight: 500,
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        '& .MuiOutlinedInput-root': {
          backgroundColor: 'var(--light-foreground)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-text-area)',
          },
          '&::before': {
            display: 'none',
          },
          '&::after': {
            display: 'none',
          },
        },
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      MenuProps: {
        container: document.body,
        slotProps: {
          paper: {
            elevation: 8
          }
        }
      }
    },
    styleOverrides: {
      root: {
        backgroundColor: 'var(--light-foreground)',
        '[data-mui-color-scheme="dark"] &': {
          backgroundColor: 'var(--dark-text-area)',
        },
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: 'var(--light-foreground)',
        '[data-mui-color-scheme="dark"] &': {
          backgroundColor: 'var(--dark-text-area)',
        },
        '&:hover': {
          backgroundColor: 'var(--light-foreground)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-text-hover)',
          },
        },
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid var(--light-border)',
          '[data-mui-color-scheme="dark"] &': {
            border: '1px solid var(--dark-border)',
          },
        },
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      container: document.body
    },
    styleOverrides: {
      root: {
        zIndex: 1400  // Higher than Dialog's default z-index
      },
      paper: {
        backgroundColor: 'var(--light-foreground)',
        '[data-mui-color-scheme="dark"] &': {
          backgroundColor: 'var(--dark-dropdowns)',
        }
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: 'var(--light-focus-ring)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-focus-ring)',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            backgroundColor: 'var(--light-foreground)',
            '[data-mui-color-scheme="dark"] &': {
              backgroundColor: 'var(--dark-dropdowns)',
            },
          },
        },
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      container: document.body
    },
    styleOverrides: {
      paper: {
        zIndex: 1400,  // Same as Popover
        backgroundColor: 'var(--light-foreground)',
        '[data-mui-color-scheme="dark"] &': {
          backgroundColor: 'var(--dark-dropdowns)',
        }
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        backgroundColor: 'var(--dark-background)',
        '& .MuiDialogActions-root': {
          padding: '16px',
          backgroundColor: 'var(--dark-background)',
          borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          '& .MuiButton-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            color: 'white',
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 400,
            padding: '6px 16px',
            borderRadius: '4px',
            minWidth: 'auto',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.15)',
            }
          }
        },
        '[data-mui-color-scheme="light"] &': {
          backgroundColor: 'var(--light-background)',
          '& .MuiDialogActions-root': {
            backgroundColor: 'var(--light-background)',
            borderTop: '1px solid var(--light-border)',
            '& .MuiButton-root': {
              backgroundColor: 'var(--light-primary)',
              color: 'white',
              '&:hover': {
                backgroundColor: 'var(--light-accent)',
              }
            }
          }
        }
      }
    }
  },
};

// Light Theme Configuration
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: getCSSVar('--light-primary'),
      contrastText: '#fff',
    },
    secondary: {
      main: getCSSVar('--light-secondary'),
      contrastText: '#fff',
    },
    background: {
      default: getCSSVar('--light-background'),
      paper: getCSSVar('--light-foreground'),
    },
    text: {
      primary: getCSSVar('--light-text'),
      secondary: getCSSVar('--light-text-light'),
    },
    error: {
      main: getCSSVar('--error'),
      contrastText: getCSSVar('--error-content'),
    },
  },
  components: {
    ...commonComponents,
    MuiBox: {
      styleOverrides: {
        root: {
          '&.CreateJobForm_CreateJobForm__RDAjx': {
            backgroundColor: 'var(--light-background)',
            color: 'var(--light-text)',
            borderColor: 'none',
            '& .MuiTextField-root': {
              backgroundColor: 'var(--light-foreground)',
              borderColor: 'none',
            },
            '& .MuiAccordion-root': {
              backgroundColor: 'var(--light-foreground)',
              borderColor: 'none',
            },
            '& .MuiAutocomplete-paper': {
              backgroundColor: 'var(--light-foreground)',
              borderColor: 'none',
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1300  // Standard Dialog z-index
        },
        paper: {
          backgroundColor: 'var(--light-background)',
          margin: '16px',
          borderRadius: '12px',
          overflow: 'hidden',
          boxShadow: 'var(--shadow-lg-light)',
          '& .MuiDialogTitle-root': {
            backgroundColor: 'var(--light-primary)',
            backgroundImage: 'linear-gradient(135deg, var(--light-primary), var(--light-accent))',
            color: 'white',
            padding: '16px 24px',
            fontSize: '1.25rem',
            fontWeight: 500,
          },
          '& .MuiDialogContent-root': {
            backgroundColor: 'var(--light-background)',
            padding: '24px',
            '& .MuiOutlinedInput-root': {
              backgroundColor: 'var(--light-foreground)',
            }
          },
          '& .MuiDialogActions-root': {
            backgroundColor: 'var(--light-background)',
            padding: '16px 24px',
            borderTop: '1px solid var(--light-border)'
          },
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-background)',
            boxShadow: 'var(--shadow-lg-dark)',
            '& .MuiDialogTitle-root': {
              backgroundColor: 'var(--dark-primary)',
              backgroundImage: 'linear-gradient(135deg, var(--dark-primary), var(--dark-accent))',
              color: 'var(--dark-text)',
            },
            '& .MuiDialogContent-root': {
              backgroundColor: 'var(--dark-background)',
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'var(--dark-foreground)',
              }
            },
            '& .MuiDialogActions-root': {
              backgroundColor: 'var(--dark-background)',
              borderTop: '1px solid var(--dark-border)'
            }
          }
        }
      }
    }
  },
});

// Dark Theme Configuration
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: getCSSVar('--dark-primary'),
      contrastText: '#fff',
    },
    secondary: {
      main: getCSSVar('--dark-secondary'),
      contrastText: '#fff',
    },
    background: {
      default: getCSSVar('--dark-background'),
      paper: getCSSVar('--dark-foreground'),
    },
    text: {
      primary: getCSSVar('--dark-text'),
      secondary: getCSSVar('--dark-text-light'),
    },
    error: {
      main: getCSSVar('--error'),
      contrastText: getCSSVar('--error-content'),
    },
  },
  components: {
    ...commonComponents,
    // MuiDialog: {
    //   styleOverrides: {
    //     root: {
    //       zIndex: 1300  // Standard Dialog z-index
    //     },
    //     paper: {
    //       backgroundColor: 'var(--dark-background)',
    //       backgroundImage: 'none',
    //       '& .MuiDialogTitle-root': {
    //         backgroundColor: 'var(--dark-background)',
    //         color: 'var(--dark-text)',
    //         padding: '16px 24px',
    //         borderColor: 'none',
    //         boxShadow: 'var(--shadow-lg-dark)',
    //       },
    //       '& .MuiDialogContent-root': {
    //         backgroundColor: 'var(--dark-background)',
    //         color: 'var(--dark-text)',
    //         padding: '20px 24px',
    //         borderColor: 'none',
    //       },
    //       '& .MuiDialogActions-root': {
    //         backgroundColor: 'var(--dark-background)',
    //         padding: '16px 24px',
    //         borderColor: 'none',
    //       },
    //       '& .MuiTextField-root': {
    //         backgroundColor: 'var(--dark-foreground)',
    //         borderColor: 'none',
    //       },
    //       '& .MuiSelect-select': {
    //         backgroundColor: 'var(--dark-text-area)',
    //         borderColor: 'none',
    //       },
    //     },
    //   },
    // },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'var(--dark-text-area)',
            '& fieldset': {
              borderColor: 'none',
            },
            '&:hover fieldset': {
              borderColor: 'none',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'none',
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: 'var(--dark-foreground)',
          borderColor: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'var(--dark-text)',
          '&.Mui-selected': {
            color: 'var(--dark-primary)',
          },
        },
      },
    },
  },
});