// src/components/Invoices/Settings/TemplateSettings/index.jsx
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Button,
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  Divider,
  Alert
} from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import InvoiceTemplate from './InvoiceTemplate';
import SettingsNavigation from './SettingsNavigation';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  borderRadius: 'var(--border-radius-base)',
  transition: 'var(--transition-duration) var(--transition-ease)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,

  '& .MuiCardHeader-root': {
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground-accent)' : 'var(--light-foreground-accent)',
  },

  '& .MuiCardHeader-title': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '24px 0',
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-divider)' : 'var(--light-border)',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 600,
  marginBottom: '16px',
}));

const StyledSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.08),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  '&:active': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const TemplateSettings = () => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState(0);
  const [settings, setSettings] = useState({
    template: {
      // Company Information
      showLogo: true,
      showCompanyName: true,
      showCompanyAddress: true,
      showCompanyPhone: true,
      showCompanyEmail: true,
      
      // Invoice Header
      showInvoiceTitle: true,
      showInvoiceNumber: true,
      showInvoiceDate: true,
      showDueDate: true,
      
      // Customer Information
      showBillTo: true,
      showCustomerName: true,
      showCustomerAddress: true,
      showCustomerPhone: true,
      showCustomerEmail: true,
      
      // Invoice Details
      showItemDescription: true,
      showItemQuantity: true,
      showItemRate: true,
      showItemAmount: true,
      
      // Totals
      showSubtotal: true,
      showTip: true,
      showDiscount: true,
      showTax: true,
      showTotal: true,
      
      // Notes and Footer
      showNotes: true,
      showFooter: true,
      showPaymentTerms: true,
      showThankYouMessage: true,

      colors: {
        primary: '#3b82f6',
        secondary: '#f59e0b',
        accent: '#6366f1'
      },
      headerText: '',
      footerText: 'Thank you for choosing us!',
      dueDateDays: 30,
      customFields: [],
      
      // Notes Section
      defaultNotes: 'Sample invoice for template preview',
      
      // Footer Section
      thankYouMessage: 'Thank you for choosing us!',
      defaultPaymentTerms: 'Net 30'
    },
    paymentTerms: '',
    notes: '',
    emailText: {
      subject: 'Invoice {{invoiceNumber}} from {{businessName}}',
      body: 'Dear {{customerName}},\n\nPlease find attached invoice {{invoiceNumber}} for {{amount}}.\n\nDue date: {{dueDate}}\n\nThank you for your business!',
      reminder: 'Dear {{customerName}},\n\nThis is a reminder that invoice {{invoiceNumber}} for {{amount}} is due on {{dueDate}}.\n\nPlease let us know if you have any questions.'
    }
  });
  const [error, setError] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [orgInfo, setOrgInfo] = useState(null);

  // Sample invoice for preview
  const SAMPLE_INVOICE = {
    number: 'INV-2025-001',
    date: new Date().toISOString(),
    dueDate: new Date(Date.now() + settings.template.dueDateDays * 24 * 60 * 60 * 1000).toISOString(),
    items: [
      { description: 'House Cleaning Service', quantity: 1, rate: 150.00 },
      { description: 'Deep Clean Add-on', quantity: 1, rate: 75.00 }
    ],
    customFields: settings.template.customFields.map(field => ({
      label: field.label,
      value: 'Sample Value'
    })),
    tip: 25.00,           // Sample $25 tip
    discount: 20.00,      // Sample $20 discount
    taxRate: 0.1         // 10% tax rate
  };

  useEffect(() => {
    const loadData = async () => {
      if (!user?.orgId) return;

      try {
        // Load org info
        const orgDoc = await getDoc(doc(db, 'organizations', user.orgId));
        if (orgDoc.exists()) {
          setOrgInfo(orgDoc.data());
        }

        // Load template settings
        const settingsDoc = await getDoc(doc(db, 'organizations', user.orgId, 'templateSettings', 'default'));
        if (settingsDoc.exists()) {
          setSettings(prev => ({
            ...prev,
            ...settingsDoc.data()
          }));
        }
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Failed to load settings. Please try again.');
      }
    };

    loadData();
  }, [user?.orgId]);

  const handleSave = async () => {
    if (!user?.orgId) return;

    setIsSaving(true);
    setError('');
    try {
      const settingsRef = doc(db, 'organizations', user.orgId, 'templateSettings', 'default');
      await setDoc(settingsRef, {
        ...settings,
        updatedAt: serverTimestamp(),
        updatedBy: user.uid
      }, { merge: true });
    } catch (error) {
      console.error('Error saving template settings:', error);
      setError('Failed to save settings. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (section, field, value) => {
    setSettings(prev => {
      const newSettings = { ...prev };
      if (section) {
        if (section.includes('.')) {
          const [mainSection, subSection] = section.split('.');
          newSettings[mainSection] = {
            ...newSettings[mainSection],
            [subSection]: {
              ...newSettings[mainSection][subSection],
              [field]: value
            }
          };
        } else {
          newSettings[section] = {
            ...newSettings[section],
            [field]: value
          };
        }
      } else {
        newSettings[field] = value;
      }
      return newSettings;
    });
  };

  const handleAddCustomField = () => {
    setSettings(prev => ({
      ...prev,
      template: {
        ...prev.template,
        customFields: [
          ...prev.template.customFields,
          { label: '', value: '' }
        ]
      }
    }));
  };

  if (!orgInfo) return <div>Loading...</div>;

  const { companyInfo } = orgInfo;

  return (
    <Box>
      <SettingsNavigation />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardHeader title="Template Settings" />
            <CardContent>
              {error && (
                <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
              )}

              <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                <StyledTab label="Display" />
                <StyledTab label="Content" />
                <StyledTab label="Colors" />
                <StyledTab label="Email" />
              </Tabs>

              <Box sx={{ mt: 2 }}>
                {activeTab === 0 && (
                  <Grid container spacing={2}>
                    {/* Company Information */}
                    <Grid item xs={12}>
                      <SectionTitle variant="subtitle1" gutterBottom>Company Information</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showLogo}
                            onChange={(e) => handleChange('template', 'showLogo', e.target.checked)}
                          />
                        }
                        label="Show Logo"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCompanyName}
                            onChange={(e) => handleChange('template', 'showCompanyName', e.target.checked)}
                          />
                        }
                        label="Show Company Name"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCompanyAddress}
                            onChange={(e) => handleChange('template', 'showCompanyAddress', e.target.checked)}
                          />
                        }
                        label="Show Company Address"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCompanyPhone}
                            onChange={(e) => handleChange('template', 'showCompanyPhone', e.target.checked)}
                          />
                        }
                        label="Show Company Phone"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCompanyEmail}
                            onChange={(e) => handleChange('template', 'showCompanyEmail', e.target.checked)}
                          />
                        }
                        label="Show Company Email"
                      />
                    </Grid>

                    {/* Invoice Header */}
                    <Grid item xs={12}>
                      <StyledDivider />
                      <SectionTitle variant="subtitle1" gutterBottom>Invoice Header</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showInvoiceTitle}
                            onChange={(e) => handleChange('template', 'showInvoiceTitle', e.target.checked)}
                          />
                        }
                        label="Show Invoice Title"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showInvoiceNumber}
                            onChange={(e) => handleChange('template', 'showInvoiceNumber', e.target.checked)}
                          />
                        }
                        label="Show Invoice Number"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showInvoiceDate}
                            onChange={(e) => handleChange('template', 'showInvoiceDate', e.target.checked)}
                          />
                        }
                        label="Show Invoice Date"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showDueDate}
                            onChange={(e) => handleChange('template', 'showDueDate', e.target.checked)}
                          />
                        }
                        label="Show Due Date"
                      />
                    </Grid>

                    {/* Customer Information */}
                    <Grid item xs={12}>
                      <StyledDivider />
                      <SectionTitle variant="subtitle1" gutterBottom>Customer Information</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showBillTo}
                            onChange={(e) => handleChange('template', 'showBillTo', e.target.checked)}
                          />
                        }
                        label="Show 'Bill To' Section"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCustomerName}
                            onChange={(e) => handleChange('template', 'showCustomerName', e.target.checked)}
                          />
                        }
                        label="Show Customer Name"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCustomerAddress}
                            onChange={(e) => handleChange('template', 'showCustomerAddress', e.target.checked)}
                          />
                        }
                        label="Show Customer Address"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCustomerPhone}
                            onChange={(e) => handleChange('template', 'showCustomerPhone', e.target.checked)}
                          />
                        }
                        label="Show Customer Phone"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showCustomerEmail}
                            onChange={(e) => handleChange('template', 'showCustomerEmail', e.target.checked)}
                          />
                        }
                        label="Show Customer Email"
                      />
                    </Grid>

                    {/* Invoice Details */}
                    <Grid item xs={12}>
                      <StyledDivider />
                      <SectionTitle variant="subtitle1" gutterBottom>Invoice Details</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showItemDescription}
                            onChange={(e) => handleChange('template', 'showItemDescription', e.target.checked)}
                          />
                        }
                        label="Show Item Description"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showItemQuantity}
                            onChange={(e) => handleChange('template', 'showItemQuantity', e.target.checked)}
                          />
                        }
                        label="Show Item Quantity"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showItemRate}
                            onChange={(e) => handleChange('template', 'showItemRate', e.target.checked)}
                          />
                        }
                        label="Show Item Rate"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showItemAmount}
                            onChange={(e) => handleChange('template', 'showItemAmount', e.target.checked)}
                          />
                        }
                        label="Show Item Amount"
                      />
                    </Grid>

                    {/* Totals */}
                    <Grid item xs={12}>
                      <StyledDivider />
                      <SectionTitle variant="subtitle1" gutterBottom>Totals</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showSubtotal}
                            onChange={(e) => handleChange('template', 'showSubtotal', e.target.checked)}
                          />
                        }
                        label="Show Subtotal"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showTip}
                            onChange={(e) => handleChange('template', 'showTip', e.target.checked)}
                          />
                        }
                        label="Show Tip"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showDiscount}
                            onChange={(e) => handleChange('template', 'showDiscount', e.target.checked)}
                          />
                        }
                        label="Show Discount"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showTax}
                            onChange={(e) => handleChange('template', 'showTax', e.target.checked)}
                          />
                        }
                        label="Show Tax"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showTotal}
                            onChange={(e) => handleChange('template', 'showTotal', e.target.checked)}
                          />
                        }
                        label="Show Total"
                      />
                    </Grid>

                    {/* Notes and Footer */}
                    <Grid item xs={12}>
                      <StyledDivider />
                      <SectionTitle variant="subtitle1" gutterBottom>Notes and Footer</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showNotes}
                            onChange={(e) => handleChange('template', 'showNotes', e.target.checked)}
                          />
                        }
                        label="Show Notes"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showFooter}
                            onChange={(e) => handleChange('template', 'showFooter', e.target.checked)}
                          />
                        }
                        label="Show Footer"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showPaymentTerms}
                            onChange={(e) => handleChange('template', 'showPaymentTerms', e.target.checked)}
                          />
                        }
                        label="Show Payment Terms"
                      />
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showThankYouMessage}
                            onChange={(e) => handleChange('template', 'showThankYouMessage', e.target.checked)}
                          />
                        }
                        label="Show Thank You Message"
                      />
                    </Grid>
                  </Grid>
                )}

                {activeTab === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        type="number"
                        label="Default Due Date (days)"
                        value={settings.template.dueDateDays}
                        onChange={(e) => handleChange('template', 'dueDateDays', parseInt(e.target.value))}
                        helperText="Number of days until invoice is due"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SectionTitle variant="subtitle1" gutterBottom>Custom Fields</SectionTitle>
                      {settings.template.customFields.map((field, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <StyledTextField
                            fullWidth
                            label={`Custom Field ${index + 1}`}
                            value={field.label}
                            onChange={(e) => {
                              const newFields = [...settings.template.customFields];
                              newFields[index].label = e.target.value;
                              handleChange('template', 'customFields', newFields);
                            }}
                          />
                        </Box>
                      ))}
                      <Button onClick={handleAddCustomField}>
                        Add Custom Field
                      </Button>
                    </Grid>

                    {/* Notes Settings */}
                    <Grid item xs={12}>
                      <SectionTitle variant="subtitle1" gutterBottom>Notes</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showNotes}
                            onChange={(e) => handleChange('template', 'showNotes', e.target.checked)}
                          />
                        }
                        label="Show Notes"
                      />
                      <StyledTextField
                        fullWidth
                        multiline
                        rows={3}
                        label="Default Notes"
                        value={settings.template.defaultNotes}
                        onChange={(e) => handleChange('template', 'defaultNotes', e.target.value)}
                        sx={{ mt: 2 }}
                      />
                    </Grid>

                    {/* Footer Settings */}
                    <Grid item xs={12}>
                      <SectionTitle variant="subtitle1" gutterBottom>Footer</SectionTitle>
                      <FormControlLabel
                        control={
                          <StyledSwitch
                            checked={settings.template.showThankYouMessage}
                            onChange={(e) => handleChange('template', 'showThankYouMessage', e.target.checked)}
                          />
                        }
                        label="Show Thank You Message"
                      />
                      <StyledTextField
                        fullWidth
                        label="Thank You Message"
                        value={settings.template.thankYouMessage}
                        onChange={(e) => handleChange('template', 'thankYouMessage', e.target.value)}
                        sx={{ mt: 2 }}
                      />
                    </Grid>
                  </Grid>
                )}

                {activeTab === 2 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Primary Color"
                        type="color"
                        value={settings.template.colors.primary}
                        onChange={(e) => handleChange('template.colors', 'primary', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Secondary Color"
                        type="color"
                        value={settings.template.colors.secondary}
                        onChange={(e) => handleChange('template.colors', 'secondary', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Accent Color"
                        type="color"
                        value={settings.template.colors.accent}
                        onChange={(e) => handleChange('template.colors', 'accent', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                )}

                {activeTab === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Header Text"
                        multiline
                        rows={2}
                        value={settings.template.headerText}
                        onChange={(e) => handleChange('template', 'headerText', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Footer Text"
                        multiline
                        rows={2}
                        value={settings.template.footerText}
                        onChange={(e) => handleChange('template', 'footerText', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Payment Terms"
                        multiline
                        rows={2}
                        value={settings.paymentTerms}
                        onChange={(e) => handleChange(null, 'paymentTerms', e.target.value)}
                        helperText="These terms will appear at the bottom of the invoice"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Default Notes"
                        multiline
                        rows={3}
                        value={settings.notes}
                        onChange={(e) => handleChange(null, 'notes', e.target.value)}
                        helperText="These notes will be added by default to new invoices"
                      />
                    </Grid>
                  </Grid>
                )}

                {activeTab === 4 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Email Subject Template"
                        value={settings.emailText.subject}
                        onChange={(e) => handleChange('emailText', 'subject', e.target.value)}
                        helperText="Use {{businessName}}, {{invoiceNumber}} as placeholders"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Email Body Template"
                        multiline
                        rows={4}
                        value={settings.emailText.body}
                        onChange={(e) => handleChange('emailText', 'body', e.target.value)}
                        helperText="Use {{businessName}}, {{invoiceNumber}}, {{dueDate}}, {{amount}} as placeholders"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label="Reminder Email Template"
                        multiline
                        rows={4}
                        value={settings.emailText.reminder}
                        onChange={(e) => handleChange('emailText', 'reminder', e.target.value)}
                        helperText="Use {{businessName}}, {{invoiceNumber}}, {{dueDate}}, {{amount}} as placeholders"
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <SaveButton
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  {isSaving ? 'Saving...' : 'Save Settings'}
                </SaveButton>
              </Box>
            </CardContent>
          </StyledCard>
        </Grid>

        {/* Preview Panel */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <CardHeader title="Preview" />
            <CardContent>
              <Paper elevation={3}>
                <InvoiceTemplate
                  invoice={SAMPLE_INVOICE}
                  settings={settings}
                  orgInfo={orgInfo}
                />
              </Paper>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TemplateSettings;