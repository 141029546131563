// src/components/Invoices/Settings/InvoiceTemplate/index.jsx
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const InvoiceTemplate = ({ 
  invoice, 
  customer = {},
  className = '',
  settings = null,
  orgInfo = null
}) => {
  const [localOrgInfo, setLocalOrgInfo] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      if (orgInfo || !user?.orgId) return;
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', user.orgId));
        if (orgDoc.exists()) {
          setLocalOrgInfo(orgDoc.data());
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user?.orgId, orgInfo]);

  const effectiveOrgInfo = orgInfo || localOrgInfo;
  if (!effectiveOrgInfo) return null;

  const { companyInfo } = effectiveOrgInfo;
  const templateSettings = settings?.template || {};
  
  const getStyles = (templateConfig) => ({
    container: {
      '--primary-color': templateConfig?.colors?.primary || '#3b82f6',
      '--secondary-color': templateConfig?.colors?.secondary || '#f59e0b',
      '--accent-color': templateConfig?.colors?.accent || '#6366f1',
      backgroundColor: 'white',
      color: '#1f2937',
      padding: '2rem',
      maxWidth: '800px',
      margin: '0 auto',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      borderRadius: '0.5rem'
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '2rem',
      borderBottom: `2px solid var(--primary-color)`,
      paddingBottom: '1rem'
    },
    logo: {
      maxHeight: '80px',
      width: 'auto',
      marginRight: '1rem'
    },
    companyInfo: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem'
    },
    invoiceTitle: {
      color: 'var(--primary-color)',
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginBottom: '0.5rem'
    },
    billTo: {
      marginBottom: '2rem',
      padding: '1rem',
      backgroundColor: '#f9fafb',
      borderRadius: '0.375rem'
    },
    table: {
      width: '100%',
      marginBottom: '2rem',
      borderCollapse: 'collapse'
    },
    th: {
      backgroundColor: 'var(--primary-color)',
      color: 'white',
      padding: '0.75rem',
      textAlign: 'left'
    },
    td: {
      padding: '0.75rem',
      borderBottom: '1px solid #e5e7eb'
    },
    totals: {
      marginLeft: 'auto',
      width: '300px',
      backgroundColor: '#f9fafb',
      padding: '1.5rem',
      borderRadius: '0.375rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)'
    },
    totalRow: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.5rem 0',
      borderBottom: '1px solid #e5e7eb'
    },
    totalRowFinal: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0.75rem 0',
      marginTop: '0.5rem',
      borderTop: '2px solid var(--primary-color)',
      fontSize: '1.125rem',
      fontWeight: 'bold'
    },
    footer: {
      marginTop: '2rem',
      textAlign: 'center',
      color: 'var(--secondary-color)',
      borderTop: '1px solid #e5e7eb',
      paddingTop: '1rem'
    }
  });

  const styles = getStyles(templateSettings);

  // Calculate totals with tips and discounts
  const subtotal = invoice.items?.reduce((sum, item) => sum + (item.quantity * item.rate), 0) || 0;
  const tipAmount = invoice.tip || 0;
  const tipPercent = (tipAmount / subtotal) * 100;
  const discountAmount = invoice.discount || 0;
  const discountPercent = (discountAmount / subtotal) * 100;
  const taxRate = invoice.taxRate || 0.1; // 10% default tax rate
  const taxableAmount = subtotal - discountAmount;
  const tax = taxableAmount * taxRate;
  const total = subtotal + tipAmount - discountAmount + tax;

  return (
    <div style={styles.container} className={className}>
      {/* Header Section */}
      <div style={styles.header}>
        <div className="flex items-start gap-4">
          {templateSettings.showLogo && companyInfo?.logoUrl && (
            <img 
              src={companyInfo.logoUrl} 
              alt={effectiveOrgInfo.name || 'Company Logo'} 
              style={styles.logo}
            />
          )}
          <div>
            {templateSettings.showCompanyName && (
              <h1 style={styles.invoiceTitle}>{effectiveOrgInfo.name}</h1>
            )}
            <div style={styles.companyInfo}>
              {templateSettings.showCompanyAddress && (
                <>
                  {companyInfo?.address && <div>{companyInfo.address}</div>}
                  {companyInfo?.city && companyInfo?.state && companyInfo?.zipCode && (
                    <div>{`${companyInfo.city}, ${companyInfo.state} ${companyInfo.zipCode}`}</div>
                  )}
                </>
              )}
              {templateSettings.showCompanyPhone && companyInfo?.phoneNumber && (
                <div>{formatPhoneNumber(companyInfo.phoneNumber)}</div>
              )}
              {templateSettings.showCompanyEmail && companyInfo?.email && (
                <div>{companyInfo.email}</div>
              )}
            </div>
          </div>
        </div>
        
        <div className="text-right">
          {templateSettings.showInvoiceTitle && (
            <div className="text-xl font-bold mb-2">INVOICE</div>
          )}
          <div className="text-sm">
            {templateSettings.showInvoiceNumber && (
              <div>Invoice #: {invoice.number}</div>
            )}
            {templateSettings.showInvoiceDate && (
              <div>Date: {formatDate(invoice.date)}</div>
            )}
            {templateSettings.showDueDate && (
              <div>Due Date: {formatDate(invoice.dueDate)}</div>
            )}
          </div>
        </div>
      </div>

      {/* Custom Fields Section */}
      {templateSettings.customFields?.length > 0 && (
        <div className="mb-4">
          {templateSettings.customFields.map((field, index) => (
            <div key={index} className="flex justify-between text-sm">
              <span>{field.label}:</span>
              <span>{invoice.customFields?.[index]?.value || 'Sample Value'}</span>
            </div>
          ))}
        </div>
      )}

      {/* Bill To Section */}
      {templateSettings.showBillTo && (
        <div style={styles.billTo}>
          <div className="font-bold mb-2">Bill To:</div>
          <div className="text-sm">
            {templateSettings.showCustomerName && (
              <div>{customer.name || 'Sample Customer'}</div>
            )}
            {templateSettings.showCustomerAddress && (
              <>
                <div>{customer.address || '123 Sample St'}</div>
                <div>{`${customer.city || 'Sample City'}, ${customer.state || 'ST'} ${customer.zip || '12345'}`}</div>
              </>
            )}
            {templateSettings.showCustomerPhone && (
              <div>{formatPhoneNumber(customer.phone) || '(555) 555-5555'}</div>
            )}
            {templateSettings.showCustomerEmail && (
              <div>{customer.email || 'customer@example.com'}</div>
            )}
          </div>
        </div>
      )}

      {/* Items Table */}
      <table style={styles.table}>
        <thead>
          <tr>
            {templateSettings.showItemDescription && (
              <th style={styles.th}>Description</th>
            )}
            {templateSettings.showItemQuantity && (
              <th style={styles.th} className="text-right">Quantity</th>
            )}
            {templateSettings.showItemRate && (
              <th style={styles.th} className="text-right">Rate</th>
            )}
            {templateSettings.showItemAmount && (
              <th style={styles.th} className="text-right">Amount</th>
            )}
          </tr>
        </thead>
        <tbody>
          {invoice.items?.map((item, index) => (
            <tr key={index}>
              {templateSettings.showItemDescription && (
                <td style={styles.td}>{item.description}</td>
              )}
              {templateSettings.showItemQuantity && (
                <td style={styles.td} className="text-right">{item.quantity}</td>
              )}
              {templateSettings.showItemRate && (
                <td style={styles.td} className="text-right">${formatCurrency(item.rate)}</td>
              )}
              {templateSettings.showItemAmount && (
                <td style={styles.td} className="text-right">${formatCurrency(item.quantity * item.rate)}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Updated Totals Section */}
      <div style={styles.totals}>
        {templateSettings.showSubtotal && (
          <div style={styles.totalRow}>
            <div>Subtotal:</div>
            <div>${formatCurrency(subtotal)}</div>
          </div>
        )}
        
        {templateSettings.showTip && tipAmount > 0 && (
          <div style={styles.totalRow}>
            <div>Tip ({tipPercent.toFixed(1)}%):</div>
            <div>${formatCurrency(tipAmount)}</div>
          </div>
        )}

        {templateSettings.showDiscount && discountAmount > 0 && (
          <div style={styles.totalRow}>
            <div>Discount ({discountPercent.toFixed(1)}%):</div>
            <div>-${formatCurrency(discountAmount)}</div>
          </div>
        )}

        {templateSettings.showTax && (
          <div style={styles.totalRow}>
            <div>Tax ({(taxRate * 100).toFixed(1)}%):</div>
            <div>${formatCurrency(tax)}</div>
          </div>
        )}

        {templateSettings.showTotal && (
          <div style={styles.totalRowFinal}>
            <div>Total:</div>
            <div>${formatCurrency(total)}</div>
          </div>
        )}
      </div>

      {/* Notes Section */}
      {templateSettings.showNotes && (
        <div className="mt-8">
          <div className="font-bold mb-2">Notes:</div>
          <div className="text-sm">{templateSettings.defaultNotes}</div>
        </div>
      )}

      {/* Footer Section */}
      {templateSettings.showFooter && (
        <div style={styles.footer}>
          {templateSettings.showPaymentTerms && (
            <div className="mb-2">{templateSettings.defaultPaymentTerms}</div>
          )}
          {templateSettings.showThankYouMessage && (
            <div>{templateSettings.thankYouMessage}</div>
          )}
        </div>
      )}
    </div>
  );
};

// Utility functions
const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  const cleaned = phoneNumber.toString().replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumber;
};

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

const formatCurrency = (amount) => {
  if (!amount) return '0.00';
  return Number(amount).toFixed(2);
};

export default InvoiceTemplate;