// /src/components/JobDialog.js

import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { normalizeJobStatus, JOB_STATUSES } from '../context/JobContext'; 
import JobDetails from './JobDetails';
import JobEditForm from './JobEditForm';
import RecurringInstancesList from './RecurringInstancesList';
import styles from '../styles/JobDialog.module.css';
import AddRoomsModal from './AddRoomsModal';
import EmailPreviewModal from './EmailPreviewModal';

const JobDialog = ({
  open,
  onClose,
  job,
  isEditing,
  isReactivating = false,
  handleEditClick,
  handleDeleteJob,
  handleMarkCompleted,
  handleDeleteRecurringJob,
  fetchRecurringInstances,
  showingRecurringSeries,
  recurringInstances,
  generateMoreInstances,
  handleSaveEdit,
  handleCancelEdit,
  editedJob,
  handleEditInputChange,
  handleTotalSizeChange,
  handleAddRooms,
  handleDeleteRoom,
  openAddRoomsModal,
  setOpenAddRoomsModal,
  availableRooms,
  formatRecurrenceRule,
  getStatusDetails,
  handleApplyToRecurringChange,
  applyToRecurring,
  handleRevertJobStatus,
  cleaners,
  emailTemplates = [],
  onSendEmail,
  customJobStatuses = [],
  defaultJobStatuses = [],
  handleUpdateStatus,
  onUpdate,
}) => {
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [organizationInfo, setOrganizationInfo] = useState(null);

  useEffect(() => {
    const fetchOrgInfo = async () => {
      if (job?.organizationId) {
        try {
          const orgDocRef = doc(db, 'organizations', job.organizationId);
          const orgDocSnap = await getDoc(orgDocRef);
          
          if (orgDocSnap.exists()) {
            setOrganizationInfo(orgDocSnap.data());
          }
        } catch (error) {
          console.error('Error fetching organization info:', error);
        }
      }
    };
    
    fetchOrgInfo();
  }, [job?.organizationId]);

  const renderDialogActions = () => (
    <DialogActions className={styles.dialogActions}>
      {isEditing ? (
        <>
          <Button
            onClick={handleSaveEdit}
            variant="contained"
            color={isReactivating ? "success" : "primary"}
            className={styles.actionButton}
          >
            {isReactivating ? "Reactivate Service" : "Save Changes"}
          </Button>
          <Button
            onClick={handleCancelEdit}
            variant="outlined"
            color="secondary"
            className={styles.actionButton}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={handleEmailGenerate}
            className={`${styles.actionButton} ${styles.generateEmail}`}
            startIcon={<EmailIcon />}
          >
            Generate Email
          </Button>
          <Button
            onClick={handleEditClick}
            className={styles.actionButton}
          >
            Edit
          </Button>
          <Button
            onClick={handleDeleteJob}
            className={styles.actionButton}
          >
            Delete
          </Button>
          {normalizeJobStatus(job?.status) === JOB_STATUSES.PENDING && !isReactivating && (
            <Button
              onClick={handleMarkCompleted}
              variant="contained"
              color="success"
              className={styles.actionButton}
            >
              Mark Complete
            </Button>
          )}
          {job?.recurring && normalizeJobStatus(job?.status) === JOB_STATUSES.PENDING && !isReactivating && (
            <Button
              onClick={() => handleDeleteRecurringJob(job.recurrenceGroupId)}
              variant="outlined"
              color="error"
              className={styles.actionButton}
            >
              Delete All Recurring
            </Button>
          )}
          {job?.recurring && !isReactivating && (
          <Button
          onClick={() => fetchRecurringInstances(job.recurrenceGroupId)}
          className={styles.actionButton}
        >
          View Series
        </Button>
          )}
        </>
      )}
        <Button
          onClick={onClose}
          className={styles.actionButton}
        >
          Close
        </Button>
    </DialogActions>
  );

  const handleEmailGenerate = () => {
    setEmailModalOpen(true);
  };

  const handleSendEmail = async (emailData) => {
    try {
      await onSendEmail({
        jobId: job.id,
        ...emailData
      });
      setEmailModalOpen(false);
      // You might want to add a success notification here
    } catch (error) {
      // Handle error
      console.error('Error sending email:', error);
    }
  };

  const handleStatusChange = async (newStatus) => {
    // Handle status change if needed at dialog level
    console.log('Status changed to:', newStatus);
  };

  const handleOpenEmailPreview = () => {
    setEmailModalOpen(true);
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth 
      className={styles.dialog}
      PaperProps={{
        className: styles.dialogPaper
      }}
    >
      {job && (
        <>
          <DialogTitle className={styles.dialogTitle}>
            {isReactivating 
              ? 'Reactivate Recurring Service'
              : isEditing 
                ? 'Edit Job' 
                : job.customerName}
          </DialogTitle>
          <DialogContent className={styles.dialogContent}>
            {isEditing || isReactivating ? (
              <>
                {isReactivating && (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    You are reactivating a recurring service. Please update the service details and schedule as needed.
                  </Alert>
                )}
                <JobEditForm
                  job={editedJob}
                  handleEditInputChange={handleEditInputChange}
                  handleTotalSizeChange={handleTotalSizeChange}
                  handleAddRooms={handleAddRooms}
                  handleDeleteRoom={handleDeleteRoom}
                  openAddRoomsModal={openAddRoomsModal}
                  setOpenAddRoomsModal={setOpenAddRoomsModal}
                  availableRooms={availableRooms}
                  handleApplyToRecurringChange={handleApplyToRecurringChange}
                  applyToRecurring={applyToRecurring}
                  isReactivating={isReactivating}
                  cleaners={cleaners}
                />
              </>
            ) : (
              <>
                <JobDetails
                  job={job}
                  getStatusDetails={getStatusDetails}
                  onReopenJob={handleRevertJobStatus}
                  customJobStatuses={customJobStatuses}
                  defaultJobStatuses={defaultJobStatuses}
                  onStatusChange={handleStatusChange}
                  onOpenEmailPreview={handleOpenEmailPreview}
                  handleUpdateStatus={handleUpdateStatus}
                  onUpdate={onUpdate}
                />
                <Typography className={styles.jobLink}>
                  <strong>Job Link:</strong>{' '}
                  {`${window.location.origin}/org/${job.organizationId}/job/${job.id}`}
                </Typography>
                {showingRecurringSeries && !isReactivating && (
                  <>
                    <RecurringInstancesList 
                    instances={recurringInstances}
                    organizationId={job.organizationId}
                    recurrenceGroupId={job.recurrenceGroupId}
                    />
                    <Button
                      onClick={() => generateMoreInstances(job)}
                      variant="outlined"
                      sx={{ mt: 2 }}
                    >
                      Generate More Instances
                    </Button>
                  </>
                )}
              </>
            )}
          </DialogContent>
          {renderDialogActions()}
          
          {openAddRoomsModal && (
            <AddRoomsModal
              open={openAddRoomsModal}
              handleClose={() => setOpenAddRoomsModal(false)}
              availableRooms={availableRooms}
              handleAddRooms={handleAddRooms}
              existingRooms={editedJob?.rooms || []}
              orgId={job?.organizationId}
              fetchAvailableRooms={() => {}}
            />
          )}

          <EmailPreviewModal
            open={emailModalOpen}
            onClose={() => setEmailModalOpen(false)}
            jobData={{
              // Ensure proper date objects for times
              appointmentDate: job.appointmentDate?.toDate ? job.appointmentDate.toDate() : new Date(job.appointmentDate),
              scheduledStartTime: job.scheduledStartTime?.toDate ? job.scheduledStartTime.toDate() : new Date(job.scheduledStartTime),
              scheduledEndTime: job.scheduledEndTime?.toDate ? job.scheduledEndTime.toDate() : new Date(job.scheduledEndTime),
              
              // Customer info
              customerName: job.customerName,
              customerEmail: job.customerEmail,
              customerPhone: job.customerPhone,
              address: job.address,
              
              // Cleaner info 
              cleanerDetails: job.cleanerDetails || job.cleaners || [],
              
              // Organization info from the organization context/data
              organizationName: organizationInfo?.name,
              organizationLogo: organizationInfo?.companyInfo?.logoUrl,
              organizationPhone: organizationInfo?.companyInfo?.phoneNumber, 
              organizationEmail: organizationInfo?.companyInfo?.email,
              
              // Service details
              serviceCost: job.serviceCost,
              serviceType: job.serviceType,
              status: job.status,
              totalSize: job.totalSize,
              notes: job.notes,
              importantNotes: job.importantNotes,
              
              // For recurring jobs
              recurringStatus: job.recurringStatus,
              recurrenceRule: job.recurrenceRule
            }}
            onSend={handleSendEmail}
            templates={emailTemplates}
          />
        </>
      )}
    </Dialog>
  );
};

export default JobDialog;
