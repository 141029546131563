import React, { useState, useEffect, useCallback, useRef } from 'react';
import { GoogleMap, Polyline, InfoWindow } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Slider,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Stack,
} from '@mui/material';
import {
  PlayArrow,
  Pause,
  DirectionsCar,
  DirectionsWalk,
  LocationOn,
  AccessTime,
} from '@mui/icons-material';
import moment from 'moment';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import LocationMapModal from './LocationMapModal';


const LocationTrackingDashboard = ({ timesheetEntry, onError }) => {
  const [locations, setLocations] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState(null);

  // Use refs for values that shouldn't trigger re-renders
  const mapRef = useRef(null);
  const locationsRef = useRef([]);
  const fetchingRef = useRef(false);
  const playbackIntervalRef = useRef(null);

  // Memoize map bounds calculation
  const getMapBounds = useCallback(() => {
    if (!locationsRef.current.length) return null;
    try {
      const bounds = new window.google.maps.LatLngBounds();
      locationsRef.current.forEach(location => {
        bounds.extend({ lat: location.latitude, lng: location.longitude });
      });
      return bounds;
    } catch (err) {
      console.error('Error calculating bounds:', err);
      return null;
    }
  }, []);

  // Memoize map load callback
const polylineRef = useRef(null);

const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    
    if (locations.length > 0) {
      try {
        // Create the complete path initially
        const fullPath = locations.map(loc => ({
          lat: Number(loc.latitude),
          lng: Number(loc.longitude)
        }));
        
        // Create polyline with full path
        polylineRef.current = new window.google.maps.Polyline({
          path: fullPath,
          map: map,
          strokeColor: '#2196F3',
          strokeWeight: 4,
          strokeOpacity: 1.0,
          geodesic: true
        });
  
        map.setZoom(15);
        map.setCenter({
          lat: Number(locations[0].latitude),
          lng: Number(locations[0].longitude)
        });
      } catch (err) {
        console.error("Error in map initialization:", err);
      }
    }
  }, [locations]);

// Add this effect to update the polyline path during playback
useEffect(() => {
  if (polylineRef.current && locations.length > 0) {
    const currentPath = locations.slice(0, currentIndex + 1).map(loc => ({
      lat: Number(loc.latitude),
      lng: Number(loc.longitude)
    }));
    polylineRef.current.setPath(currentPath);
  }
}, [currentIndex, locations]);

// Add cleanup to the component
useEffect(() => {
  return () => {
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }
  };
}, []);

  // Fetch locations only once when component mounts or timesheetEntry changes
  useEffect(() => {
    if (!timesheetEntry?.orgId || !timesheetEntry?.cleanerId || !timesheetEntry?.id) {
      setError('Missing required timesheet entry data');
      setLoading(false);
      return;
    }

    const fetchLocations = async () => {
      if (fetchingRef.current) return;
      
      try {
        fetchingRef.current = true;
        setLoading(true);

        const locationsCollectionRef = collection(
          db,
          'organizations',
          timesheetEntry.orgId,
          'cleaners',
          timesheetEntry.cleanerId,
          'timesheet',
          timesheetEntry.id,
          'locationHistory'
        );
        
        const locationsQuery = query(locationsCollectionRef, orderBy('timestamp', 'asc'));
        const locationsSnapshot = await getDocs(locationsQuery);
        
        const locationData = locationsSnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              ...data
            };
          });

        if (locationData.length === 0) {
          setError('No location data available for this timesheet entry');
        } else {
          locationsRef.current = locationData;
          setLocations(locationData);
          setCurrentIndex(0);
          
          // Force the map to center on the first location
          if (mapRef.current) {
            mapRef.current.setCenter({
              lat: Number(locationData[0].latitude),
              lng: Number(locationData[0].longitude)
            });
            mapRef.current.setZoom(14); // Adjust zoom level as needed
          }
        }
      } catch (err) {
        console.error('Error fetching locations:', err);
        setError('Failed to fetch location data');
        if (onError) onError(err);
      } finally {
        fetchingRef.current = false;
        setLoading(false);
      }
    };

    fetchLocations();

    // Cleanup function
    return () => {
      fetchingRef.current = false;
      if (playbackIntervalRef.current) {
        clearInterval(playbackIntervalRef.current);
      }
    };
  }, [timesheetEntry?.id, timesheetEntry?.orgId, timesheetEntry?.cleanerId, onError]);

  // Handle playback with optimized interval
  useEffect(() => {
    if (playbackIntervalRef.current) {
      clearInterval(playbackIntervalRef.current);
      playbackIntervalRef.current = null;
    }

    if (isPlaying && currentIndex < locations.length - 1) {
      playbackIntervalRef.current = setInterval(() => {
        setCurrentIndex(prev => {
          const next = prev + 1;
          if (next >= locations.length) {
            setIsPlaying(false);
            clearInterval(playbackIntervalRef.current);
            return prev;
          }
          return next;
        });
      }, 1000 / playbackSpeed);
    }

    return () => {
      if (playbackIntervalRef.current) {
        clearInterval(playbackIntervalRef.current);
      }
    };
  }, [isPlaying, playbackSpeed, locations.length]);

  // Add this before the return statement
  useEffect(() => {
    if (locations.length > 0) {
      locations.forEach((loc, index) => {
      });
    }
  }, [locations]);

  // Early returns for different states
  if (!timesheetEntry) {
    return (
      <Box p={2}>
        <Typography color="error">No timesheet entry provided</Typography>
      </Box>
    );
  }

  if (!timesheetEntry.orgId || !timesheetEntry.cleanerId || !timesheetEntry.id) {
    return (
      <Box p={2}>
        <Typography color="error">Invalid timesheet entry data</Typography>
        <Typography variant="body2">
          Required fields: orgId, cleanerId, and id must be present
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
        <Typography ml={2}>Loading location data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  const currentLocation = locations[currentIndex];

  // Extract locations from timesheet entry
  const locationsFromEntry = [];
  if (timesheetEntry.inLocation) {
    locationsFromEntry.push({
      ...timesheetEntry.inLocation,
      timestamp: timesheetEntry.inDate,
      type: 'Clock In'
    });
  }
  if (timesheetEntry.outLocation) {
    locationsFromEntry.push({
      ...timesheetEntry.outLocation,
      timestamp: timesheetEntry.outDate,
      type: 'Clock Out'
    });
  }

  // Handle marker click to open modal
  const handleMarkerClick = (location, type) => {
    setSelectedLocationData({
      location: location,
      timestamp: type === 'Clock In' ? timesheetEntry.inDate : timesheetEntry.outDate,
      type: type,
      cleanerName: timesheetEntry.cleanerName
    });
    setShowLocationModal(true);
  };

  return (
    <Box>
      <Card>
        <CardContent>
          <Box height="500px">
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '100%',
                border: '1px solid #ccc'
              }}
              zoom={15}
              onLoad={onMapLoad}
              center={timesheetEntry?.inLocation ? {
                lat: Number(timesheetEntry.inLocation.latitude),
                lng: Number(timesheetEntry.inLocation.longitude)
              } : {
                lat: 39.6505457,
                lng: -83.5570308
              }}
              options={{
                zoomControl: true,
                streetViewControl: true,
                mapTypeControl: true,
                fullscreenControl: true,
                mapTypeId: 'roadmap',
                gestureHandling: 'greedy',
                disableDefaultUI: false,
                clickableIcons: false,
                maxZoom: 18,
                minZoom: 3
              }}
            >
              {/* Clock In Location */}
              {timesheetEntry?.inLocation && (
                <Marker
                  position={{
                    lat: Number(timesheetEntry.inLocation.latitude),
                    lng: Number(timesheetEntry.inLocation.longitude)
                  }}
                  options={{
                    map: mapRef.current,
                    visible: true
                  }}
                  onClick={() => handleMarkerClick(timesheetEntry.inLocation, 'Clock In')}
                />
              )}

              {/* Clock Out Location */}
              {timesheetEntry?.outLocation && (
                <Marker
                  position={{
                    lat: Number(timesheetEntry.outLocation.latitude),
                    lng: Number(timesheetEntry.outLocation.longitude)
                  }}
                  options={{
                    map: mapRef.current,
                    visible: true
                  }}
                  onClick={() => handleMarkerClick(timesheetEntry.outLocation, 'Clock Out')}
                />
              )}

              {/* Route line between clock in and clock out */}
              {locations.length > 1 && (
                <Polyline
                    options={{
                    path: locations.map(loc => ({
                        lat: Number(loc.latitude),
                        lng: Number(loc.longitude)
                    })),
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 8,
                    geodesic: true,
                    visible: true,
                    zIndex: 999
                    }}
                />
                )}
            </GoogleMap>
          </Box>

          <Box mt={2}>
            <Stack direction="row" spacing={2} alignItems="center">
              <IconButton onClick={() => setIsPlaying(!isPlaying)}>
                {isPlaying ? <Pause /> : <PlayArrow />}
              </IconButton>

              <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
                <Select
                  value={playbackSpeed}
                  onChange={(e) => setPlaybackSpeed(e.target.value)}
                >
                  <MenuItem value={1}>1x</MenuItem>
                  <MenuItem value={2}>2x</MenuItem>
                  <MenuItem value={4}>4x</MenuItem>
                  <MenuItem value={8}>8x</MenuItem>
                </Select>
              </FormControl>

              <Typography variant="body2" sx={{ flex: 1 }}>
                {currentLocation && (
                  <>
                    <AccessTime sx={{ verticalAlign: 'middle', mr: 1 }} />
                    {moment(currentLocation.timestamp.toDate()).format('h:mm:ss A')}
                  </>
                )}
              </Typography>

              {currentLocation && getActivityIcon(currentLocation.activityType)}
            </Stack>

            <Slider
              value={currentIndex}
              min={0}
              max={locations.length - 1}
              onChange={(e, newValue) => {
                setCurrentIndex(newValue);
                setIsPlaying(false);
              }}
              sx={{ mt: 2 }}
            />
          </Box>
        </CardContent>
      </Card>

      {/* Location Modal */}
      {showLocationModal && selectedLocationData && (
        <LocationMapModal
          open={showLocationModal}
          onClose={() => {
            setShowLocationModal(false);
            setSelectedLocationData(null);
          }}
          location={selectedLocationData.location}
          timestamp={selectedLocationData.timestamp}
          type={selectedLocationData.type}
          cleanerName={selectedLocationData.cleanerName}
        />
      )}
    </Box>
  );
};

const getActivityIcon = (activityType) => {
  switch (activityType) {
    case 'AUTOMOTIVE':
      return <DirectionsCar />;
    case 'WALKING':
      return <DirectionsWalk />;
    default:
      return <LocationOn />;
  }
};

// Memoize the entire component
export default React.memo(LocationTrackingDashboard, (prevProps, nextProps) => {
    // Only re-render if the timesheet entry ID changes
    return (
      prevProps.timesheetEntry?.id === nextProps.timesheetEntry?.id &&
      prevProps.timesheetEntry?.orgId === nextProps.timesheetEntry?.orgId &&
      prevProps.timesheetEntry?.cleanerId === nextProps.timesheetEntry?.cleanerId
    );
  });