// /src/components/Sidebar.js

import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaFileAlt, FaTasks, FaCog, FaPlus, FaUser, FaFileInvoiceDollar } from 'react-icons/fa';
import logo from '../logo.png';
import dmlogo from '../dmlogo.png';
import '../styles/global.css';

function Sidebar({ isDarkMode }) {
    const location = useLocation();
    const [activeTooltip, setActiveTooltip] = useState(null);

    const menuItems = [
        { 
            path: '/sales-tools', 
            icon: FaFileAlt, 
            label: 'Client Sales Tools',
            subLinks: [
                { label: 'Client Sales Tools', path: '/sales-tools' },
                { label: 'Lead Analysis', path: '/lead-analysis' }
            ]
        },
        { 
            path: '/invoices', 
            icon: FaFileInvoiceDollar, 
            label: 'Invoices',
            subLinks: [
              { label: 'Dashboard', path: '/invoices' },
              { label: 'All Invoices', path: '/invoices/list' },
              { label: 'Create Invoice', path: '/invoices/create' },
              { label: 'Settings', path: '/invoices/settings' }
            ]
          },
        { 
            path: '/jobs', 
            icon: FaPlus, 
            label: 'Jobs Management',
            subLinks: [
                { label: 'Create New Job', path: '/jobs/create' },
                { label: 'Jobs Overview', path: '/jobs/jobs-overview' },
                { label: 'Rooms & Tasks', path: '/jobs/rooms-tasks' },
                { label: 'Clients', path: '/jobs/clients' },
                { label: 'Cleaners', path: '/jobs/cleaners' },
            ]
        },
        {
            path: '/cleaners',
            icon: FaUser,
            label: 'Team Management',
            subLinks: [
                { label: 'Time Clock', path: '/time-clock' },
                { label: 'Cleaners Overview', path: '/cleaners' },
                { label: 'Cleaner Profile', path: '/organizations/:orgId/cleaners/:cleanerId' },
                { label: 'Expense Requests', path: '/organizations/:orgId/expenses' }
            ]
        },
        { 
            path: '/org-settings', 
            icon: FaCog, 
            label: 'Settings',
            subLinks: [
                { label: 'Organization Profile', path: '/org-settings' }
            ]
        },
    ];

    return (
        <div className="sidebar">
            <img src={isDarkMode ? dmlogo : logo} alt="Logo" className="logo" />
            {menuItems.map(({ path, icon: Icon, label, subLinks }) => (
                <div 
                    key={path} 
                    className="tooltip-wrapper"
                    onMouseEnter={() => setActiveTooltip(path)}
                    onMouseLeave={() => setActiveTooltip(null)}
                >
                    <Link 
                        to={path} 
                        className={location.pathname.startsWith(path) ? 'active' : ''}
                    >
                        <Icon />
                    </Link>
                    {activeTooltip === path && (
                        <div className="tooltip">
                            <p className="tooltip-title">{label}</p>
                            <div className="tooltip-links">
                                {subLinks.map((link) => (
                                    <Link 
                                        key={link.path} 
                                        to={link.path}
                                        className={location.pathname === link.path ? 'tooltip-link active' : 'tooltip-link'}
                                    >
                                        {link.label}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default Sidebar;