// /src/components/UserModal.js

import React, { useEffect } from 'react';
import styles from '../styles/UserModal.module.css';

const UserModal = ({ isOpen, onClose, onSubmit, loading, initialData }) => {
  const [formData, setFormData] = React.useState({
    email: '',
    role: 'member',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    address: '',
    payType: 'salary',
    payRate: '',
    manHourCost: '',
    calendarColor: '#9DCBFC',
    // New cleaner-specific fields
    isActive: true,
    maxJobs: '',
    availability: {
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false,
    },
    workingHours: {
      monday: { start: '09:00', end: '17:00' },
      tuesday: { start: '09:00', end: '17:00' },
      wednesday: { start: '09:00', end: '17:00' },
      thursday: { start: '09:00', end: '17:00' },
      friday: { start: '09:00', end: '17:00' },
      saturday: { start: '', end: '' },
      sunday: { start: '', end: '' },
    },
  });

  useEffect(() => {
    if (initialData) {
      setFormData({
        uid: initialData.uid,
        email: initialData.email || '',
        role: initialData.role || 'member',
        firstName: initialData.firstName || '',
        lastName: initialData.lastName || '',
        phoneNumber: initialData.phoneNumber || '',
        address: initialData.address || '',
        payType: initialData.payType || 'salary',
        payRate: initialData.payRate || '',
        manHourCost: initialData.manHourCost || '',
        calendarColor: initialData.calendarColor || '#9DCBFC',
        isActive: initialData.isActive !== false, // Default to true if undefined
        maxJobs: initialData.maxJobs || '',
        availability: initialData.availability || {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        },
        workingHours: initialData.workingHours || {
          monday: { start: '09:00', end: '17:00' },
          tuesday: { start: '09:00', end: '17:00' },
          wednesday: { start: '09:00', end: '17:00' },
          thursday: { start: '09:00', end: '17:00' },
          friday: { start: '09:00', end: '17:00' },
          saturday: { start: '', end: '' },
          sunday: { start: '', end: '' },
        },
      });
    } else {
      setFormData({
        email: '',
        role: 'member',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        address: '',
        payType: 'salary',
        payRate: '',
        manHourCost: '',
        isActive: true,
        maxJobs: '',
        availability: {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: false,
          sunday: false,
        },
        workingHours: {
          monday: { start: '09:00', end: '17:00' },
          tuesday: { start: '09:00', end: '17:00' },
          wednesday: { start: '09:00', end: '17:00' },
          thursday: { start: '09:00', end: '17:00' },
          friday: { start: '09:00', end: '17:00' },
          saturday: { start: '', end: '' },
          sunday: { start: '', end: '' },
        },
      });
    }
  }, [initialData]);

  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleAvailabilityChange = (day) => {
    setFormData((prev) => ({
      ...prev,
      availability: {
        ...prev.availability,
        [day]: !prev.availability[day]
      }
    }));
  };

  const handleWorkingHoursChange = (day, type, value) => {
    setFormData((prev) => ({
      ...prev,
      workingHours: {
        ...prev.workingHours,
        [day]: {
          ...prev.workingHours[day],
          [type]: value,
        },
      },
    }));
  };
  
  const copyToAllDays = (day) => {
    const { start, end } = formData.workingHours[day];
    setFormData((prev) => ({
      ...prev,
      workingHours: Object.keys(prev.workingHours).reduce((acc, key) => {
        if (prev.availability[key]) {
          acc[key] = { start, end };
        } else {
          acc[key] = prev.workingHours[key];
        }
        return acc;
      }, {}),
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  if (!isOpen) return null;

  const renderCleanerFields = () => {
    if (formData.role !== 'cleaner') return null;

    return (
      <>

        <div className={styles.userFormGroup}>
          <label htmlFor="calendarColor">Calendar Color</label>
          <div className={styles.colorPickerContainer}>
            <input
              id="calendarColor"
              type="color"
              value={formData.calendarColor}
              onChange={(e) => handleChange('calendarColor', e.target.value)}
              className={styles.colorPicker}
            />
            <span className={styles.colorValue}>{formData.calendarColor}</span>
          </div>
        </div>

        <div className={styles.userFormGroup}>
          <label htmlFor="maxJobs">Maximum Concurrent Jobs</label>
          <input
            id="maxJobs"
            type="number"
            value={formData.maxJobs}
            onChange={(e) => handleChange('maxJobs', e.target.value)}
            placeholder="3"
          />
        </div>

        <div className={styles.userFormGroup}>
        <label>Availability</label>
        <div className={styles.availabilityGrid}>
          {Object.keys(formData.availability).map((day) => (
            <label key={day} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={formData.availability[day]}
                onChange={() => handleAvailabilityChange(day)}
              />
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </label>
          ))}
        </div>
      </div>

      <div className={styles.workingHours}>
        <h4>Working Hours</h4>
        {Object.keys(formData.workingHours).map((day) => (
          <div key={day} className={styles.workingHoursRow}>
            <label className={styles.dayLabel}>
              {day.charAt(0).toUpperCase() + day.slice(1)}:
            </label>
            <input
              type="time"
              value={formData.workingHours[day].start}
              onChange={(e) => handleWorkingHoursChange(day, 'start', e.target.value)}
              disabled={!formData.availability[day]}
            />
            <input
              type="time"
              value={formData.workingHours[day].end}
              onChange={(e) => handleWorkingHoursChange(day, 'end', e.target.value)}
              disabled={!formData.availability[day]}
            />
          </div>
        ))}
        <button
          type="button"
          className={styles.copyButton}
          onClick={() => copyToAllDays('monday')}
        >
          Copy Monday's Hours to All Days
        </button>
      </div>

        <div className={styles.userFormGroup}>
        <label htmlFor="activeStatus">Status</label>
        <select
          id="activeStatus"
          value={formData.isActive ? 'active' : 'inactive'}
          onChange={(e) => handleChange('isActive', e.target.value === 'active')}
        >
          <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select> 
        </div>
      </>
    );
  };

  return (
    <div className={styles.userModalOverlay}>
      <div className={styles.userModalContent}>
        <h2 className={styles.userModalTitle}>
          {initialData ? 'Edit User' : 'Add New User'}
        </h2>

        <form onSubmit={handleSubmit} className={styles.userForm}>
          <div className={styles.userFormRow}>
            <div className={styles.userFormGroup}>
              <label htmlFor="firstName">First Name</label>
              <input
                id="firstName"
                value={formData.firstName}
                onChange={(e) => handleChange('firstName', e.target.value)}
                placeholder="John"
              />
            </div>

            <div className={styles.userFormGroup}>
              <label htmlFor="lastName">Last Name</label>
              <input
                id="lastName"
                value={formData.lastName}
                onChange={(e) => handleChange('lastName', e.target.value)}
                placeholder="Doe"
              />
            </div>
          </div>

          <div className={styles.userFormGroup}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
              placeholder="john.doe@example.com"
              disabled={!!initialData}
            />
          </div>

          <div className={styles.userFormGroup}>
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              id="phoneNumber"
              type="tel"
              value={formData.phoneNumber}
              onChange={(e) => handleChange('phoneNumber', e.target.value)}
              placeholder="(555) 555-5555"
            />
          </div>

          <div className={styles.userFormGroup}>
            <label htmlFor="address">Address</label>
            <input
              id="address"
              value={formData.address}
              onChange={(e) => handleChange('address', e.target.value)}
              placeholder="123 Main St, City, State ZIP"
            />
          </div>

          <div className={styles.userFormRow}>
            <div className={styles.userFormGroup}>
              <label htmlFor="role">Role</label>
              <select
                id="role"
                value={formData.role}
                onChange={(e) => handleChange('role', e.target.value)}
              >
                <option value="member">Member</option>
                <option value="admin">Admin</option>
                <option value="cleaner">Cleaner</option>
              </select>
            </div>

            <div className={styles.userFormGroup}>
              <label htmlFor="payType">Pay Type</label>
              <select
                id="payType"
                value={formData.payType}
                onChange={(e) => handleChange('payType', e.target.value)}
              >
                <option value="salary">Salary</option>
                <option value="hourly">Hourly</option>
                <option value="contract">Contract</option>
                <option value="per-job">Per Job</option>
              </select>
            </div>
          </div>

          <div className={styles.userFormRow}>
            <div className={styles.userFormGroup}>
              <label htmlFor="payRate">Pay Rate</label>
              <input
                id="payRate"
                type="number"
                value={formData.payRate}
                onChange={(e) => handleChange('payRate', e.target.value)}
                placeholder="0.00"
              />
            </div>

            <div className={styles.userFormGroup}>
              <label htmlFor="manHourCost">Man Hour Cost</label>
              <input
                id="manHourCost"
                type="number"
                value={formData.manHourCost}
                onChange={(e) => handleChange('manHourCost', e.target.value)}
                placeholder="0.00"
              />
            </div>
          </div>

          {renderCleanerFields()}

          <div className={styles.userButtonGroup}>
            <button
              type="button"
              className={`${styles.button} ${styles.userButtonSecondary}`}
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`${styles.button} ${styles.userButtonPrimary}`}
              disabled={loading}
            >
              {loading ? (initialData ? 'Updating...' : 'Adding...') : initialData ? 'Update User' : 'Add User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserModal;