import React from 'react';
import { CardHeader, CardContent, useTheme, Typography } from '@mui/material';
import {
  Html,
  Head,
  Preview,
  Body,
  Container,
  Section,
  Text,
  Link,
  Button,
  Img,
  Hr,
} from '@react-email/components';
import { 
  StyledCard,
  PreviewContainer,
  EmailHeader,
  EmailMetadata,
  EmailBody 
} from './EmailStyledComponents';
import DOMPurify from 'dompurify';
import Handlebars from 'handlebars';

// Register custom Handlebars helpers if needed
Handlebars.registerHelper('dateFormat', function(date) {
  return new Date(date).toLocaleDateString();
});

const EmailTemplate = ({ template, variables, mode = 'html' }) => {
  const theme = useTheme();
  
  try {
    // Compile the different template parts
    const compiledHeaderText = Handlebars.compile(template.headerText);
    const compiledFooterText = Handlebars.compile(template.footerText);
    
    // Get customer name based on addressAs setting
    let customerName = '';
    switch(template.addressAs) {
      case 'First Name':
        customerName = variables.customer.firstName;
        break;
      case 'Full Name':
        customerName = variables.customer.fullName;
        break;
      case 'Company Name':
        customerName = variables.customer.companyName;
        break;
      default:
        customerName = variables.customer.firstName;
    }

    return (
      <Container style={{ padding: '20px' }}>
        {variables.organization.logoUrl && (
          <Img
            src={variables.organization.logoUrl}
            alt={variables.organization.name}
            width={200}
            style={{ marginBottom: '20px' }}
          />
        )}
        
        <Text>Dear {customerName},</Text>
        
        <Text>{compiledHeaderText(variables)}</Text>
        
        <Button 
          href={variables.invoice.paymentLink}
          style={{
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            padding: '10px 20px',
            margin: '20px 0',
            borderRadius: '4px',
            textDecoration: 'none',
          }}
        >
          {template.buttonText}
        </Button>
        
        <Text>{compiledFooterText(variables)}</Text>
        
        <Hr style={{ margin: '20px 0' }} />
        
        <Text style={{ fontSize: '14px', color: '#666' }}>
          {variables.organization.name}<br />
          {variables.organization.address}<br />
          {variables.organization.phone}<br />
          {variables.organization.email}
        </Text>
      </Container>
    );
  } catch (error) {
    console.error('Template rendering error:', error);
    return (
      <Typography color="error" sx={{ p: 2 }}>
        Error rendering template: {error.message}
      </Typography>
    );
  }
};

const EmailPreview = ({ template, variables, mode }) => {
  const theme = useTheme();

  if (!template || !variables) {
    return (
      <StyledCard>
        <CardHeader title="Email Preview" />
        <CardContent>
          <Typography>No template or variables provided</Typography>
        </CardContent>
      </StyledCard>
    );
  }

  return (
    <StyledCard sx={{ height: '100%' }}>
      <CardHeader 
        title="Email Preview" 
        subheader={`Preview in ${mode === 'html' ? 'HTML' : 'Plain Text'} mode`}
      />
      <CardContent sx={{ p: 0, '&:last-child': { pb: 0 }, height: 'calc(100% - 72px)' }}>
        <PreviewContainer elevation={0}>
          <EmailHeader>
            <EmailMetadata>
              <Typography variant="subtitle2">From:</Typography>
              <Typography variant="subtitle2">
                {variables.organization.name} &lt;{variables.organization.email}&gt;
              </Typography>
              <Typography variant="subtitle2">To:</Typography>
              <Typography variant="subtitle2">
                {variables.customer.name} &lt;{variables.customer.email}&gt;
              </Typography>
              <Typography variant="subtitle2">Subject:</Typography>
              <Typography 
                variant="subtitle2" 
                sx={{ color: theme.palette.primary.main, fontWeight: 500 }}
              >
                {/* Compile and render the subject template */}
                {(() => {
                  try {
                    const compiledSubject = Handlebars.compile(template.subject);
                    return compiledSubject(variables);
                  } catch (error) {
                    console.error('Subject template error:', error);
                    return template.subject;
                  }
                })()}
              </Typography>
            </EmailMetadata>
          </EmailHeader>
          <EmailBody mode={mode}>
            <EmailTemplate 
              template={template} 
              variables={variables} 
              mode={mode} 
            />
          </EmailBody>
        </PreviewContainer>
      </CardContent>
    </StyledCard>
  );
};

export default EmailPreview;