// components/timeclock/LocationMapModal.js
import React, { useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_CONFIG } from '../../constants/googleMaps';
import moment from 'moment';

const LocationMapModal = ({ 
  open, 
  onClose, 
  inLocation, 
  outLocation, 
  cleanerName,
  inDate,
  outDate 
}) => {
  const [map, setMap] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_CONFIG.apiKey
  });

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const getMapCenter = useCallback(() => {
    if (inLocation) {
      return {
        lat: Number(inLocation.latitude),
        lng: Number(inLocation.longitude)
      };
    }
    if (outLocation) {
      return {
        lat: Number(outLocation.latitude),
        lng: Number(outLocation.longitude)
      };
    }
    return { lat: 39.6505479, lng: -83.5570378 };
  }, [inLocation, outLocation]);

  const containerStyle = {
    width: '100%',
    height: '400px'
  };

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: true,
    streetViewControl: true,
    fullscreenControl: true
  };

  if (!isLoaded) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Location Details - {cleanerName}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={getMapCenter()}
          zoom={18}
          options={mapOptions}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {inLocation && map && (
            <Marker
              position={{
                lat: Number(inLocation.latitude),
                lng: Number(inLocation.longitude)
              }}
              map={map}
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                scaledSize: new window.google.maps.Size(32, 32),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(16, 32),
              }}
            />
          )}
          {outLocation && map && (
            <Marker
              position={{
                lat: Number(outLocation.latitude),
                lng: Number(outLocation.longitude)
              }}
              map={map}
              icon={{
                url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                scaledSize: new window.google.maps.Size(32, 32),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(16, 32),
              }}
            />
          )}
        </GoogleMap>
        <Box mt={2}>
          {inLocation && (
            <Box mb={1}>
              <Typography variant="subtitle2" color="primary">
                Clock In Location:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {`${inLocation.latitude}, ${inLocation.longitude}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {inDate && moment(inDate.toDate()).format('MMM D, YYYY h:mm A')}
              </Typography>
            </Box>
          )}
          {outLocation && (
            <Box>
              <Typography variant="subtitle2" color="error">
                Clock Out Location:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {`${outLocation.latitude}, ${outLocation.longitude}`}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {outDate && moment(outDate.toDate()).format('MMM D, YYYY h:mm A')}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LocationMapModal;