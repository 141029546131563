// src/hooks/useCalculateDistance.js

import { useState, useEffect } from 'react';
import { fetchGeocodeData, fetchDistanceData } from '../utils/api';
import config from '../config';

const BASE_ADDRESS = '278 Mertland Ave, Dayton, OH 45431';

const useClientDistance = (client, getGeocodeFromCache, updateGeocodeCache) => {
  const [distance, setDistance] = useState(null);
  const [duration, setDuration] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const calculateDistance = async () => {
      if (!client || !client.address1 || !client.city || !client.state || !client.postalCode) {
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const clientAddress = `${client.address1}, ${client.city}, ${client.state} ${client.postalCode}`;

        // Use stored coordinates if available
        const clientCoords = client.coordinates || 
          await fetchGeocodeData(clientAddress, getGeocodeFromCache, updateGeocodeCache);

        // Get base address coordinates (could also be cached)
        const baseCoords = await fetchGeocodeData(BASE_ADDRESS, getGeocodeFromCache, updateGeocodeCache);

        // Calculate distance
        const distanceData = await fetchDistanceData(
          `${clientCoords.lat},${clientCoords.lng}`,
          `${baseCoords.lat},${baseCoords.lng}`
        );

        setDistance(distanceData.distanceText);
        setDuration(distanceData.durationText);
      } catch (err) {
        console.error('Error in useClientDistance:', err);
        setError('Error fetching distance data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    calculateDistance();
  }, [client, getGeocodeFromCache, updateGeocodeCache]);

  return { distance, duration, loading, error };
};

export default useClientDistance;
