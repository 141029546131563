import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Search, ChevronRight } from 'lucide-react';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../firebase';
import CreateRoomModal from './CreateRoomModal';
import EditRoomModal from './EditRoomModal';
import UploadRoomsTasksCSV from './UploadRoomsTasksCSV';
import styles from '../styles/RoomsTasksManagement.module.css';

const RoomAccordion = ({ room, onEdit, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordionRow}>
      <div 
        className={styles.accordionHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.roomInfo}>
          <ChevronRight 
            size={16} 
            className={`${styles.chevronIcon} ${isOpen ? styles.open : ''}`}
          />
          <span className={styles.roomName}>{room.name}</span>
        </div>
        <div className={styles.roomActions}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEdit(room);
            }}
            className={styles.iconButton}
          >
            <Edit2 size={16} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(room.id);
            }}
            className={styles.iconButton}
          >
            <Trash2 size={16} />
          </button>
        </div>
      </div>
      <div className={`${styles.accordionContent} ${isOpen ? styles.open : ''}`}>
        <div className={styles.tasksList}>
          {room.tasks?.map((task, index) => (
            <div key={index} className={styles.taskItem}>
              {typeof task === 'object' ? task.description : task}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const RoomsTasksManagement = ({ orgId }) => {
  const [availableRooms, setAvailableRooms] = useState([]);
  const [openCreateRoomModal, setOpenCreateRoomModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingRoom, setEditingRoom] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  // Filter rooms based on search query
  const filteredRooms = availableRooms.filter(room =>
    room.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Fetch rooms
  const fetchAvailableRooms = async () => {
    try {
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);
    } catch (err) {
      console.error('Error fetching room types:', err);
      setError('Failed to fetch rooms');
    }
  };

  useEffect(() => {
    if (orgId) {
      fetchAvailableRooms();
    }
  }, [orgId]);

  // Room management handlers
  const handleCreateRoom = async (newRoom) => {
    try {
      if (!orgId) throw new Error("Organization ID not found");
      
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      await addDoc(roomTypesRef, newRoom);
      await fetchAvailableRooms();
      setSuccess("Room created successfully!");
      setOpenCreateRoomModal(false);
    } catch (error) {
      setError("Failed to create new room type");
    }
  };

  const handleEditRoomClick = (room) => {
    setEditingRoom(room);
    setIsEditModalOpen(true);
  };

  const handleEditRoom = async (updatedRoom) => {
    try {
      if (!orgId) throw new Error("Organization ID not found");
      
      const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', updatedRoom.id);
      await updateDoc(roomDocRef, {
        name: updatedRoom.name,
        tasks: updatedRoom.tasks,
        updatedAt: new Date()
      });
      await fetchAvailableRooms();
      setSuccess("Room updated successfully!");
      setIsEditModalOpen(false);
    } catch (error) {
      setError("Failed to edit room type");
    }
  };

  const handleDeleteRoom = async (roomId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this room?");
    if (!confirmDelete) return;

    try {
      if (!orgId) throw new Error("Organization ID not found");
      
      const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', roomId);
      await deleteDoc(roomDocRef);
      await fetchAvailableRooms();
      setSuccess("Room deleted successfully!");
    } catch (error) {
      setError("Failed to delete room type");
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.pageTitle}>Rooms and Tasks Management</h2>
      
      <div className={styles.actionsContainer}>
        <div className={styles.searchContainer}>
          <Search size={16} className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Search rooms..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className={styles.searchInput}
          />
        </div>

        <div className={styles.buttonGroup}>
          <UploadRoomsTasksCSV 
            orgId={orgId} 
            onRoomsCreated={fetchAvailableRooms}
            className={styles.uploadButton}
          />
          
          <button
            onClick={() => setOpenCreateRoomModal(true)}
            className={styles.createButton}
          >
            <Plus size={16} /> Create New Room Type
          </button>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            {filteredRooms.map((room) => (
              <RoomAccordion
                key={room.id}
                room={room}
                onEdit={handleEditRoomClick}
                onDelete={handleDeleteRoom}
              />
            ))}
          </tbody>
        </table>
      </div>

      {/* Modals */}
      <CreateRoomModal
        open={openCreateRoomModal}
        handleClose={() => setOpenCreateRoomModal(false)}
        handleCreateRoom={handleCreateRoom}
      />

      <EditRoomModal
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        roomData={editingRoom}
        handleEditRoom={handleEditRoom}
      />
    </div>
  );
};

export default RoomsTasksManagement; 