import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Alert,
  Snackbar,
  Paper,
  Grid
} from '@mui/material';
import { usePlaces } from '../hooks/usePlaces';
import styles from '../styles/CreateCustomerModal.module.css';
import { isValidPhoneNumber, formatPhoneNumber } from '../utils/validation';
import { fetchGeocodeData } from '../utils/api.js';

const CreateCustomerModal = ({
  open,
  handleClose,
  handleCreateCustomer,
  customFields = [],
  isEditing = false,
  initialData = null,
  existingCustomers = []
}) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    serviceAddress: '',
    billingAddress: '',
    notes: '',
    isPrivateNote: false
  });

  // Form State
  const [serviceAddressInput, setServiceAddressInput] = useState('');
  const [billingAddressInput, setBillingAddressInput] = useState('');
  const [useSameAddress, setUseSameAddress] = useState(true);

  // Places Autocomplete State
  const [serviceAutocomplete, setServiceAutocomplete] = useState(null);
  const [billingAutocomplete, setBillingAutocomplete] = useState(null);

  // Feedback State
  const [feedback, setFeedback] = useState({ open: false, message: '', severity: 'info' });

  // Places Hook
  const { loading: placesLoading, error: placesError, handlePlaceSelect: processPlace } = usePlaces();

  const [validationErrors, setValidationErrors] = useState({
    email: '',
    phone: '',
    duplicate: ''
  });

  useEffect(() => {
    if (initialData && isEditing) {
      const formattedServiceAddress = initialData.addresses?.[0]?.formattedAddress || initialData.serviceAddress || '';
      const formattedBillingAddress = initialData.billingAddress || '';
      
      setFormData({
        firstName: initialData.firstName || '',
        lastName: initialData.lastName || '',
        email: initialData.email || '',
        phone: initialData.phone || '',
        companyName: initialData.companyName || '',
        serviceAddress: formattedServiceAddress,
        billingAddress: formattedBillingAddress,
        notes: initialData.notes || '',
        isPrivateNote: initialData.isPrivateNote || false,
        addresses: initialData.addresses || []
      });

      setServiceAddressInput(formattedServiceAddress);
      setBillingAddressInput(formattedBillingAddress);
      setUseSameAddress(
        initialData.useSameAddress !== undefined 
          ? initialData.useSameAddress 
          : formattedBillingAddress === formattedServiceAddress
      );
    } else {
      resetForm();
    }
  }, [initialData, isEditing, open]);

  const resetForm = () => {
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      serviceAddress: '',
      billingAddress: '',
      notes: '',
      isPrivateNote: false
    });
    setServiceAddressInput('');
    setBillingAddressInput('');
    setUseSameAddress(true);
    setValidationErrors({});
  };

  const handleServiceAutocompleteLoad = useCallback((autocomplete) => {
    setServiceAutocomplete(autocomplete);
  }, []);

  const handleBillingAutocompleteLoad = useCallback((autocomplete) => {
    setBillingAutocomplete(autocomplete);
  }, []);

  const handleChange = (field, value) => {
    let formattedValue = value;
    
    // Format phone number as user types
    if (field === 'phone') {
      formattedValue = formatPhoneNumber(value);
    }

    setFormData(prev => ({
      ...prev,
      [field]: formattedValue
    }));

    // Clear validation errors when user starts typing
    if (['email', 'phone'].includes(field)) {
      setValidationErrors(prev => ({
        ...prev,
        [field]: ''
      }));
    }
  };

  const handlePlaceSelect = async (type) => {
    const autocomplete = type === 'service' ? serviceAutocomplete : billingAutocomplete;
    
    try {
      const place = autocomplete.getPlace();
      if (!place.formatted_address) {
        setFeedback({
          open: true,
          message: 'Please select an address from the dropdown',
          severity: 'warning'
        });
        return;
      }

      // Get geolocation data for service address
      let geoData = null;
      if (type === 'service') {
        try {
          geoData = await fetchGeocodeData(place.formatted_address);
        } catch (error) {
          console.error('Geocoding error:', error);
        }
      }

      if (type === 'service') {
        setFormData(prev => ({
          ...prev,
          serviceAddress: geoData?.formattedAddress || place.formatted_address,
          billingAddress: useSameAddress ? (geoData?.formattedAddress || place.formatted_address) : prev.billingAddress,
          addresses: geoData ? [{
            formattedAddress: geoData.formattedAddress,
            latitude: geoData.latitude,
            longitude: geoData.longitude,
            lastUpdated: new Date()
          }] : prev.addresses
        }));
        setServiceAddressInput(place.formatted_address);
        if (useSameAddress) {
          setBillingAddressInput(place.formatted_address);
        }
      } else {
        setFormData(prev => ({
          ...prev,
          billingAddress: place.formatted_address
        }));
        setBillingAddressInput(place.formatted_address);
      }
    } catch (error) {
      console.error('Error processing place selection:', error);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const errors = {};

    // Email validation
    if (formData.email && !validateEmail(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    // Phone validation
    if (formData.phone && !isValidPhoneNumber(formData.phone)) {
      errors.phone = 'Phone number must be in format: 000-000-0000';
    }

    // Duplicate customer check with safety checks
    const isDuplicate = existingCustomers.some(customer => {
      // Check for matching email if both exist
      const emailMatch = formData.email && customer.email && 
        formData.email.toLowerCase() === customer.email.toLowerCase();
      
      // Check for matching phone if both exist
      const phoneMatch = formData.phone && customer.phone && 
        formData.phone === customer.phone;
      
      // Check for matching name if all name fields exist
      const nameMatch = formData.firstName && formData.lastName && 
        customer.firstName && customer.lastName &&
        formData.firstName.toLowerCase() === customer.firstName.toLowerCase() && 
        formData.lastName.toLowerCase() === customer.lastName.toLowerCase();
      
      return emailMatch || phoneMatch || nameMatch;
    });

    if (isDuplicate && !isEditing) {
      errors.duplicate = 'A customer with this information already exists';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    const fullName = `${formData.firstName} ${formData.lastName}`.trim();
    
    const customerData = {
      name: fullName,
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      serviceAddress: formData.serviceAddress,
      billingAddress: formData.billingAddress,
      addresses: formData.addresses,
      notes: formData.notes,
      isPrivateNote: formData.isPrivateNote,
      accountStatus: 'active',
      startTime: '09:00',
      endTime: '11:00',
      ...(isEditing ? { 
        updatedAt: new Date(),
      } : { 
        createdAt: new Date(),
      }),
      useSameAddress
    };

    handleCreateCustomer(customerData);
    handleClose();
    resetForm();
  };

  const renderAutocompleteField = (type) => (
    <Box className={styles.googlePlacesAutocomplete}>
      <Autocomplete
        onLoad={type === 'service' ? handleServiceAutocompleteLoad : handleBillingAutocompleteLoad}
        onPlaceChanged={() => handlePlaceSelect(type)}
        options={{
          componentRestrictions: { country: 'us' },
          fields: ['address_components', 'formatted_address', 'geometry', 'name'],
          types: ['address']
        }}
      >
        <TextField
          fullWidth
          label={`${type === 'service' ? 'Service' : 'Billing'} Address`}
          value={type === 'service' ? serviceAddressInput : billingAddressInput}
          onChange={(e) => {
            if (type === 'service') {
              setServiceAddressInput(e.target.value);
            } else {
              setBillingAddressInput(e.target.value);
            }
          }}
          className={styles.inputField}
          placeholder="Start typing an address..."
        />
      </Autocomplete>
    </Box>
  );

  return (
    <>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        maxWidth="md" 
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: 'var(--light-background)',
            '[data-mui-color-scheme="dark"] &': {
              backgroundColor: 'var(--dark-background)'
            }
          }
        }}
      >
        <div className={styles.modalTitle}>
          {isEditing ? 'Edit Customer' : 'Create New Customer'}
        </div>

        <DialogContent className={styles.modalContent}>
          <Paper elevation={3} sx={{ 
            p: 3, 
            mt: 2,
            backgroundColor: 'var(--light-foreground)',
            '[data-mui-color-scheme="dark"] &': {
              backgroundColor: 'var(--dark-foreground)'
            }
          }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Basic Information
                </Typography>
              </Grid>

              {/* Validation Errors Alert */}
              {Object.values(validationErrors).some(error => error) && (
                <Grid item xs={12}>
                  <Alert 
                    severity="error" 
                    className={styles.validationAlert}
                    sx={{ mb: 2 }}
                  >
                    {Object.values(validationErrors).filter(error => error).join(', ')}
                  </Alert>
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  label="Company Name (Optional)"
                  fullWidth
                  value={formData.companyName}
                  onChange={(e) => handleChange('companyName', e.target.value)}
                  className={styles.inputField}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  required
                  fullWidth
                  value={formData.firstName}
                  onChange={(e) => handleChange('firstName', e.target.value)}
                  className={styles.inputField}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  required
                  fullWidth
                  value={formData.lastName}
                  onChange={(e) => handleChange('lastName', e.target.value)}
                  className={styles.inputField}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email Address"
                  type="email"
                  fullWidth
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  className={styles.inputField}
                  error={!!validationErrors.email}
                  helperText={validationErrors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  fullWidth
                  value={formData.phone}
                  onChange={(e) => handleChange('phone', e.target.value)}
                  className={styles.inputField}
                  error={!!validationErrors.phone}
                  helperText={validationErrors.phone}
                  placeholder="000-000-0000"
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Address Information
                </Typography>
              </Grid>

              <Grid item xs={12}>
                {renderAutocompleteField('service')}
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useSameAddress}
                      onChange={(e) => {
                        setUseSameAddress(e.target.checked);
                        if (e.target.checked) {
                          setFormData(prev => ({
                            ...prev,
                            billingAddress: prev.serviceAddress
                          }));
                          setBillingAddressInput(serviceAddressInput);
                        }
                      }}
                    />
                  }
                  label="Billing Address same as Service Address"
                />
              </Grid>

              {!useSameAddress && (
                <Grid item xs={12}>
                  {renderAutocompleteField('billing')}
                </Grid>
              )}

              {customFields.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      Custom Fields
                    </Typography>
                  </Grid>
                  {customFields.map((field) => (
                    <Grid item xs={12} key={field.id}>
                      <TextField
                        label={field.label}
                        fullWidth
                        value={formData.customFieldValues[field.id] || ''}
                        onChange={(e) => {
                          setFormData(prev => ({
                            ...prev,
                            customFieldValues: {
                              ...prev.customFieldValues,
                              [field.id]: e.target.value
                            }
                          }));
                        }}
                        className={styles.inputField}
                      />
                    </Grid>
                  ))}
                </>
              )}

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Notes
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  value={formData.notes}
                  onChange={(e) => handleChange('notes', e.target.value)}
                  placeholder="Enter customer notes here..."
                  className={styles.inputField}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.isPrivateNote}
                      onChange={(e) => handleChange('isPrivateNote', e.target.checked)}
                    />
                  }
                  label="Make this note private"
                />
              </Grid>
            </Grid>
          </Paper>
        </DialogContent>
        <DialogActions className={styles.modalActions}>
          <Button 
            onClick={handleClose} 
            variant="outlined"
            className={styles.cancelButton}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!formData.firstName || !formData.lastName || !formData.serviceAddress}
            className={styles.submitButton}
          >
            {isEditing ? 'Save Changes' : 'Create Customer'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={() => setFeedback(prev => ({ ...prev, open: false }))}
      >
        <Alert severity={feedback.severity} onClose={() => setFeedback(prev => ({ ...prev, open: false }))}>
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CreateCustomerModal;