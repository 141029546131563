import { collection, query, where, getDocs, doc, updateDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { db } from '../firebase';

// Customer status constants
export const CUSTOMER_STATUSES = {
  RECURRING: 'Recurring Customer',
  ONE_TIME: 'One-Time Customer',
  FORMER: 'Former Customer',
  LEAD: 'Lead'
};

export const useCustomerStatus = () => {
  const calculateCustomerStatus = async (orgId, customerId) => {
    try {
      if (!orgId || !customerId) {
        throw new Error('Organization ID and Customer ID are required');
      }

      // Get all jobs for this customer
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const q = query(jobsRef, where('customerId', '==', customerId));
      const querySnapshot = await getDocs(q);
      const customerJobs = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Find active recurring jobs
      const hasActiveRecurring = customerJobs.some(job => 
        job.recurring && 
        job.status !== 'Cancelled' && 
        job.status !== 'Completed'
      );

      // Find active one-time jobs
      const hasActiveOneTime = customerJobs.some(job => 
        !job.recurring && 
        job.status !== 'Cancelled' && 
        job.status !== 'Completed'
      );

      // Check job history
      const hasCompletedJobs = customerJobs.some(job => 
        job.status === 'Completed'
      );

      // Determine status
      let customerStatus;
      if (hasActiveRecurring) {
        customerStatus = CUSTOMER_STATUSES.RECURRING;
      } else if (hasActiveOneTime) {
        customerStatus = CUSTOMER_STATUSES.ONE_TIME;
      } else if (hasCompletedJobs) {
        customerStatus = CUSTOMER_STATUSES.FORMER;
      } else {
        customerStatus = CUSTOMER_STATUSES.LEAD;
      }

      // Update customer document with new status
      const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
      await updateDoc(customerRef, {
        customerStatus,
        updatedAt: serverTimestamp()
      });

      return customerStatus;
    } catch (error) {
      console.error('Error calculating customer status:', error);
      throw error;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case CUSTOMER_STATUSES.RECURRING:
        return 'success';
      case CUSTOMER_STATUSES.ONE_TIME:
        return 'primary';
      case CUSTOMER_STATUSES.FORMER:
        return 'warning';
      default:
        return 'default';
    }
  };

  const getStatusDisplayText = (status) => {
    return status || CUSTOMER_STATUSES.LEAD; // Default to 'Lead' if no status
  };

  const isValidStatus = (status) => {
    return Object.values(CUSTOMER_STATUSES).includes(status);
  };

  const updateAllCustomerStatuses = async (orgId) => {
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      const customersSnapshot = await getDocs(customersRef);
      
      const batch = writeBatch(db);
      
      for (const customerDoc of customersSnapshot.docs) {
        const customerId = customerDoc.id;
        const status = await calculateCustomerStatus(orgId, customerId);
        
        const customerRef = doc(db, 'organizations', orgId, 'customers', customerId);
        batch.update(customerRef, {
          customerStatus: status,
          updatedAt: serverTimestamp()
        });
      }
      
      await batch.commit();
    } catch (error) {
      console.error('Error updating all customer statuses:', error);
      throw error;
    }
  };

  return {
    calculateCustomerStatus,
    CUSTOMER_STATUSES,
    getStatusColor,
    getStatusDisplayText,
    isValidStatus,
    updateAllCustomerStatuses
  };
}; 