import React, { createContext, useState, useMemo, useEffect } from 'react';
import moment from 'moment';

const JobOverviewContext = createContext();

export function JobOverviewProvider({
  children,
  jobs = [],
  timeOffRequests = [],
  cleaners = [],
  findAvailabilityGaps = (day, dayJobs) => {}
}) {
  const week = Array.from({ length: 6 }, (_, i) => i);
  const today = new Date().getDate();

  const [relativeWeek, setRelativeWeek] = useState(0);
  const [weekJobs, setWeekJobs] = useState([]);
  const [weekAvailabilityGaps, setWeekAvailabilityGaps] = useState([]);
  const [statusList, setStatusList] = useState([
    { label: "Pending", active: true }, 
    { label: "In Progress", active: true }, 
    { label: "Completed", active: true }, 
    { label: "Cancelled", active: true },
    { label: "Estimate", active: true },
    { label: "Open slots", active: true },
  ]);
  const [showingCleaners, setShowingCleaners] = useState([])

  const currentDates = useMemo(() => {
    const today = new Date();
    const dayOfWeek = today.getUTCDay() || 7;
    const dayLimit = 6;
    const monday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

    monday.setUTCDate(today.getUTCDate() - (dayOfWeek - 2) + relativeWeek * 7);
      
    if (dayOfWeek === 7) {
      monday.setUTCDate(monday.getUTCDate() - 7);
    }
  
    return Array.from({ length: dayLimit }, (_, i) => {
      return new Date(Date.UTC(monday.getUTCFullYear(), monday.getUTCMonth(), monday.getUTCDate() + i));
    });
  }, [relativeWeek]);

  const hours = useMemo(() => {
    const timeIntervals = [];
    let hour = 0;
    let minute = 0;
    let period = "AM";

    while (!(hour === 11 && minute === 30 && period === "PM")) {
        const formattedHour = hour === 0 ? 12 : hour;
        const formattedMinute = minute === 0 ? "00" : minute;
        
        timeIntervals.push(`${formattedHour}:${formattedMinute} ${period}`);

        minute += 30;
        if (minute === 60) {
            minute = 0;
            hour++;
        }
        if (hour === 12 && minute === 0) {
            period = period === "AM" ? "PM" : "AM";
            hour = 1;
        }
    }

    timeIntervals.push("11:30 PM");

    return timeIntervals;
}, []);

  useEffect(() => {
    const getWeekSchedule = () => {
      const weekStart = moment(currentDates[0]);
      const weekEnd = moment(currentDates[currentDates.length - 1]);

      setWeekJobs(
        jobs.filter(job => {
          if (!job.appointmentDate || !moment.isMoment(job.appointmentDate)) {
            console.warn("Invalid or missing appointmentDate (not a Moment object):", job);
            return false;
          }
  
          const appointmentDate = job.appointmentDate;
          return appointmentDate.isSameOrAfter(weekStart) && appointmentDate.isSameOrBefore(weekEnd);
        })
      );
    };
  
    getWeekSchedule();
  }, [jobs, currentDates]);

  useEffect(() => {
    if (cleaners.length > 0) {
      const availabilityGaps = currentDates.map(date => {
        const dayJobs = weekJobs.filter(job => {
          if (!job.appointmentDate || !moment.isMoment(job.appointmentDate)) {
            console.warn("Invalid or missing appointmentDate (not a Moment object):", job);
            return false;
          }
  
          const todayMoment = moment(date);
          return job.appointmentDate.isSame(todayMoment, 'day');
        })

        return findAvailabilityGaps(moment(date), dayJobs)
      })

      setWeekAvailabilityGaps(availabilityGaps)
    }
  }, [
    cleaners,
    currentDates,
    findAvailabilityGaps,
    today,
    weekJobs
  ]);

  useEffect(() => {
    setShowingCleaners(cleaners.map(cleaner => cleaner.id))
  }, [cleaners])

  const updateStatus = (status) => {
    const updatedStatusList = statusList.map(statusItem => {
      if (statusItem.label === status) {
        return {
          ...statusItem,
          active: !statusItem.active
        };
      }

      return statusItem;
    });

    setStatusList(updatedStatusList);
  }

  return (
    <JobOverviewContext.Provider
      value={{
        week,
        hours,
        today,
        cleaners,
        showingCleaners,
        setShowingCleaners,
        currentDates,
        relativeWeek,
        setRelativeWeek,
        weekJobs,
        weekAvailabilityGaps,
        statusList,
        updateStatus
      }}
    >
      {children}
    </JobOverviewContext.Provider>
  );
}

export default JobOverviewContext;