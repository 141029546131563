import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { photoApi } from '../utils/api';
import { useAuth } from '../context/AuthContext';
import { ChevronLeft, ChevronRight, ZoomIn, ZoomOut, X, Download, ChevronUp, ChevronDown } from 'lucide-react';
import styles from '../styles/EnhancedPhotoView.module.css';
import JSZip from 'jszip';

const PHOTOS_PER_PAGE = 9; // Show 9 photos at a time (3x3 grid)

const EnhancedPhotoViewer = ({ job }) => {
  const { token } = useAuth();
  const [photos, setPhotos] = useState([]);
  const [loadingPhotos, setLoadingPhotos] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showAll, setShowAll] = useState(false);

  // Get displayed photos based on showAll state
  const displayedPhotos = showAll ? photos : photos.slice(0, PHOTOS_PER_PAGE);
  const hasMorePhotos = photos.length > PHOTOS_PER_PAGE;

  // Function to fetch photos from storage
  const fetchCompletionPhotos = async () => {
    // Get photos from root level only
    const rootPhotos = job?.completionPhotos || [];
    
    console.log('Job data:', {
      organizationId: job?.organizationId,
      jobId: job?.id,
      completionPhotos: rootPhotos
    });
    
    if (!job?.organizationId || !job?.id || !rootPhotos.length) {
      console.log('Missing required data, skipping photo fetch');
      setPhotos([]);
      return;
    }
    
    setLoadingPhotos(true);
    try {
      const photoPromises = rootPhotos.map(async fileName => {
        try {
          console.log('Fetching photo:', fileName);
          const response = await photoApi.images(token, {
            organizationId: job.organizationId,
            jobId: job.id,
            fileName
          });
          
          console.log('Photo response:', response.data);
          return {
            url: response.data.url,
            name: fileName
          };
        } catch (error) {
          console.error(`Error fetching photo ${fileName}:`, error);
          return null;
        }
      });
      const fetchedPhotos = (await Promise.all(photoPromises)).filter(photo => photo !== null);
      console.log('Final photos array:', fetchedPhotos);
      setPhotos(fetchedPhotos);
    } catch (error) {
      console.error('Error fetching photos:', error);
      setPhotos([]);
    } finally {
      setLoadingPhotos(false);
    }
  };

  useEffect(() => {
    if (job?.status === 'Completed') {
      fetchCompletionPhotos();
    }
  }, [job?.status, job?.completionPhotos, job?.organizationId, job?.id]);

  const handlePrevious = () => {
    setCurrentPhotoIndex((prev) => (prev > 0 ? prev - 1 : photos.length - 1));
  };

  const handleNext = () => {
    setCurrentPhotoIndex((prev) => (prev < photos.length - 1 ? prev + 1 : 0));
  };

  const handleZoomIn = () => {
    setZoomLevel((prev) => Math.min(prev + 0.25, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel((prev) => Math.max(prev - 0.25, 0.5));
  };

  const handleDownload = async () => {
    if (!photos.length) return;
    
    try {
      const response = await photoApi.downloadImages(token, {
        organizationId: job.organizationId,
        jobId: job.id,
        photoNames: photos.map(photo => photo.name)
      });

      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'completion_photos.zip');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading photos:', error);
      // You might want to show an error notification here
    }
  };

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isModalOpen) return;
      
      switch(e.key) {
        case 'ArrowLeft':
          handlePrevious();
          break;
        case 'ArrowRight':
          handleNext();
          break;
        case 'Escape':
          setIsModalOpen(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isModalOpen]);

  return (
    <div className={styles.completionPhotos}>
      <h4 className={styles.header}>Completion Photos</h4>
      
      {loadingPhotos ? (
        <div className={styles.loadingText}>Loading photos...</div>
      ) : photos.length > 0 ? (
        <>
          <p className={styles.helperText}>Click the photos to view them in full screen.</p>
          
          {/* Thumbnail Grid */}
          <div className={styles.photoGrid}>
            {displayedPhotos.map((photo, index) => (
              <div
                key={index}
                className={styles.photoThumbnail}
                onClick={() => {
                  setCurrentPhotoIndex(index);
                  setIsModalOpen(true);
                  setZoomLevel(1);
                }}
              >
                <img
                  src={photo.url}
                  alt={`Completion photo ${index + 1}`}
                />
              </div>
            ))}
          </div>

          {/* Show More/Less Button */}
          {hasMorePhotos && (
            <button
              onClick={() => setShowAll(!showAll)}
              className={styles.showMoreButton}
            >
              {showAll ? (
                <>
                  <ChevronUp className="w-4 h-4" />
                  Show Less
                </>
              ) : (
                <>
                  <ChevronDown className="w-4 h-4" />
                  Show {photos.length - PHOTOS_PER_PAGE} More Photos
                </>
              )}
            </button>
          )}

          {/* Download Button */}
          <div className={styles.footerActions}>
            <button
              onClick={handleDownload}
              className={styles.downloadButton}
            >
              <Download className="w-4 h-4" />
              Download All ({photos.length})
            </button>
          </div>

          {/* Modal Dialog */}
          {isModalOpen && (
            <div className={styles.modalOverlay}>
              <div className={styles.modalDialog}>
                {/* Header */}
                <div className={styles.modalHeader}>
                  <h3 className={styles.modalTitle}>
                    Photo {currentPhotoIndex + 1} of {photos.length}
                  </h3>
                  <button
                    onClick={() => {
                      setIsModalOpen(false);
                      setZoomLevel(1);
                    }}
                    className={styles.closeButton}
                  >
                    <X className="w-5 h-5" />
                  </button>
                </div>

                {/* Image Container */}
                <div className={styles.imageContainer}>
                  <button
                    onClick={handlePrevious}
                    className={`${styles.navigationButton} ${styles.prevButton}`}
                  >
                    <ChevronLeft className="w-6 h-6" />
                  </button>

                  <button
                    onClick={handleNext}
                    className={`${styles.navigationButton} ${styles.nextButton}`}
                  >
                    <ChevronRight className="w-6 h-6" />
                  </button>

                  <img
                    src={photos[currentPhotoIndex].url}
                    alt={`Completion photo ${currentPhotoIndex + 1}`}
                    style={{
                      transform: `scale(${zoomLevel})`,
                      transition: 'transform 0.2s ease-in-out'
                    }}
                    className={styles.mainImage}
                  />
                </div>

                {/* Footer */}
                <div className={styles.modalFooter}>
                  <button
                    onClick={handleZoomOut}
                    className={styles.zoomButton}
                  >
                    <ZoomOut className="w-5 h-5" />
                  </button>
                  <button
                    onClick={handleZoomIn}
                    className={styles.zoomButton}
                  >
                    <ZoomIn className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <p className={styles.noPhotosText}>No completion photos available</p>
      )}
    </div>
  );
};

export default EnhancedPhotoViewer;