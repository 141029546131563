import styles from "../../../styles/JobOverview/CalendarCards/TimeOffCard.module.css";

import moment from "moment";

const TimeOffCard = ({ timeOff, onAvailabilityClick }) => {
  const formatTime = (date) => date
    ? date.format('hh:mm A')
    : undefined;

  const startDate = formatTime(moment(timeOff.startTime.seconds * 1000))
  const endDate = formatTime(moment(timeOff.endTime.seconds * 1000))

  return (
    <div
      className={styles.card}
      onClick={() => onAvailabilityClick({
        ...timeOff,
        start: moment(timeOff.startTime.seconds * 1000),
        end: moment(timeOff.endTime.seconds * 1000),
        date: moment(timeOff.startTime.seconds * 1000),
        status: `time_off_${timeOff.status}`,	
      })}
    >
      <p>
        {startDate} - {endDate}
      </p>
      <p>
        {timeOff.cleaner.firstName} Time Off
      </p>
    </div>
  )
}

export default TimeOffCard;