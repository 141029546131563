// src/components/Invoices/InvoiceManager/InvoiceManager.jsx
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import InvoiceNavigation from './InvoiceNavigation';
import InvoiceDashboard from './InvoiceDashboard';
import InvoiceList from './InvoiceList';
import InvoiceCreate from './InvoiceCreate';
import InvoiceSettings from '../Settings/InvoiceSettings';
import ProductSettings from '../Settings/ProductSettings';
import TaxSettings from '../Settings/TaxSettings';
import EmailSettings from '../Settings/EmailSettings/EmailSettings';
import TemplateSettings from '../Settings/TemplateSettings';

const InvoiceManager = () => {
  return (
    <Box sx={{ p: 3 }}>
      <InvoiceNavigation />
      <Box sx={{ mt: 2 }}>
        <Routes>
          <Route path="/" element={<InvoiceDashboard />} />
          <Route path="/list/*" element={<InvoiceList />} />
          <Route path="/create" element={<InvoiceCreate />} />
          <Route path="/settings/*" element={
            <Routes>
              <Route path="general" element={<InvoiceSettings />} />
              <Route path="products" element={<ProductSettings />} />
              <Route path="tax" element={<TaxSettings />} />
              <Route path="email" element={<EmailSettings />} />
              <Route path="templates" element={<TemplateSettings />} />
              <Route path="*" element={<Navigate to="general" replace />} />
            </Routes>
          } />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default InvoiceManager;