import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Divider
} from '@mui/material';
import styles from '../../../styles/CreateCustomerModal.module.css';

const ProductModal = ({
  open,
  handleClose,
  handleSubmit,
  formData,
  setFormData,
  isEditing
}) => {
  const handleChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          backgroundColor: 'var(--light-background)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-background)'
          }
        }
      }}
    >
      <div className={styles.modalTitle}>
        {isEditing ? 'Edit Product' : 'Create New Product'}
      </div>

      <DialogContent className={styles.modalContent}>
        <Paper elevation={3} sx={{ 
          p: 3, 
          mt: 2,
          backgroundColor: 'var(--light-foreground)',
          '[data-mui-color-scheme="dark"] &': {
            backgroundColor: 'var(--dark-foreground)'
          }
        }}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Basic Information
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  autoFocus
                  label="Product Name"
                  type="text"
                  fullWidth
                  required
                  value={formData.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                  className={styles.inputField}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={2}
                  value={formData.description}
                  onChange={(e) => handleChange('description', e.target.value)}
                  className={styles.inputField}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Pricing Information
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Pricing Type"
                  fullWidth
                  value={formData.pricingType}
                  onChange={(e) => handleChange('pricingType', e.target.value)}
                  className={styles.inputField}
                >
                  <MenuItem value="fixed">Fixed Price</MenuItem>
                  <MenuItem value="hourly">Per Hour</MenuItem>
                  <MenuItem value="square_foot">Per Square Foot</MenuItem>
                  <MenuItem value="room">Per Room</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Price Source"
                  fullWidth
                  value={formData.priceSource}
                  onChange={(e) => handleChange('priceSource', e.target.value)}
                  className={styles.inputField}
                >
                  <MenuItem value="fixed">Fixed Price</MenuItem>
                  <MenuItem value="service_cost">Job Service Cost</MenuItem>
                </TextField>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  select
                  label="Pricing Per"
                  fullWidth
                  value={formData.pricingPer}
                  onChange={(e) => handleChange('pricingPer', e.target.value)}
                  className={styles.inputField}
                >
                  <MenuItem value="job">Per Job</MenuItem>
                  <MenuItem value="cleaner">Per Cleaner</MenuItem>
                  <MenuItem value="room">Per Room</MenuItem>
                </TextField>
              </Grid>

              {formData.priceSource === 'fixed' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Price"
                    type="number"
                    fullWidth
                    required
                    value={formData.price}
                    onChange={(e) => handleChange('price', e.target.value)}
                    className={styles.inputField}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  label="Category"
                  type="text"
                  fullWidth
                  value={formData.category}
                  onChange={(e) => handleChange('category', e.target.value)}
                  className={styles.inputField}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography variant="h6" gutterBottom>
                  Product Settings
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.active}
                      onChange={(e) => handleChange('active', e.target.checked)}
                    />
                  }
                  label="Active"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formData.taxable}
                      onChange={(e) => handleChange('taxable', e.target.checked)}
                    />
                  }
                  label="Taxable"
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </DialogContent>

      <DialogActions className={styles.modalActions}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          className={styles.submitButton}
          disabled={!formData.name}
        >
          {isEditing ? 'Update' : 'Add'} Product
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductModal; 