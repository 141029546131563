import { Card, TextField, Button, Typography, Box } from '@mui/material';
import styled from '@emotion/styled';
import { alpha } from '@mui/material/styles';
import { styled as muiStyled } from '@mui/material/styles';
import { Paper } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
  boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-md-dark)' : 'var(--shadow-md-light)',
  borderRadius: 'var(--border-radius-base)',
  transition: 'var(--transition-duration) var(--transition-ease)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',

  '& .MuiCardHeader-root': {
    borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground-accent)' : 'var(--light-foreground-accent)',
  },

  '& .MuiCardHeader-title': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
    fontSize: '1.25rem',
    fontWeight: 600,
  },

  '& .MuiCardHeader-subheader': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text-light)' : 'var(--light-text-light)',
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-text-area)' : 'var(--light-foreground)',
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)',
    },
    '&:hover fieldset': {
      borderColor: 'var(--light-primary)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--light-primary)',
      boxShadow: `0 0 0 3px ${theme.palette.mode === 'dark' ? 'var(--dark-focus-ring)' : 'var(--light-focus-ring)'}`,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text-light)' : 'var(--light-text-light)',
  },
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  },
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'var(--light-primary)',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--form-button-hover-dark)' : 'var(--form-button-hover-light)',
  },
  '&:active': {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--form-button-active-dark)' : 'var(--form-button-active-light)',
  },
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  fontSize: '1.1rem',
}));

export const PreviewContainer = styled(Paper)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
}));

export const VariableChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  padding: '4px 8px',
  margin: '4px',
  borderRadius: '16px',
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-text-area)'
    : 'var(--light-foreground-accent)',
  color: theme.palette.mode === 'dark'
    ? 'var(--dark-text)'
    : 'var(--light-text)',
  fontSize: '0.875rem',
  cursor: 'pointer',
  transition: 'var(--transition-duration) var(--transition-ease)',
  border: `1px solid ${theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)'}`,
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark'
      ? 'var(--dark-text-hover)'
      : 'var(--light-foreground)',
    transform: 'translateY(-1px)',
    boxShadow: theme.palette.mode === 'dark' ? 'var(--shadow-sm-dark)' : 'var(--shadow-sm-light)',
  },
}));

export const VariablesContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground-accent)'
    : 'var(--light-foreground-accent)',
  borderRadius: 'var(--border-radius-base)',
  padding: theme.spacing(2),
  marginTop: theme.spacing(3),
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'var(--dark-border)'
    : 'var(--light-border)'}`,
}));

export const CategoryContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.mode === 'dark' 
    ? 'var(--dark-foreground)'
    : 'var(--light-foreground)',
  borderRadius: 'var(--border-radius-base)',
  border: `1px solid ${theme.palette.mode === 'dark' 
    ? 'var(--dark-border)'
    : 'var(--light-border)'}`,
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const StyledTextArea = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-text-area)' : 'var(--light-foreground)',
    minHeight: '400px',
    '& textarea': {
      minHeight: '380px',
      resize: 'vertical',
      cursor: 'ns-resize',
    },
    '& fieldset': {
      borderColor: theme.palette.mode === 'dark' ? 'var(--dark-border)' : 'var(--light-border)',
    },
    '&:hover fieldset': {
      borderColor: 'var(--light-primary)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'var(--light-primary)',
      boxShadow: `0 0 0 3px ${theme.palette.mode === 'dark' ? 'var(--dark-focus-ring)' : 'var(--light-focus-ring)'}`,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text-light)' : 'var(--light-text-light)',
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--dark-foreground)' : 'var(--light-foreground)',
    padding: '0 4px',
  },
  '& .MuiInputBase-input': {
    color: theme.palette.mode === 'dark' ? 'var(--dark-text)' : 'var(--light-text)',
    lineHeight: '1.6',
    fontFamily: 'var(--font-family-base)',
  },
}));

export const EmailHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[100],
}));

export const EmailMetadata = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: theme.spacing(1),
  '& .MuiTypography-root': {
    lineHeight: 1.5,
  },
}));

export const EmailBody = styled('div')(({ theme, mode }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflow: 'auto',
  backgroundColor: theme.palette.background.paper,
  ...(mode === 'plain' && {
    fontFamily: 'monospace',
    whiteSpace: 'pre-wrap',
    fontSize: '0.875rem',
  }),
}));