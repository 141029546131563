// /src/components/EditRoomModal.js

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  List,
  ListItem,
} from '@mui/material';
import { X as CloseIcon, Plus as AddIcon, Trash2 } from 'lucide-react';
import styles from '../styles/EditRoomModal.module.css';

const EditRoomModal = ({ open, handleClose, roomData, handleEditRoom }) => {
  const [roomName, setRoomName] = useState('');
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (roomData) {
      setRoomName(roomData.name || '');
      setTasks(roomData.tasks?.map(task => task.description) || []);
    }
  }, [roomData]);

  const handleTaskChange = (index, value) => {
    const updatedTasks = [...tasks];
    updatedTasks[index] = value;
    setTasks(updatedTasks);
  };

  const addTaskField = () => {
    setTasks([...tasks, '']);
  };

  const removeTaskField = (index) => {
    const updatedTasks = tasks.filter((_, idx) => idx !== index);
    setTasks(updatedTasks);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (roomName.trim() === '') {
      alert('Room name is required.');
      return;
    }
    
    const cleanedTasks = tasks
      .filter(task => task.trim() !== '')
      .map(task => ({ description: task, completed: false }));
    
    handleEditRoom({
      id: roomData.id,
      name: roomName,
      tasks: cleanedTasks,
    });
    handleClose();
  };

  return (
    <Dialog 
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <div className={styles.modalTitle}>
        Edit Room Type
        <IconButton 
          onClick={handleClose}
          className={styles.closeButton}
          size="small"
        >
          <CloseIcon size={18} />
        </IconButton>
      </div>

      <DialogContent className={styles.modalContent}>
        <form onSubmit={onSubmit} className={styles.form}>
          <div className={styles.formSection}>
            <TextField
              label="Room Name"
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              fullWidth
              required
              className={styles.inputField}
            />
          </div>

          <div className={styles.formSection}>
            <div className={styles.sectionTitle}>Tasks</div>
            <List className={styles.taskList}>
              {tasks.map((task, index) => (
                <ListItem key={index} className={styles.taskItem} disableGutters>
                  <TextField
                    label={`Task ${index + 1}`}
                    value={task}
                    onChange={(e) => handleTaskChange(index, e.target.value)}
                    fullWidth
                    required
                    className={styles.inputField}
                  />
                  <IconButton
                    onClick={() => removeTaskField(index)}
                    disabled={tasks.length === 1}
                    className={styles.iconButton}
                  >
                    <Trash2 size={18} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
            <Button
              onClick={addTaskField}
              startIcon={<AddIcon />}
              className={styles.addTaskButton}
            >
              Add Task
            </Button>
          </div>
        </form>
      </DialogContent>

      <DialogActions className={styles.modalActions}>
        <Button 
          onClick={handleClose}
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          className={styles.submitButton}
        >
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditRoomModal;
