// src/components/Invoices/Settings/InvoiceSettings.jsx
import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Divider,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box
} from '@mui/material';
import { useAuth } from '../../../context/AuthContext';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebase';
import SettingsNavigation from './SettingsNavigation';

const DEFAULT_SETTINGS = {
  defaultTemplate: 'standard',
  autoGenerateInvoices: true,
  autoGenerateOnJobComplete: true,
  sendEmailOnGeneration: false,
  paymentGateway: {
    provider: 'authorize.net',
    sandbox: true,
    apiLoginId: '',
    transactionKey: '',
    publicClientKey: ''
  },
  emailSettings: {
    invoiceSubject: 'New Invoice from {{businessName}}',
    reminderSubject: 'Invoice Reminder from {{businessName}}',
    footerText: 'Thank you for your business!',
    sendReminders: true,
    reminderDays: 3
  },
  numberingFormat: {
    prefix: 'INV',
    nextNumber: 1000,
    suffix: '',
    yearInNumber: true
  }
};

const InvoiceSettings = () => {
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);
  const [isSaving, setIsSaving] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const loadSettings = async () => {
      if (!user?.orgId) return;

      try {
        const settingsRef = doc(db, 'organizations', user.orgId, 'invoiceSettings', 'default');
        const settingsSnap = await getDoc(settingsRef);

        if (settingsSnap.exists()) {
          setSettings(settingsSnap.data());
        } else {
          // If no settings exist, create default ones
          await setDoc(settingsRef, {
            ...DEFAULT_SETTINGS,
            createdAt: serverTimestamp(),
            createdBy: user.uid,
            updatedAt: serverTimestamp(),
            updatedBy: user.uid
          });
        }
      } catch (error) {
        console.error('Error loading invoice settings:', error);
      }
    };

    loadSettings();
  }, [user?.orgId]);

  const handleSave = async () => {
    if (!user?.orgId) return;

    setIsSaving(true);
    try {
      const settingsRef = doc(db, 'organizations', user.orgId, 'invoiceSettings', 'default');
      await setDoc(settingsRef, {
        ...settings,
        updatedAt: serverTimestamp(),
        updatedBy: user.uid
      }, { merge: true });
    } catch (error) {
      console.error('Error saving invoice settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleChange = (section, field, value) => {
    if (section) {
      setSettings(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [field]: value
        }
      }));
    } else {
      setSettings(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  return (
    <>
      <SettingsNavigation />
      <Card>
        <CardHeader title="Invoice Settings" />
        <CardContent>
          <Grid container spacing={3}>
            {/* General Settings */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>General Settings</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Default Template</InputLabel>
                    <Select
                      value={settings.defaultTemplate}
                      onChange={(e) => handleChange(null, 'defaultTemplate', e.target.value)}
                      label="Default Template"
                    >
                      <MenuItem value="standard">Standard Invoice</MenuItem>
                      <MenuItem value="professional">Professional</MenuItem>
                      <MenuItem value="minimal">Minimal</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.autoGenerateInvoices}
                        onChange={(e) => handleChange(null, 'autoGenerateInvoices', e.target.checked)}
                      />
                    }
                    label="Auto-generate invoices"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.autoGenerateOnJobComplete}
                        onChange={(e) => handleChange(null, 'autoGenerateOnJobComplete', e.target.checked)}
                      />
                    }
                    label="Generate invoice when job is completed"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.sendEmailOnGeneration}
                        onChange={(e) => handleChange(null, 'sendEmailOnGeneration', e.target.checked)}
                      />
                    }
                    label="Send email when invoice is generated"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Payment Gateway Settings */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Payment Gateway Settings</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.paymentGateway.sandbox}
                        onChange={(e) => handleChange('paymentGateway', 'sandbox', e.target.checked)}
                      />
                    }
                    label="Use Sandbox (Testing) Environment"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="API Login ID"
                    value={settings.paymentGateway.apiLoginId}
                    onChange={(e) => handleChange('paymentGateway', 'apiLoginId', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Transaction Key"
                    type="password"
                    value={settings.paymentGateway.transactionKey}
                    onChange={(e) => handleChange('paymentGateway', 'transactionKey', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Public Client Key"
                    value={settings.paymentGateway.publicClientKey}
                    onChange={(e) => handleChange('paymentGateway', 'publicClientKey', e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Email Settings */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Email Settings</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Invoice Email Subject"
                    value={settings.emailSettings.invoiceSubject}
                    onChange={(e) => handleChange('emailSettings', 'invoiceSubject', e.target.value)}
                    helperText="Use {{businessName}} as a placeholder"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Reminder Email Subject"
                    value={settings.emailSettings.reminderSubject}
                    onChange={(e) => handleChange('emailSettings', 'reminderSubject', e.target.value)}
                    helperText="Use {{businessName}} as a placeholder"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email Footer Text"
                    multiline
                    rows={2}
                    value={settings.emailSettings.footerText}
                    onChange={(e) => handleChange('emailSettings', 'footerText', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.emailSettings.sendReminders}
                        onChange={(e) => handleChange('emailSettings', 'sendReminders', e.target.checked)}
                      />
                    }
                    label="Send Payment Reminders"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Reminder Days"
                    value={settings.emailSettings.reminderDays}
                    onChange={(e) => handleChange('emailSettings', 'reminderDays', parseInt(e.target.value))}
                    disabled={!settings.emailSettings.sendReminders}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Invoice Numbering Format */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>Invoice Numbering</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Prefix"
                    value={settings.numberingFormat.prefix}
                    onChange={(e) => handleChange('numberingFormat', 'prefix', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Next Number"
                    value={settings.numberingFormat.nextNumber}
                    onChange={(e) => handleChange('numberingFormat', 'nextNumber', parseInt(e.target.value))}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Suffix"
                    value={settings.numberingFormat.suffix}
                    onChange={(e) => handleChange('numberingFormat', 'suffix', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={settings.numberingFormat.yearInNumber}
                        onChange={(e) => handleChange('numberingFormat', 'yearInNumber', e.target.checked)}
                      />
                    }
                    label="Include Year in Invoice Number"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={isSaving}
            >
              {isSaving ? 'Saving...' : 'Save Settings'}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default InvoiceSettings;