// /src/components/CleanerJobView.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, updateDoc, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../firebase';
import { 
  Card, CardContent, Typography, Checkbox, Button, TextField, 
  FormControlLabel, Box, CircularProgress, Dialog, DialogTitle, 
  DialogContent, DialogActions, Accordion, AccordionSummary, 
  AccordionDetails, Rating, Stack, IconButton, Grid, Divider, Chip 
} from '@mui/material';
import { PhotoCamera, AccessTime, Person, LocationOn } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useJobContext, JOB_STATUSES, normalizeJobStatus, DEFAULT_JOB_STATUS } from '../context/JobContext';
import styles from'../styles/CleanerJobView.module.css';
import MobileHeader from './MobileHeader';
import AddressLink from './AddressLink';
import StatusChip from './StatusChip';

const CleanerJobView = () => {
  // Extract both orgId and jobId from URL parameters
  const { orgId, jobId } = useParams();
  const [user, setUser] = useState(null); // Define user state
  const [job, setJob] = useState(null);
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isStarted, setIsStarted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const { startJob, completeJob } = useJobContext();
  const [currentCleanerStatus, setCurrentCleanerStatus] = useState(null);

  // Ratings State & Job Completion Notes
  const [difficultyRating, setDifficultyRating] = useState(0);
  const [cleanlinessRating, setCleanlinessRating] = useState(0);
  const [completionNotes, setCompletionNotes] = useState('');

  // Upload Photos State
  const [photos, setPhotos] = useState([]);
  const [uploadingPhotos, setUploadingPhotos] = useState(false);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // Optionally, verify user's membership in the organization here
        fetchJob(currentUser.uid);
      } else {
        setLoading(false);
        setError("User not authenticated");
      }
    });

    return () => unsubscribe();
  }, [orgId, jobId]);

    // Updated to check for IN_PROGRESS status
    useEffect(() => {
      if (job) {
        const cleanerEntry = job.cleanersActualTimes?.find(
          c => c.cleanerId === user?.uid
        );
  
        if (cleanerEntry) {
          setCurrentCleanerStatus(cleanerEntry.status);
          setIsStarted(cleanerEntry.status === 'In Progress');
          setIsCompleted(cleanerEntry.status === 'Completed');
        } else {
          setCurrentCleanerStatus(null);
          setIsStarted(false);
          setIsCompleted(false);
        }
      }
    }, [job, user]);

  // Fetch job data
  const fetchJob = async (userId) => {
    if (orgId && jobId) {
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);

        const jobSnap = await getDoc(jobRef);


        if (jobSnap.exists()) {
          const jobData = { 
            id: jobSnap.id, 
            ...jobSnap.data(),
            status: normalizeJobStatus(jobSnap.data().status) // Normalize status here
          };



          // Ensure rooms is an array; default to empty array if undefined
          jobData.rooms = Array.isArray(jobData.rooms) ? jobData.rooms : [];
          
          setJob(jobData);
          setIsStarted(!!jobData.startTime);
          setIsCompleted(jobData.status === 'completed');
        } else {
          console.error('No such job exists.');
          setError('Job not found.');
        }
      } catch (err) {
        console.error('Error fetching job:', err);
        setError('Error fetching job: ' + err.message);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('orgId or jobId is missing from URL parameters.');
      setError('Invalid URL parameters.');
      setLoading(false);
    }
  };

  // Handle room completion toggle
  const handleCheckRoom = async (roomId) => {
    if (!job || !orgId) {
      console.error('Job data or orgId is missing.');
      return;
    }

    const updatedRooms = job.rooms.map(room => 
      room.id === roomId ? { ...room, completed: !room.completed } : room
    );

    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await updateDoc(jobRef, { rooms: updatedRooms });
      setJob({ ...job, rooms: updatedRooms });
    } catch (err) {
      console.error('Error updating room:', err);
      setError('Error updating room: ' + err.message);
    }
  };

  // Handle starting the job
  const handleStartJob = async () => {
    if (!job || !orgId || !jobId || !user?.uid) {
      console.error("Missing required data:", { job, orgId, jobId, userId: user?.uid });
      setError("Missing required data to start job");
      return;
    }

    try {
      // Call the context method with the cleanerId
      await startJob(orgId, jobId, user.uid);
      
      // Update local state
      setJob(prev => ({
        ...prev,
        status: JOB_STATUSES.IN_PROGRESS,
        startTime: new Date(),
        cleanersActualTimes: [
          ...(prev.cleanersActualTimes || []),
          {
            cleanerId: user.uid,
            cleanerName: user.displayName || user.email,
            status: 'In Progress',
            actualStartTime: new Date()
          }
        ]
      }));
      setIsStarted(true);
      
    } catch (err) {
      const errorMessage = `Error starting job: ${err.message}`;
      console.error(errorMessage, err);
      setError(errorMessage);
    }
  };

  // Photo Upload Functions
  const handlePhotoUpload = async (event) => {
    const files = Array.from(event.target.files);
    setUploadingPhotos(true);
    const storage = getStorage();
    
    try {
      const uploadedPhotos = await Promise.all(
        files.map(async (file) => {
          // Generate a unique filename using timestamp
          const timestamp = Date.now();
          const uniqueFileName = `${timestamp}_${file.name}`;
          const fileRef = ref(storage, `jobs/${orgId}/${jobId}/completion/${uniqueFileName}`);
          
          // Upload the file
          await uploadBytes(fileRef, file);
          
          // Store just the filename in completionPhotos array
          return uniqueFileName;
        })
      );
      
      // Update state with just the filenames
      setPhotos([...photos, ...uploadedPhotos]);
    } catch (err) {
      console.error("Error uploading photos:", err);
      setError("Failed to upload photos");
    } finally {
      setUploadingPhotos(false);
    }
  };

  // Handle opening the completion confirmation dialog
  const handleCompleteJob = () => {
    setOpenDialog(true);
  };

  const handleConfirmComplete = async () => {
    if (!job || !orgId || !jobId) {
      console.error("Job, orgId, or jobId is missing");
      return;
    }
  
    try {
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      const jobSnap = await getDoc(jobRef);
      
      if (!jobSnap.exists()) {
        throw new Error('Job not found during completion.');
      }
  
      const jobData = jobSnap.data();
      if (!jobData) {
        throw new Error('No job data found.');
      }
  
      // Get the first cleaner ID from the cleaners array
      let cleanerId;
      if (Array.isArray(jobData.cleaners) && jobData.cleaners.length > 0) {
        cleanerId = jobData.cleaners[0];  // Get first cleaner ID
      }
  
      if (!cleanerId) {
        throw new Error('No cleaner ID found for job.');
      }
  
      // Get cleaner data
      const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', cleanerId);
      const cleanerSnap = await getDoc(cleanerRef);
      
      if (!cleanerSnap.exists()) {
        throw new Error('Cleaner data not found');
      }
      
      const cleanerData = cleanerSnap.data();
      const cleanerPayRate = cleanerData?.payRate || 0;
  
      // Ensure startTime exists and is valid
      const startTimeMillis = jobData.startTime?.toDate?.();
      if (!startTimeMillis) {
        throw new Error('Invalid start time');
      }
  
      const now = new Date();
      const durationMillis = now.getTime() - startTimeMillis.getTime();
      const durationHours = durationMillis / (1000 * 60 * 60);
      const durationDecimal = parseFloat(durationHours.toFixed(2));
      const jobExpenses = durationDecimal * cleanerPayRate;
  
      // Initialize or update cleanersActualTimes array
      let cleanersActualTimes = jobData.cleanersActualTimes || [];
      const existingCleanerIndex = cleanersActualTimes.findIndex(
        c => c.cleanerId === cleanerId
      );
  
      const cleanerEntry = {
        cleanerId,
        cleanerName: cleanerData.firstName && cleanerData.lastName 
          ? `${cleanerData.firstName} ${cleanerData.lastName}`
          : cleanerData.displayName || cleanerData.email,
        status: 'Completed',
        actualEndTime: now,
        actualDuration: durationDecimal,
        laborCost: jobExpenses,
        payRate: cleanerPayRate,
        cleanerNotes: completionNotes || '',
        jobDifficultyRating: difficultyRating || 0,
        jobCleanlinessRating: cleanlinessRating || 0,
        completionPhotos: photos || []
      };
  
      if (existingCleanerIndex >= 0) {
        cleanersActualTimes[existingCleanerIndex] = {
          ...cleanersActualTimes[existingCleanerIndex],
          ...cleanerEntry
        };
      } else {
        cleanersActualTimes.push(cleanerEntry);
      }
  
      // Check if all cleaners have completed
      const allCompleted = jobData.cleaners.every(cleaner => {
        const cleanerTime = cleanersActualTimes.find(
          ct => ct.cleanerId === (typeof cleaner === 'string' ? cleaner : cleaner.id)
        );
        return cleanerTime && cleanerTime.status === 'Completed';
      });
  
      // First update the array with regular Date objects
      await updateDoc(jobRef, {
        cleanersActualTimes
      });
  
      // Then update the other fields with serverTimestamp
      const updateData = {
        updatedAt: serverTimestamp()
      };
  
      // Only change status to completed if all cleaners are done
      if (allCompleted) {
        updateData.status = JOB_STATUSES.COMPLETED;
        updateData.endTime = serverTimestamp();
        updateData.duration = durationDecimal;
        updateData.jobExpenses = jobExpenses;
      }
  
      // Only add fields if they have values
      if (completionNotes) {
        updateData.completionNotes = completionNotes;
      }
  
      if (difficultyRating > 0) {
        updateData.jobDifficultyRating = difficultyRating;
      }
  
      if (cleanlinessRating > 0) {
        updateData.jobCleanlinessRating = cleanlinessRating;
      }
  
      if (photos && photos.length > 0) {
        updateData.completionPhotos = photos;
      }
  
      // Update the job document with serverTimestamp fields
      await updateDoc(jobRef, updateData);
      
      setIsCompleted(true);
      setOpenDialog(false);

      // Add a small delay before refreshing to ensure UI feedback is visible
      setTimeout(() => {
        window.location.reload();
      }, 500);
  
    } catch (err) {
      console.error("Error completing job:", err);
      setError('Error completing job: ' + err.message);
    }
  };

  const getRemainingCleaners = () => {
    if (!job?.cleaners || !job?.cleanersActualTimes) return [];
    
    return job.cleaners.filter(cleaner => {
      const cleanerId = typeof cleaner === 'string' ? cleaner : cleaner.id;
      const cleanerTime = job.cleanersActualTimes.find(
        ct => ct.cleanerId === cleanerId
      );
      return !cleanerTime || cleanerTime.status !== 'Completed';
    });
  };

  const handleTaskChange = async (roomId, taskIndex, completed) => {
    if (!job || !orgId) {
      console.error('Job data or orgId is missing.');
      return;
    }
  
    try {
      const updatedRooms = job.rooms.map(room => {
        if (room.id === roomId) {
          const updatedTasks = [...room.tasks];
          updatedTasks[taskIndex] = {
            ...updatedTasks[taskIndex],
            completed: completed
          };
          return { ...room, tasks: updatedTasks };
        }
        return room;
      });
  
      const jobRef = doc(db, 'organizations', orgId, 'jobs', jobId);
      await updateDoc(jobRef, { rooms: updatedRooms });
      setJob({ ...job, rooms: updatedRooms });
    } catch (err) {
      console.error('Error updating task:', err);
      setError('Error updating task: ' + err.message);
    }
  };

  const formatAppointmentTime = (job) => {
    if (!job.appointmentDate || !job.scheduledEndTime) return 'Time not specified';
    
    // Firebase timestamps have a toDate() method
    const start = job.appointmentDate.toDate();
    const end = job.scheduledEndTime.toDate();
    
    // Format the date and times
    const date = start.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
    
    const startTime = start.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    
    const endTime = end.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    });
    
    return `${date}, ${startTime} - ${endTime}`;
  };

  // Render loading state
  if (loading) {
    return (
      <Box className={`${styles.cleanerJobView} ${styles.loadingContainer}`}>
        <CircularProgress />
      </Box>
    );
  }

  // Render error state
  if (error) {
    console.error('Rendering error message:', error);
    return (
      <Box className={`${styles.cleanerJobView} ${styles.errorContainer}`}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // Render no job found state
  if (!job) {
    console.warn('No job data available to display.');
    return (
      <Box className={`${styles.cleanerJobView} ${styles.noJobContainer}`}>
        <Typography>No job found.</Typography>
      </Box>
    );
  }

  // Main render
  return (
    <Box sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <MobileHeader title="Job Details" />
      
      {/* Job Overview Card */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={3}>
              <Box display="flex" alignItems="center">
                <Person sx={{ mr: 1 }} />
                <Typography variant="h6">{job.customerName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display="flex" alignItems="center">
                <LocationOn sx={{ mr: 1 }} />
                <AddressLink address={job.address} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" alignItems="center">
                <AccessTime sx={{ mr: 1 }} />
                <Typography>
                  {formatAppointmentTime(job)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <StatusChip status={job.status} />
            </Grid>
            <Grid item xs={12}>
              {(() => {
                if (!job.recurringServices) return <Typography>One Time Service</Typography>;
                
                if (job.recurrenceRule) {
                  const rule = job.recurrenceRule;
                  if (rule.includes('FREQ=MONTHLY')) {
                    // Parse BYDAY value
                    const bydayMatch = rule.match(/BYDAY=(\d+)([A-Z]{2})/);
                    if (bydayMatch) {
                      const [_, week, day] = bydayMatch;
                      const dayNames = {
                        'MO': 'Monday',
                        'TU': 'Tuesday',
                        'WE': 'Wednesday',
                        'TH': 'Thursday',
                        'FR': 'Friday',
                        'SA': 'Saturday',
                        'SU': 'Sunday'
                      };
                      const weekNames = {
                        '1': 'first',
                        '2': 'second',
                        '3': 'third',
                        '4': 'fourth',
                        '-1': 'last'
                      };
                      return (
                        <Typography>
                          Recurring Monthly ({weekNames[week]} {dayNames[day]})
                        </Typography>
                      );
                    }
                  }
                  return <Typography>Recurring Service</Typography>;
                }
                return <Typography>Recurring Service</Typography>;
              })()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Team Status Card - New Addition */}
      {job?.cleaners?.length > 1 && (
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Team Status
            </Typography>
            <Box sx={{ mt: 2 }}>
              {job.cleaners.map(cleaner => {
                const cleanerId = typeof cleaner === 'string' ? cleaner : cleaner.id;
                const cleanerTime = job.cleanersActualTimes?.find(
                  ct => ct.cleanerId === cleanerId
                );
                const status = cleanerTime?.status || 'Not Started';
                const isCurrentUser = cleanerId === user?.uid;

                return (
                  <Box 
                    key={cleanerId}
                    sx={{ 
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mb: 1,
                      p: 1,
                      bgcolor: isCurrentUser ? 'action.hover' : 'transparent',
                      borderRadius: 1
                    }}
                  >
                    <Typography>
                      {isCurrentUser ? 'You' : (cleaner.name || 'Other Cleaner')}
                    </Typography>
                    <Chip
                      label={status}
                      color={
                        status === 'Completed' ? 'success' :
                        status === 'In Progress' ? 'primary' :
                        'default'
                      }
                      size="small"
                    />
                  </Box>
                );
              })}
            </Box>
          </CardContent>
        </Card>
      )}

      {/* Add On Services Card */}
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Additional Services
          </Typography>
          <Divider sx={{ mb: 2 }} />
          
          {(job.addOns?.length > 0 || job.otherAddOnRequest) ? (
            <Stack spacing={2}>
              {/* Standard Add-ons */}
              {job.addOns?.map((addon, index) => (
                <Box key={index}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between',
                    alignItems: 'flex-start'
                  }}>
                    <Box>
                      <Typography variant="subtitle2">
                        {addon.name}
                      </Typography>
                      {addon.description && (
                        <Typography variant="body2" color="text.secondary">
                          {addon.description}
                        </Typography>
                      )}
                      <Divider sx={{ mb: 2, mt: 2 }} />
                        <Typography variant="subtitle2">
                          For Service Date: {job.appointmentDate.toDate().toLocaleDateString()}
                        </Typography>
                    </Box>
                    <Box sx={{ textAlign: 'right' }}>
                      <Typography variant="subtitle2">
                        <span>{addon.quantity || 1}x</span>                  
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}

                  {/* Custom Service Request */}
                  {job.otherAddOnRequest && (
                    <Box sx={{ 
                      mt: 2,
                      p: 2, 
                      bgcolor: 'action.hover', 
                      borderRadius: 1 
                    }}>
                      <Typography variant="subtitle2" color="primary">
                        Custom Service Request
                      </Typography>
                      <Typography variant="body2">
                        {job.otherAddOnRequest}
                      </Typography>
                    </Box>
                  )}

                  {/* Add-on Notes */}
                  {job.addOnNotes && (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle2" color="text.secondary">
                        Additional Notes:
                      </Typography>
                      <Typography variant="body2">
                        {job.addOnNotes}
                      </Typography>
                    </Box>
                  )}
                </Stack>
              ) : (
                <Typography color="text.secondary">
                  No additional services requested for this job
                </Typography>
              )}
            </CardContent>
          </Card>

          {/* Rooms Card */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Rooms to Clean
              </Typography>
              <Divider sx={{ mb: 2 }} />
              {job.rooms && job.rooms.length > 0 ? (
                job.rooms.map(room => (
                  <Accordion key={room.id} sx={{ mb: 1 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={room.completed}
                            onChange={() => handleCheckRoom(room.id)}
                            disabled={isCompleted || !isStarted}
                          />
                        }
                        label={<Typography variant="subtitle1">{room.name}</Typography>}
                        sx={{ width: '100%', mr: 0 }}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        {room.description}
                      </Typography>
                      {Array.isArray(room.tasks) && room.tasks.length > 0 && (
                        <Box sx={{ ml: 2 }}>
                          {room.tasks.map((task, index) => (
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  checked={task.completed || false}
                                  onChange={() => handleTaskChange(room.id, index, !task.completed)}
                                  disabled={isCompleted || !isStarted}
                                  size="small"
                                />
                              }
                              label={<Typography variant="body2">{task.description || task}</Typography>}
                            />
                          ))}
                        </Box>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography color="text.secondary">No rooms assigned to this job.</Typography>
              )}
            </CardContent>
          </Card>

          {/* Notes Card */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Notes
              </Typography>
              <Divider sx={{ mb: 2 }} />
              
              {/* General Notes */}
              {job.notes && (
                <Accordion sx={{ mb: 1 }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle2">
                      Job Notes
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="text.secondary">
                      {job.notes.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}

              {/* Important Notes */}
              {job.importantNotes && (
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="subtitle2" color="error">
                      Important Notes
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="body2" color="text.secondary">
                      {job.importantNotes.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )}

              {/* Cleaner Notes */}
              <Box>
                <Typography variant="subtitle2" gutterBottom>
                  Cleaner Notes
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  disabled={isCompleted || !isStarted}
                  size="small"
                />
              </Box>
            </CardContent>
          </Card>

          {/* Modified Action Buttons Card */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'flex-end', 
                gap: 2
              }}>
                {!currentCleanerStatus && (
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleStartJob}
                    size="large"
                    fullWidth
                  >
                    Start Job
                  </Button>
                )}
                {currentCleanerStatus === 'In Progress' && (
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleCompleteJob}
                    size="large"
                    fullWidth
                  >
                    Complete Job
                  </Button>
                )}
              </Box>
              
              {/* Add remaining cleaners info */}
              {job?.status !== JOB_STATUSES.COMPLETED && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    {getRemainingCleaners().length > 0 
                      ? `Waiting for ${getRemainingCleaners().length} cleaner(s) to complete the job`
                      : 'All cleaners have completed the job'}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>

          {/* Confirmation Dialog for Job Completion */}
          <Dialog 
          open={openDialog} 
          onClose={() => setOpenDialog(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Complete Job</DialogTitle>
          <DialogContent>
            <Stack spacing={3} sx={{ mt: 2 }}>
              <Box>
                <Typography gutterBottom>How difficult was this job today?</Typography>
                <Rating
                  value={difficultyRating}
                  onChange={(event, newValue) => setDifficultyRating(newValue)}
                  max={5}
                />
                <Typography color="text.secondary">
                  <Box sx={{ fontSize: '12px' }}>1 Star - Extremely Difficult</Box>
                </Typography>
                <Typography color="text.secondary">
                  <Box sx={{ fontSize: '12px' }}>5 Stars - Extremely Easy</Box>
                </Typography>
              </Box>

              <Box>
                <Typography gutterBottom>How clean was this job?</Typography>
                <Rating
                  value={cleanlinessRating}
                  onChange={(event, newValue) => setCleanlinessRating(newValue)}
                  max={5}
                />
                <Typography color="text.secondary">
                  <Box sx={{ fontSize: '12px' }}>1 Star - Extremely Dirty</Box>
                </Typography>
                <Typography color="text.secondary">
                  <Box sx={{ fontSize: '12px' }}>5 Stars - Extremely Clean</Box>
                </Typography>
              </Box>

              <TextField
                fullWidth
                multiline
                rows={4}
                label="Any Additional Notes?"
                value={completionNotes}
                onChange={(e) => setCompletionNotes(e.target.value)}
              />

              <Box>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="photo-upload"
                  multiple
                  type="file"
                  onChange={handlePhotoUpload}
                />
                <label htmlFor="photo-upload">
                  <IconButton
                    color="primary"
                    component="span"
                    disabled={uploadingPhotos}
                  >
                    <PhotoCamera />
                  </IconButton>
                  <Typography variant="caption" component="span">
                    {uploadingPhotos ? 'Uploading...' : ' Upload Photos'}
                  </Typography>
                </label>
                {photos.length > 0 && (
                  <Typography variant="caption" display="block">
                    {photos.length} photo(s) uploaded
                  </Typography>
                )}
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button 
              onClick={handleConfirmComplete} 
              color="primary" 
              disabled={uploadingPhotos}
            >
              Complete Job
            </Button>
          </DialogActions>
        </Dialog>
        </Box>
      );
    };

    export default CleanerJobView;
