import styles from "../../../styles/JobOverview/CalendarCards/ScheduledCard.module.css"

import { DEFAULT_STATUS_COLORS } from "../../../context/JobContext";

const ScheduledCard = ({ job, onJobClick, isLargeCard=false}) => {
  const formatTime = (date) => date
    ? date.format('hh:mm A')
    : undefined;

  const startTime = formatTime(job.appointmentDate)
  const endTime = formatTime(job.scheduledEndTime)

  const formatAddress = (address) => {
    if (!address) return '';
    const { line1, line2, city, state, zip } = address;
    const addressParts = [line1];
    if (line2) addressParts.push(line2);
    if (city || state || zip) {
      const cityStateZip = [city, state, zip].filter(Boolean).join(', ');
      addressParts.push(cityStateZip);
    }
    return addressParts[0];
  };

  const hexToRgba = (hex, alpha = 1) => {
    hex = hex.replace(/^#/, '');
  
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
  
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const getEventStyle = (job) => {
    const getDecimalPart = (num) => num - Math.floor(num);

    const startTime = job.appointmentDate;
    const endTime = job.scheduledEndTime;

    const startMinutes = startTime.hours() * 60 + startTime.minutes();
    const endMinutes = endTime.hours() * 60 + endTime.minutes();

    const slotHeight = 40;
    const slotDuration = 30;

    const topPosition = getDecimalPart(startMinutes / slotDuration) * slotHeight;
    const eventHeight = ((endMinutes - startMinutes) / slotDuration) * slotHeight;

    if (isLargeCard) {
      return {
        top: `${topPosition}px`,
        height: `${eventHeight}px`,
        position: "absolute",
        backgroundColor: DEFAULT_STATUS_COLORS[job.status] ?? "#ffffff",
        borderRadius: "5px",
        padding: "5px",
        width: "100%",
      };
    } else {
      return {
        backgroundColor: hexToRgba(DEFAULT_STATUS_COLORS[job.status] ?? "#ffffff", 0.1)
      }
    }
  };

  return (
    <div
      key={job.id}
      style={getEventStyle(job)}
      className={styles.card}
      onClick={() => onJobClick(job)}
    >
      <p>
        {startTime} { endTime && <span>- {endTime}</span>}
      </p>
      <p>{job.customerName}</p>
      <p>{formatAddress(job.address)}</p>
    </div>
  )
}

export default ScheduledCard;