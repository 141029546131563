// src/components/JobEditForm.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Checkbox,
  FormControlLabel,
  Alert,
  InputAdornment,
  Autocomplete
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import RecurrenceSelect from './RecurrenceSelect';
import { JOB_STATUSES } from '../context/JobContext';
import { formatRecurrenceRule } from '../utils/formatRecurrenceRule';
import { useTimezone } from '../context/TimeZoneContext';
import moment from 'moment';
import SelectCleanersModal from './SelectCleanersModal';
import { Autocomplete as GoogleAutocomplete } from '@react-google-maps/api';
import styles from '../styles/JobEditForm.module.css';


const JobEditForm = ({
  job = {},
  handleEditInputChange,
  handleAddRooms,
  handleDeleteRoom,
  openAddRoomsModal,
  setOpenAddRoomsModal,
  availableRooms,
  handleApplyToRecurringChange,
  applyToRecurring,
  isReactivating = false,
  cleaners = []
}) => {
  // Add loading state
  const [loading, setLoading] = useState(false);
  const [addressAutocomplete, setAddressAutocomplete] = useState(null);

  // State for cleaners
  const [openSelectCleanersModal, setOpenSelectCleanersModal] = useState(false);
  const [selectedCleaners, setSelectedCleaners] = useState([]);

  const { timezone, formatInOrgTz, convertToOrgTz, convertFromOrgTz } = useTimezone();
  const [timeError, setTimeError] = useState('');

  // Helper function to get cleaner display name
  const getCleanerDisplayName = (cleaner) => {
    if (!cleaner) return '';
    // Try different possible name field combinations
    if (cleaner.name) return cleaner.name;
    if (cleaner.firstName && cleaner.lastName) return `${cleaner.firstName} ${cleaner.lastName}`;
    if (cleaner.displayName) return cleaner.displayName;
    if (cleaner.email) return cleaner.email;
    return `Cleaner ${cleaner.id}`;
  };

// Add useEffect to initialize selected cleaners
useEffect(() => {
  if (Array.isArray(job?.cleanerDetails) && Array.isArray(cleaners) && job.cleanerDetails.length > 0 && cleaners.length > 0) {
    const initialSelectedCleaners = job.cleanerDetails
      .map(jobCleaner => 
        cleaners.find(c => c.id === jobCleaner.id)
      )
      .filter(Boolean);
    setSelectedCleaners(initialSelectedCleaners);
  }
}, [job?.cleanerDetails, cleaners]);

  // Format service cost from job data
  const getInitialServiceCost = () => {
    if (!job) return 0;
    const cost = job.serviceCost || job.price || 0;
    const numericCost = parseFloat(String(cost).replace(/[^0-9.-]+/g, ''));
    return isNaN(numericCost) ? 0 : numericCost;
  };

  // Get the formatted date and times from the job data
  const getFormattedDateTime = () => {
    try {
      if (!job) {
        const now = convertToOrgTz(new Date());
        return {
          date: now.format('YYYY-MM-DD'),
          startTime: '09:00',
          endTime: '11:00',
          actualStartTime: '',
          actualEndTime: '',
          actualDate: now.format('YYYY-MM-DD')
        };
      }
  
      // Keep existing appointment date handling since it works
      const startTime = job.appointmentDate?.toDate 
        ? moment(job.appointmentDate.toDate())
        : moment(job.appointmentDate);
      
      const endTime = job.scheduledEndTime?.toDate 
        ? moment(job.scheduledEndTime.toDate())
        : moment(job.scheduledEndTime);
  
      // Use convertToOrgTz for actual times
      const actualStart = job.actualStartTime ? convertToOrgTz(job.actualStartTime) : null;
      const actualEnd = job.endTime ? convertToOrgTz(job.endTime) : null;
  
      return {
        date: startTime.format('YYYY-MM-DD'),
        startTime: startTime.format('HH:mm'),
        endTime: endTime.format('HH:mm'),
        actualStartTime: actualStart ? actualStart.format('HH:mm') : '',
        actualEndTime: actualEnd ? actualEnd.format('HH:mm') : '',
        actualDate: actualStart ? actualStart.format('YYYY-MM-DD') : startTime.format('YYYY-MM-DD')
      };
    } catch (error) {
      console.error('Error formatting date/time:', error);
      return {
        date: moment().format('YYYY-MM-DD'),
        startTime: '09:00',
        endTime: '11:00',
        actualStartTime: '',
        actualEndTime: '',
        actualDate: moment().format('YYYY-MM-DD')
      };
    }
  };

  const { date, startTime, endTime, actualStartTime, actualEndTime, actualDate } = getFormattedDateTime();

  // Get current actual times for display
  const getCurrentTimes = () => {
    if (!job) {
      return {
        start: 'Not set',
        end: 'Not set'
      };
    }
  
    const formatTime = (timeValue) => {
      if (!timeValue) return 'Not set';
      try {
        return formatInOrgTz(timeValue, 'LLLL z');
      } catch (error) {
        console.error('Error formatting time:', error);
        return 'Not set';
      }
    };
  
    return {
      start: formatTime(job.actualStartTime),
      end: formatTime(job.endTime)
    };
  };

  const currentTimes = getCurrentTimes();


  const handleDateChange = (newDate) => {
    try {
      const startMoment = convertFromOrgTz(`${newDate} ${startTime}`);
      const endMoment = convertFromOrgTz(`${newDate} ${endTime}`);

      handleEditInputChange('appointmentDate', startMoment.toDate());
      handleEditInputChange('scheduledEndTime', endMoment.toDate());
    } catch (error) {
      console.error('Error updating dates:', error);
    }
  };

  const handleStartTimeChange = (newTime) => {
    try {
      const startMoment = convertFromOrgTz(`${date} ${newTime}`);
      handleEditInputChange('appointmentDate', startMoment.toDate());
    } catch (error) {
      console.error('Error updating start time:', error);
    }
  };

  const handleEndTimeChange = (newTime) => {
    try {
      const endMoment = convertFromOrgTz(`${date} ${newTime}`);
      handleEditInputChange('scheduledEndTime', endMoment.toDate());
    } catch (error) {
      console.error('Error updating end time:', error);
    }
  };

  const formatDuration = (duration) => {
    if (!duration) return '0 hrs';
    const hours = Math.floor(duration);
    const minutes = Math.round((duration - hours) * 60);
    if (hours === 0) {
      return `${minutes} mins`;
    }
    if (minutes === 0) {
      return `${hours} hrs`;
    }
    return `${hours} hrs ${minutes} mins`;
  };

  const handleAddressAutocompleteLoad = (autocomplete) => {
    setAddressAutocomplete(autocomplete);
  };
  
  const handlePlaceSelect = () => {
    try {
      const place = addressAutocomplete.getPlace();
      
      if (!place.address_components) {
        return;
      }
  
      handleEditInputChange('address', place.formatted_address);
      
    } catch (error) {
      console.error('Error processing place selection:', error);
    }
  };
  

  return (
    <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
      <Typography variant="h5" gutterBottom>
        {isReactivating ? 'Reactivate Recurring Service' : 'Edit Job Details'}
      </Typography>

      {isReactivating && (
        <Alert severity="info" sx={{ mb: 3 }}>
          You are reactivating a recurring service. Please update the service details and schedule as needed.
        </Alert>
      )}

      <Grid container spacing={2}>
        {/* Customer Name */}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Customer Name"
            value={job.customerName || ''}
            onChange={(e) => handleEditInputChange('customerName', e.target.value)}
            fullWidth
            disabled={isReactivating}
            className={styles.customerName}
          />
        </Grid>

        {/* Cleaner Selection */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            Assigned Cleaners
          </Typography>
          
          {/* Display selected cleaners */}
          <Box sx={{ mb: 2 }}>
              {Array.isArray(job?.cleanerDetails) && job.cleanerDetails.length > 0 ? (
                job.cleanerDetails.map(cleaner => (
                  <div key={cleaner.id} className={styles.cleanerItem}>
                    <span>
                      {getCleanerDisplayName(cleaner)}
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        const updatedCleaners = job.cleanerDetails.filter(c => c.id !== cleaner.id);
                        handleEditInputChange('cleanerDetails', updatedCleaners);
                      }}
                      className={styles.removeButton}
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : job.cleanerName ? (
                <div className={styles.cleanerItem}>
                  <span>{job.cleanerName}</span>
                </div>
              ) : (
                <Typography color="text.secondary">No cleaners assigned</Typography>
              )}
            </Box>

          {/* Button to open cleaner selection modal */}
          <SelectCleanersModal
            open={openSelectCleanersModal}
            handleClose={() => setOpenSelectCleanersModal(false)}
            cleaners={cleaners} // Make sure this is an array of cleaner objects
            selectedCleaners={Array.isArray(job?.cleanerDetails) ? job.cleanerDetails : []}
            setSelectedCleaners={(newCleaners) => {
              handleEditInputChange('cleanerDetails', newCleaners);
              setOpenSelectCleanersModal(false);
            }}
            />

            {/* Button to open cleaner selection modal */}
            <Button
              variant="outlined"
              onClick={() => setOpenSelectCleanersModal(true)}
              className={styles.button}
            >
              {Array.isArray(job?.cleanerDetails) && job.cleanerDetails.length > 0 ? 'Modify Cleaners' : 'Select Cleaners'}
            </Button>
          </Grid>

        {/* Address */}
        <Grid item xs={12}>
        <GoogleAutocomplete
          onLoad={handleAddressAutocompleteLoad}
          onPlaceChanged={handlePlaceSelect}
          options={{
            componentRestrictions: { country: 'us' },
            fields: ['address_components', 'formatted_address', 'geometry', 'name'],
            types: ['address']
          }}
        >
          <TextField
            label="Address"
            value={job.address || ''}
            onChange={(e) => handleEditInputChange('address', e.target.value)}
            fullWidth
            disabled={isReactivating}
            className={styles.addressField}
          />
        </GoogleAutocomplete>
        </Grid>

        {/* Service Type and Cost */}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="service-type-label">Service Type</InputLabel>
            <Select
              labelId="service-type-label"
              value={job.serviceType || 'one-time'}
              label="Service Type"
              onChange={(e) => {
                const newType = e.target.value;
                handleEditInputChange('serviceType', newType);
                handleEditInputChange('recurring', newType === 'recurring');
                if (newType === 'one-time') {
                  handleEditInputChange('recurrenceRule', '');
                }
              }}
              disabled={isReactivating}
            >
              <MenuItem value="one-time">One Time Service</MenuItem>
              <MenuItem value="recurring">Recurring Service</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Service Cost ($)"
            type="number"
            value={getInitialServiceCost()} // Keep the raw number for easier editing
            onChange={(e) => {
              const value = e.target.value;
              if (value === '' || /^\d*\.?\d*$/.test(value)) { // Allow only valid numeric input
                handleEditInputChange('serviceCost', value); // Pass the raw input value
                handleEditInputChange('price', value);
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>, // Adds the $ symbol
              inputProps: { min: 0, step: '0.01' }, // Allow decimal values with two decimal precision
            }}
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          {job.serviceType === 'recurring' && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToRecurring}
                  onChange={(e) => handleApplyToRecurringChange(e.target.checked)}
                />
              }
              label="Apply changes to all future recurring instances"
            />
          )}
        </Grid>

        {/* Recurrence Information - Show for both recurring and reactivating */}
        {(job.serviceType === 'recurring' || isReactivating) && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              Recurrence Information
            </Typography>
            {isReactivating ? (
              <>
                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  Please set the new recurrence pattern for this service
                </Typography>
                <RecurrenceSelect
                  value={job.recurrenceRule}
                  onChange={(value) => handleEditInputChange('recurrenceRule', value)}
                />
              </>
            ) : (
              <Typography variant="body2" color="textSecondary">
                {formatRecurrenceRule(job.recurrenceRule)}
              </Typography>
            )}
          </Grid>
        )}

        {/* Scheduled Date and Time Selection */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            {isReactivating ? 'New Start Time' : 'Scheduled Time'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label={isReactivating ? "New Start Date" : "Appointment Date"}
            type="date"
            value={date}
            onChange={(e) => handleDateChange(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Start Time"
            type="time"
            value={startTime}
            onChange={(e) => handleStartTimeChange(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="End Time"
            type="time"
            value={endTime}
            onChange={(e) => handleEndTimeChange(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {/* Apply to Recurring Checkbox - Only show if not reactivating */}
        {job.serviceType === 'recurring' && !isReactivating && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToRecurring}
                  onChange={(e) => handleApplyToRecurringChange(e.target.checked)}
                />
              }
              label="Apply changes to all future recurring instances"
            />
          </Grid>
        )}

        {/* Total Size */}
        <Grid item xs={12}>
          <TextField
            label="Total Size (SqFt)"
            type="number"
            value={job.totalSize || ''}
            onChange={(e) => handleEditInputChange('totalSize', e.target.value)}
            InputProps={{
              inputProps: { min: 0, step: '1' },
            }}
            fullWidth
            placeholder="Enter total size in square feet"
          />
        </Grid>

        {/* Divider before Rooms */}
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        {/* Collapsible Rooms Section */}
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rooms-content"
              id="rooms-header"
            >
              <Typography variant="h6">Rooms</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" justifyContent="flex-end" mb={1}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => setOpenAddRoomsModal(true)}
                >
                  Add/Edit Rooms
                </Button>
              </Box>
              <List>
                {job.rooms?.map((room, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Button
                        variant="text"
                        color="secondary"
                        onClick={() => handleDeleteRoom(index)}
                        startIcon={<DeleteIcon />}
                      >
                        Delete
                      </Button>
                    }
                  >
                    <ListItemText
                      primary={`${room.name} (${room.quantity})`}
                      secondary={
                        room.tasks?.map((task, taskIndex) => (
                          <Typography
                            key={taskIndex}
                            variant="body2"
                            component="div"
                            sx={{ ml: 2 }}
                          >
                            • {task.description}
                          </Typography>
                        ))
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
        
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

{/* Actual Times Section */}
<Grid item xs={12}>
  <Typography variant="subtitle1" gutterBottom>
    Actual Times
  </Typography>
  {timeError && (
    <Alert severity="error" sx={{ mb: 2 }}>
      {timeError}
    </Alert>
  )}

  {/* Cleaner Times */}
  {job.cleanerDetails?.map((cleaner, index) => {
    // Find existing time data for this cleaner
    const cleanerTimeData = job.cleanersActualTimes?.find(
      ct => ct.cleanerId === cleaner.id
    ) || {};

    // Format the cleaner's dates and times
    const cleanerDate = cleanerTimeData.actualStartTime
      ? convertToOrgTz(cleanerTimeData.actualStartTime).format('YYYY-MM-DD')
      : date;

    const cleanerStartTime = cleanerTimeData.actualStartTime
      ? convertToOrgTz(cleanerTimeData.actualStartTime).format('HH:mm')
      : '';

    const cleanerEndTime = cleanerTimeData.actualEndTime
      ? convertToOrgTz(cleanerTimeData.actualEndTime).format('HH:mm')
      : '';

    return (
      <Box key={cleaner.id} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
        <Typography variant="subtitle2" gutterBottom>
          {getCleanerDisplayName(cleaner)}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Date"
              type="date"
              value={cleanerDate}
              onChange={(e) => {
                const newDate = e.target.value;
                let updatedTimes = [...(job.cleanersActualTimes || [])];
                const existingIndex = updatedTimes.findIndex(ct => ct.cleanerId === cleaner.id);
                
                if (existingIndex >= 0) {
                  updatedTimes[existingIndex] = {
                    ...updatedTimes[existingIndex],
                    actualStartTime: cleanerStartTime 
                      ? convertFromOrgTz(`${newDate} ${cleanerStartTime}`).toDate()
                      : null,
                    actualEndTime: cleanerEndTime
                      ? convertFromOrgTz(`${newDate} ${cleanerEndTime}`).toDate()
                      : null
                  };
                } else {
                  updatedTimes.push({
                    cleanerId: cleaner.id,
                    cleanerName: getCleanerDisplayName(cleaner),
                    actualStartTime: cleanerStartTime 
                      ? convertFromOrgTz(`${newDate} ${cleanerStartTime}`).toDate()
                      : null,
                    actualEndTime: cleanerEndTime
                      ? convertFromOrgTz(`${newDate} ${cleanerEndTime}`).toDate()
                      : null
                  });
                }
                
                handleEditInputChange('cleanersActualTimes', updatedTimes);
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Start Time"
              type="time"
              value={cleanerStartTime}
              onChange={(e) => {
                const newTime = e.target.value;
                let updatedTimes = [...(job.cleanersActualTimes || [])];
                const existingIndex = updatedTimes.findIndex(ct => ct.cleanerId === cleaner.id);
                
                if (existingIndex >= 0) {
                  updatedTimes[existingIndex] = {
                    ...updatedTimes[existingIndex],
                    actualStartTime: convertFromOrgTz(`${cleanerDate} ${newTime}`).toDate()
                  };
                } else {
                  updatedTimes.push({
                    cleanerId: cleaner.id,
                    cleanerName: getCleanerDisplayName(cleaner),
                    actualStartTime: convertFromOrgTz(`${cleanerDate} ${newTime}`).toDate()
                  });
                }
                
                handleEditInputChange('cleanersActualTimes', updatedTimes);
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="End Time"
              type="time"
              value={cleanerEndTime}
              onChange={(e) => {
                const newTime = e.target.value;
                let updatedTimes = [...(job.cleanersActualTimes || [])];
                const existingIndex = updatedTimes.findIndex(ct => ct.cleanerId === cleaner.id);
                
                if (existingIndex >= 0) {
                  updatedTimes[existingIndex] = {
                    ...updatedTimes[existingIndex],
                    actualEndTime: convertFromOrgTz(`${cleanerDate} ${newTime}`).toDate()
                  };
                } else {
                  updatedTimes.push({
                    cleanerId: cleaner.id,
                    cleanerName: getCleanerDisplayName(cleaner),
                    actualEndTime: convertFromOrgTz(`${cleanerDate} ${newTime}`).toDate()
                  });
                }
                
                handleEditInputChange('cleanersActualTimes', updatedTimes);
              }}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        {/* Show duration if both times are set */}
        {cleanerTimeData.actualStartTime && cleanerTimeData.actualEndTime && (
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            Duration: {formatDuration(
              convertToOrgTz(cleanerTimeData.actualEndTime).diff(
                convertToOrgTz(cleanerTimeData.actualStartTime)
              )
            )}
          </Typography>
        )}
      </Box>
    );
  })}

        {/* Mark Complete Button */}
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            // Validate all cleaners have times
            const allCleanersHaveTimes = job.cleanerDetails?.every(cleaner => {
              const timeData = job.cleanersActualTimes?.find(
                ct => ct.cleanerId === cleaner.id
              );
              return timeData?.actualStartTime && timeData?.actualEndTime;
            });

            if (!allCleanersHaveTimes) {
              setTimeError('Please set start and end times for all cleaners');
              return;
            }

            // Calculate total duration and costs
            const cleanersActualTimes = job.cleanersActualTimes.map(ct => {
              const startTime = convertToOrgTz(ct.actualStartTime);
              const endTime = convertToOrgTz(ct.actualEndTime);
              const duration = endTime.diff(startTime, 'hours', true);
              
              return {
                ...ct,
                duration,
                laborCost: job.pricingType === 'hourly' && job.pricingPer === 'cleaner'
                  ? duration * job.serviceCost
                  : 0
              };
            });

            const totalDuration = cleanersActualTimes.reduce(
              (total, ct) => total + ct.duration,
              0
            );

            // Calculate total cost based on pricing type
            let totalCost = job.serviceCost; // Default to flat rate
            if (job.pricingType === 'hourly') {
              if (job.pricingPer === 'cleaner') {
                totalCost = cleanersActualTimes.reduce(
                  (total, ct) => total + ct.laborCost,
                  0
                );
              } else {
                totalCost = totalDuration * job.serviceCost;
              }
            }

            // Update job with final calculations
            handleEditInputChange('cleanersActualTimes', cleanersActualTimes);
            handleEditInputChange('totalDuration', totalDuration);
            handleEditInputChange('serviceCost', totalCost);
            handleEditInputChange('status', JOB_STATUSES.COMPLETED);
          }}
          disabled={job.status === JOB_STATUSES.COMPLETED}
          fullWidth
          sx={{ mt: 2 }}
        >
          Mark Job Complete
        </Button>
      </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>

        {/* Notes Section */}
        <Grid item xs={12}>
          <TextField
            label="Notes"
            value={job.notes || ''}
            onChange={(e) => handleEditInputChange('notes', e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Important Notes"
            value={job.importantNotes || ''}
            onChange={(e) => handleEditInputChange('importantNotes', e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default JobEditForm;