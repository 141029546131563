// src/components/ClientDistanceFinder.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import useClientDistance from '../hooks/useCalculateDistance';
import useCurrentClientData from '../hooks/useCurrentClientsData';
import { fetchGeocodeData, fetchDistanceData } from '../utils/api';
import config from '../config';

const ClientDistanceFinder = ({ client }) => {
  const [file, setFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false); // Renamed to avoid confusion
  const [loadingText, setLoadingText] = useState('Loading');
  const [error, setError] = useState(null);
  const [top10Clients, setTop10Clients] = useState([]);
  const { 
    clients, 
    setClients, 
    clearAll, 
    updateGeocodeCache, 
    getGeocodeFromCache 
  } = useCurrentClientData();

  const {
    distance,
    duration,
    loading: distanceLoading,
    error: distanceError,
  } = useClientDistance(client);

  // Handle file selection
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload');
      return;
    }
  
    setUploadLoading(true);
    setError(null);
  
    // Log file details
    console.log('File details:', {
      name: file.name,
      type: file.type,
      size: file.size
    });
  
    const formData = new FormData();
    formData.append('file', file);
  
    // Verify FormData contents
    console.log('FormData contents:');
    for (let pair of formData.entries()) {
      console.log(pair[0], pair[1]);
    }
  
    try {
      console.log('Sending request to:', `${config.serviceBaseUrl}/upload`);
      const response = await axios.post(
        `${config.serviceBaseUrl}/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      console.log('Server response:', {
        status: response.status,
        statusText: response.statusText,
        data: response.data,
        headers: response.headers
      });
  
      if (Array.isArray(response.data)) {
        if (response.data.length > 0) {
          setClients(response.data);
          console.log('Successfully set clients:', response.data);
        } else {
          console.log('Response data is an empty array');
          setError('No client data found in the uploaded file');
        }
      } else {
        console.error('Response data is not an array:', response.data);
        throw new Error('Invalid response data format');
      }
    } catch (error) {
      console.error('Upload error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      const errorMessage = error.response?.data?.error || error.message || 'An unknown error occurred';
      const errorDetails = error.response?.data?.details || '';
      setError(`${errorMessage}${errorDetails ? `: ${errorDetails}` : ''}`);
    } finally {
      setUploadLoading(false);
    }
  };

  // Handle clearing clients from local storage and state
  const handleClearClients = () => {
    clearAll(); 
    setTop10Clients([]);
  };

  // Calculate background color based on distance
  const getBackgroundColor = () => {
    if (!distance) return 'var(--light-background)';
    const distanceValue = parseFloat(distance.split(' ')[0]);
    if (distanceValue <= 24.99) return 'var(--success)';
    if (distanceValue <= 29.99) return 'var(--warning)';
    return 'var(--error)';
  };

  // Effect to handle loading text animation
  useEffect(() => {
    let interval;
    if (uploadLoading) {
      const loadingStages = ['Loading', 'Loading.', 'Loading..', 'Loading...'];
      let index = 0;

      interval = setInterval(() => {
        setLoadingText(loadingStages[index]);
        index = (index + 1) % loadingStages.length;
      }, 500);
    } else {
      setLoadingText('Loading');
    }
    return () => clearInterval(interval);
  }, [uploadLoading]);

  useEffect(() => {
    const calculateDistances = async () => {
      if (!client || !clients || clients.length === 0) return;

      setUploadLoading(true);
      setError(null);

      try {
        // Construct selected client's address
        const clientAddress = `${client.address1}, ${client.city}, ${client.state} ${client.postalCode}`;
        
        // Get coordinates for selected client (using cache)
        const selectedCoords = await fetchGeocodeData(
          clientAddress, 
          getGeocodeFromCache, 
          updateGeocodeCache
        );

        // Calculate distances using cached coordinates where possible
        const results = await Promise.all(clients.map(async (storedClient) => {
          try {
            if (storedClient.client.address === clientAddress) {
              return null;
            }

            // Get coordinates (using cache)
            const storedCoords = await fetchGeocodeData(
              storedClient.client.address,
              getGeocodeFromCache,
              updateGeocodeCache
            );

            // Calculate distance
            const distanceData = await fetchDistanceData(
              `${selectedCoords.lat},${selectedCoords.lng}`,
              `${storedCoords.lat},${storedCoords.lng}`
            );

            return {
              ...storedClient,
              distanceFromSelectedClient: distanceData.distanceText,
              durationFromSelectedClient: distanceData.durationText,
              distanceValueFromSelectedClient: distanceData.distanceValue,
            };
          } catch (error) {
            console.error(`Error calculating distance for client ${storedClient.client.fullName}:`, error);
            return null;
          }
        }));

        const validResults = results.filter(result => result !== null);
        validResults.sort((a, b) => {
          const distA = parseFloat(a.distanceValueFromSelectedClient);
          const distB = parseFloat(b.distanceValueFromSelectedClient);
          return distA - distB;
        });

        const top10 = validResults.slice(0, 10);
        setTop10Clients(top10);
      } catch (error) {
        console.error('Error calculating distances:', error);
        setError('Error calculating distances from selected client.');
      } finally {
        setUploadLoading(false);
      }
    };

    calculateDistances();
  }, [client, clients]);

  return (
    <div className="client-distance-finder card">
      <h3 className="card-title">Upload CSV to Calculate Distances</h3>
      <div className="file-upload-container">
        <label htmlFor="csvUpload" className="file-input-label">
          Select CSV File:
        </label>
        <input
          id="csvUpload"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="file-input"
        />
        <button onClick={handleUpload} disabled={uploadLoading} className="btn btn-primary">
          {uploadLoading ? loadingText : 'Upload'}
        </button>
        <button
          onClick={handleClearClients}
          className="btn btn-secondary"
          style={{ marginLeft: '10px' }}
        >
          Clear
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      {top10Clients.length > 0 && (
        <div>
          <h3 className="card-title">Top 10 Closest Clients to Selected Contact</h3>
          <ul className="client-list">
            {top10Clients.map((clientInfo, index) => (
              <li key={index} className="client-list-item">
                {index + 1}. {clientInfo.client.fullName} | {clientInfo.client.address} |{' '}
                {clientInfo.distanceFromSelectedClient} : {clientInfo.durationFromSelectedClient} |{' '}
                {clientInfo.client.frequency}
              </li>
            ))}
          </ul>
        </div>
      )}

      <div
        className="client-distance-calculator"
        style={{
          backgroundColor: getBackgroundColor(),
          color: 'black',
          padding: '20px',
          borderRadius: '8px',
        }}
      >
        {distanceLoading && <p>Loading distance...</p>}
        {distanceError && (
          <div className="error-message">
            <p>Error: {distanceError}</p>
          </div>
        )}
        {!distanceLoading && !distanceError && distance && (
          <div className="distance-result">
            <h3>Distance to Client</h3>
            <p>{distance}</p>
            <p>Estimated Travel Time: {duration}</p>
          </div>
        )}
      </div>
    </div>
  );
};

ClientDistanceFinder.propTypes = {
  client: PropTypes.shape({
    address1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
  }),
};

export default ClientDistanceFinder;
