// /src/components/RecurringInstancesList.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Chip } from '@mui/material';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

  const RecurringInstancesList = ({ instances }) => {
    const [instancesWithCleaners, setInstancesWithCleaners] = useState([]);
  
    useEffect(() => {
      const fetchCleanerDetails = async () => {
        if (!instances?.length || !instances[0].organizationId) return;
  
        const db = getFirestore();
        const orgId = instances[0].organizationId;
        
        const updatedInstances = await Promise.all(instances.map(async (instance) => {
          if (!instance.cleaners?.[0]?.id) return instance;
  
          const cleanerDoc = await getDoc(doc(db, 'organizations', orgId, 'cleaners', instance.cleaners[0].id));
          const cleanerData = cleanerDoc.exists() ? cleanerDoc.data() : null;
  
          return {
            ...instance,
            cleanerName: cleanerData ? `${cleanerData.firstName} ${cleanerData.lastName}`.trim() : 'Unknown'
          };
        }));
  
        setInstancesWithCleaners(updatedInstances.sort((a, b) => 
          a.appointmentDate.toDate() - b.appointmentDate.toDate()
        ));
      };
  
      fetchCleanerDetails();
    }, [instances]);
  
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Recurring Instances</Typography>
        <List>
          {instancesWithCleaners.map(instance => (
            <ListItem key={instance.id}
              secondaryAction={
                <Chip label={instance.status} 
                  color={instance.status === 'pending' ? 'warning' : 'success'} 
                  size="small" />
              }>
              <ListItemText
                primary={new Date(instance.appointmentDate.toDate()).toLocaleString()}
                secondary={`Cleaners: ${instance.cleanerName || 'No cleaners assigned'}`}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

export default RecurringInstancesList;