// src/components/Invoices/InvoiceManager/InvoiceNavigation.jsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import { 
  FaFileInvoiceDollar, 
  FaCog, 
  FaList, 
  FaPlus 
} from 'react-icons/fa';

const InvoiceNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const tabs = [
    { path: '/invoices', icon: FaFileInvoiceDollar, label: 'Dashboard', exact: true },
    { path: '/invoices/list', icon: FaList, label: 'Invoices' },
    { path: '/invoices/create', icon: FaPlus, label: 'Create' },
    { path: '/invoices/settings/general', icon: FaCog, label: 'Settings' },
  ];

  // Get current tab value based on path
  const getCurrentTab = () => {
    const currentPath = location.pathname;
    if (currentPath === '/invoices') return '/invoices';
    if (currentPath.startsWith('/invoices/settings/')) return '/invoices/settings/general';
    return currentPath;
  };

  const handleChange = (event, newValue) => {
    navigate(newValue);
  };

  return (
    <Box sx={{ 
      borderBottom: 1, 
      borderColor: 'divider',
      bgcolor: 'background.paper',
      width: '100%',
      mb: 3
    }}>
      <Tabs 
        value={getCurrentTab()} 
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs.map(({ path, icon: Icon, label }) => (
          <Tab
            key={path}
            value={path}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{ marginRight: '8px' }} />
                {label}
              </Box>
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default InvoiceNavigation;