// src/hooks/useCurrentClientsData.js
import { useState, useEffect } from 'react';

const useCurrentClientData = () => {
  const [clients, setClientsState] = useState([]);
  const [geocodeCache, setGeocodeCache] = useState({});

  // Load both clients and geocode cache from localStorage
  useEffect(() => {
    const storedClients = localStorage.getItem('clients');
    const storedGeocodeCache = localStorage.getItem('geocodeCache');
    
    if (storedClients) {
      try {
        const parsedClients = JSON.parse(storedClients);
        if (Array.isArray(parsedClients)) {
          setClientsState(parsedClients);
        }
      } catch (error) {
        console.error('Error parsing stored clients:', error);
        localStorage.removeItem('clients');
      }
    }

    if (storedGeocodeCache) {
      try {
        const parsedCache = JSON.parse(storedGeocodeCache);
        setGeocodeCache(parsedCache);
      } catch (error) {
        console.error('Error parsing geocode cache:', error);
        localStorage.removeItem('geocodeCache');
      }
    }
  }, []);

  const setClients = (newClients) => {
    if (Array.isArray(newClients)) {
      setClientsState(newClients);
      localStorage.setItem('clients', JSON.stringify(newClients));
    }
  };

  const updateGeocodeCache = (address, coordinates) => {
    const newCache = { ...geocodeCache, [address]: coordinates };
    setGeocodeCache(newCache);
    localStorage.setItem('geocodeCache', JSON.stringify(newCache));
  };

  const getGeocodeFromCache = (address) => {
    return geocodeCache[address];
  };

  const clearAll = () => {
    setClientsState([]);
    setGeocodeCache({});
    localStorage.removeItem('clients');
    localStorage.removeItem('geocodeCache');
  };

  return { 
    clients, 
    setClients, 
    clearAll, 
    updateGeocodeCache, 
    getGeocodeFromCache 
  };
};

export default useCurrentClientData;
