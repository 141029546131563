//components/timeclock/TimeClockManagement.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Grid,
  Chip,
  IconButton,
  Tooltip,
  TablePagination,
  CircularProgress,
  Button,
  Stack
} from '@mui/material';
import { 
  Timer, 
  LocationOn, 
  AccessTime, 
  CalendarToday,
  ChevronLeft,
  ChevronRight,
  AttachMoney,
} from '@mui/icons-material';
import { collection, query, where, getDocs, onSnapshot, doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../../firebase';
import LocationMapModal from './LocationMapModal';
import moment from 'moment';
import LocationTrackingDashboard from './LocationTrackingDashboard';

const TimeClockManagement = () => {
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [user, setUser] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day'));
  const [cleanerRates, setCleanerRates] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [summary, setSummary] = useState({
    totalActive: 0,
    totalHoursToday: 0,
    averageShiftLength: 0,
    totalShiftsToday: 0,
    totalPayToday: 0
  });
  const [selectedEntry, setSelectedEntry] = useState(null);

  // Handle user authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserOrgId(currentUser.uid);
      } else {
        setLoading(false);
        setError('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch user's organization ID
  const fetchUserOrgId = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      
      const userOrgId = userDoc.data().orgId;
      setOrgId(userOrgId);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching user's organization ID:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch cleaner pay rates from the users collection
  const fetchCleanerRates = async (orgId) => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(
        usersRef,
        where('orgId', '==', orgId),
        where('role', '==', 'cleaner')
      );
      const cleanersSnapshot = await getDocs(q);
      const rates = {};
      
      cleanersSnapshot.forEach((doc) => {
        const cleanerData = doc.data();
        // Store just the payRate, convert to number
        rates[doc.id] = parseFloat(cleanerData.payRate || '0');
      });
      
      setCleanerRates(rates);
    } catch (error) {
      console.error('Error fetching cleaner rates:', error);
    }
  };

  // Calculate duration for both active and completed shifts
  const calculateDuration = (inDate, outDate = null) => {
    const startTime = inDate.toDate();
    const endTime = outDate ? outDate.toDate() : new Date();
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    return duration.asHours().toFixed(2);
  };

  // Calculate pay for a shift
  const calculatePay = (duration, payRate) => {
    return (parseFloat(duration) * parseFloat(payRate)).toFixed(2);
  };

  // Date navigation handlers
  const handlePreviousDay = () => {
    setSelectedDate(prev => moment(prev).subtract(1, 'day'));
  };

  const handleNextDay = () => {
    setSelectedDate(prev => moment(prev).add(1, 'day'));
  };

  // Fetch timesheet entries when orgId or selectedDate changes
  useEffect(() => {
    if (!orgId) return;

    fetchCleanerRates(orgId);

    const fetchTimesheetEntries = async () => {
      try {
        const startOfDay = selectedDate.toDate();
        const endOfDay = moment(selectedDate).endOf('day').toDate();

        const usersRef = collection(db, 'users');
        const q = query(
          usersRef,
          where('orgId', '==', orgId),
          where('role', '==', 'cleaner')
        );
        const cleanersSnapshot = await getDocs(q);
        
        const unsubscribes = [];
        const entriesMap = new Map();

        cleanersSnapshot.forEach((cleanerDoc) => {
          const cleanerData = cleanerDoc.data();
          const timesheetRef = collection(
            db, 
            'organizations', 
            orgId, 
            'cleaners', 
            cleanerDoc.id, 
            'timesheet'
          );
          
          const timesheetQuery = query(
            timesheetRef,
            where('inDate', '>=', startOfDay),
            where('inDate', '<=', endOfDay)
          );

          const unsubscribe = onSnapshot(timesheetQuery, (snapshot) => {
            snapshot.forEach((doc) => {
              const data = doc.data();
              const duration = calculateDuration(data.inDate, data.outDate);
              const payRate = cleanerRates[cleanerDoc.id] || 0;
              const totalPay = calculatePay(duration, payRate);

              entriesMap.set(doc.id, {
                id: doc.id,
                cleanerId: cleanerDoc.id,
                firstName: data.firstName,
                lastName: data.lastName,
                inDate: data.inDate,
                outDate: data.outDate || null,
                inLocation: data.inLocation,
                outLocation: data.outLocation || null,
                currentDuration: duration,
                payRate: payRate,
                totalPay: totalPay,
                isActive: !data.outTime
              });
            });

            const entriesList = Array.from(entriesMap.values());
            setTimesheetEntries(entriesList);
            updateSummary(entriesList);
          }, (error) => {
            console.error('Timesheet listener error:', error);
          });

          unsubscribes.push(unsubscribe);
        });

        return () => {
          unsubscribes.forEach(unsubscribe => unsubscribe());
        };
      } catch (error) {
        console.error('Error fetching timesheet entries:', error);
        setError('Failed to fetch timesheet entries');
      }
    };

    fetchTimesheetEntries();
  }, [orgId, selectedDate, cleanerRates]);

  const updateSummary = (entriesList) => {
    const activeEntries = entriesList.filter(entry => entry.isActive);
    const totalHours = entriesList.reduce((total, entry) => 
      total + parseFloat(entry.currentDuration), 0
    );
    
    // Calculate total pay for all entries
    const totalPay = entriesList.reduce((total, entry) => 
      total + parseFloat(entry.totalPay), 0
    );
    
    setSummary({
      totalActive: activeEntries.length,
      totalHoursToday: totalHours,
      averageShiftLength: entriesList.length > 0 
        ? totalHours / entriesList.length 
        : 0,
      totalShiftsToday: entriesList.length,
      totalPayToday: totalPay
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error" variant="h6">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!user) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography variant="h6">
          Please sign in to view the time clock management dashboard.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Time Clock Management</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <Button
            startIcon={<ChevronLeft />}
            onClick={handlePreviousDay}
            variant="outlined"
          >
            Previous Day
          </Button>
          <Typography variant="h6">
            {selectedDate.format('MMMM D, YYYY')}
          </Typography>
          <Button
            endIcon={<ChevronRight />}
            onClick={handleNextDay}
            variant="outlined"
          >
            Next Day
          </Button>
        </Stack>
      </Box>
  
      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={2.4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Timer sx={{ mr: 2, color: 'primary.main' }} />
                <Box>
                  <Typography variant="h6">{summary.totalActive}</Typography>
                  <Typography color="textSecondary">Currently Active</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AccessTime sx={{ mr: 2, color: 'primary.main' }} />
                <Box>
                  <Typography variant="h6">{summary.totalHoursToday.toFixed(2)}h</Typography>
                  <Typography color="textSecondary">Total Hours Today</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CalendarToday sx={{ mr: 2, color: 'primary.main' }} />
                <Box>
                  <Typography variant="h6">{summary.averageShiftLength.toFixed(2)}h</Typography>
                  <Typography color="textSecondary">Average Shift Length</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Timer sx={{ mr: 2, color: 'primary.main' }} />
                <Box>
                  <Typography variant="h6">{summary.totalShiftsToday}</Typography>
                  <Typography color="textSecondary">Total Shifts Today</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoney sx={{ mr: 2, color: 'primary.main' }} />
                <Box>
                  <Typography variant="h6">${summary.totalPayToday.toFixed(2)}</Typography>
                  <Typography color="textSecondary">Total Pay Today</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  
      {/* Timesheet Entries Table */}
      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cleaner</TableCell>
              <TableCell>Clock In Time</TableCell>
              <TableCell>Clock In Location</TableCell>
              <TableCell>Clock Out Time</TableCell>
              <TableCell>Clock Out Location</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Pay Rate</TableCell>
              <TableCell>Total Pay</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheetEntries
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell>
                    <Typography>{`${entry.firstName} ${entry.lastName}`}</Typography>
                  </TableCell>
                  <TableCell>
                    {moment(entry.inDate.toDate()).format('h:mm A')}
                  </TableCell>
                  <TableCell>
                    {entry.inLocation && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2">
                          {`${entry.inLocation.latitude.toFixed(4)}, ${entry.inLocation.longitude.toFixed(4)}`}
                        </Typography>
                        <Tooltip title="View Clock In Location">
                          <IconButton
                            size="small"
                            color="success"
                            onClick={() => {
                              console.log('Clock In Data:', {
                                inLocation: entry.inLocation,
                                outLocation: null,
                                cleanerName: `${entry.firstName} ${entry.lastName}`,
                                inDate: entry.inDate
                              });
                              setSelectedLocation({
                                inLocation: entry.inLocation,
                                outLocation: null,
                                cleanerName: `${entry.firstName} ${entry.lastName}`,
                                inDate: entry.inDate
                              });
                            }}
                          >
                            <LocationOn />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {entry.outDate ? moment(entry.outDate.toDate()).format('h:mm A') : '---'}
                  </TableCell>
                  <TableCell>
                    {entry.outLocation && (
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography variant="body2">
                          {`${entry.outLocation.latitude.toFixed(4)}, ${entry.outLocation.longitude.toFixed(4)}`}
                        </Typography>
                        <Tooltip title="View Clock Out Location">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => {
                              console.log('Clock Out Data:', {
                                inLocation: null,
                                outLocation: entry.outLocation,
                                cleanerName: `${entry.firstName} ${entry.lastName}`,
                                outDate: entry.outDate
                              });
                              setSelectedLocation({
                                inLocation: null,
                                outLocation: entry.outLocation,
                                cleanerName: `${entry.firstName} ${entry.lastName}`,
                                outDate: entry.outDate
                              });
                            }}
                          >
                            <LocationOn />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={`${entry.currentDuration}h`}
                      color="primary"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    ${(cleanerRates[entry.cleanerId] || 0).toFixed(2)}/hr
                  </TableCell>
                  <TableCell>
                    ${entry.totalPay}
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={entry.outDate ? "Completed" : "Active"}
                      color={entry.outDate ? "default" : "success"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setSelectedEntry(entry)}
                    >
                      View Route
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={timesheetEntries.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
  
      {/* Show "No entries" message if needed */}
      {timesheetEntries.length === 0 && (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography color="textSecondary">
            No timesheet entries found for {selectedDate.format('MMMM D, YYYY')}
          </Typography>
        </Box>
      )}
  
      {/* Location Tracking Dashboard */}
      {selectedEntry && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Route History - {selectedEntry.firstName} {selectedEntry.lastName}
          </Typography>
          <LocationTrackingDashboard 
            timesheetEntry={{
              ...selectedEntry,
              orgId: orgId,
              cleanerId: selectedEntry.cleanerId,
              id: selectedEntry.id
            }} 
          />
        </Box>
      )}
  
      {/* Location Map Modal */}
      {selectedLocation && (
        <LocationMapModal
          open={Boolean(selectedLocation)}
          onClose={() => setSelectedLocation(null)}
          inLocation={selectedLocation.inLocation}
          outLocation={selectedLocation.outLocation}
          cleanerName={selectedLocation.cleanerName}
          inDate={selectedLocation.inDate}
          outDate={selectedLocation.outDate}
        />
      )}
    </Box>
  );
};

export default TimeClockManagement;