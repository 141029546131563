import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Grid
} from '@mui/material';
import styles from '../styles/SelectCleanerModal.module.css';

const SelectCleanersModal = ({
  open,
  handleClose,
  cleaners = [],
  selectedCleaners = [],
  setSelectedCleaners
}) => {
  const [localSelected, setLocalSelected] = useState([]);

  useEffect(() => {
    if (open) {
      setLocalSelected(selectedCleaners);
    }
  }, [open, selectedCleaners]);

  const getCleanerDisplayName = (cleaner) => {
    if (cleaner.firstName && cleaner.lastName) {
      return `${cleaner.firstName} ${cleaner.lastName}`;
    }
    if (cleaner.name) {
      return cleaner.name;
    }
    if (cleaner.displayName) {
      return cleaner.displayName;
    }
    return cleaner.email || 'Unnamed Cleaner';
  };

  const handleCleanerToggle = (cleaner) => {
    const isCurrentlySelected = localSelected.some(c => c.id === cleaner.id);
    let updatedSelection;
    
    if (isCurrentlySelected) {
      updatedSelection = localSelected.filter(c => c.id !== cleaner.id);
    } else {
      updatedSelection = [...localSelected, {
        id: cleaner.id,
        firstName: cleaner.firstName,
        lastName: cleaner.lastName,
        name: cleaner.name || `${cleaner.firstName} ${cleaner.lastName}`,
        email: cleaner.email,
        displayName: cleaner.displayName
      }];
    }
    
    setLocalSelected(updatedSelection);
  };

  const handleSaveAndClose = () => {
    setSelectedCleaners(localSelected);
    handleClose();
  };

  if (!open) return null;

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
    >
      <div className={styles.modalTitle}>
        Select Cleaners
      </div>

      <DialogContent className={styles.modalContent}>
        <div className={styles.formSection}>
          <Grid container spacing={2}>
            {cleaners.map(cleaner => {
              const isSelected = localSelected.some(c => c.id === cleaner.id);
              const displayName = getCleanerDisplayName(cleaner);
              
              return (
                <Grid item xs={12} key={cleaner.id}>
                  <div className={`${styles.cleanerItem} ${isSelected ? styles.selected : ''}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={() => handleCleanerToggle(cleaner)}
                          className={styles.checkbox}
                        />
                      }
                      label={
                        <Typography className={styles.cleanerName}>
                          {displayName}
                        </Typography>
                      }
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </DialogContent>

      <DialogActions className={styles.modalActions}>
        <Button 
          onClick={handleClose}
          variant="outlined"
          className={styles.cancelButton}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveAndClose}
          variant="contained"
          className={styles.submitButton}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectCleanersModal;