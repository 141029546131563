// /src/components/JobDetails.js

import React, { useEffect, useState } from 'react';
import { JOB_STATUSES, normalizeJobStatus, DEFAULT_STATUS_COLORS } from '../context/JobContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, getDoc, getDocs, collection, updateDoc, serverTimestamp } from 'firebase/firestore';
import { Snackbar, Alert, Button } from '@mui/material';
import { useTimezone } from '../context/TimeZoneContext';
import EnhancedPhotoViewer from './EnhancedPhotoViewer';
import AdminServiceAddOnsModal from './AdminServiceAddOnsModal';
import { Star } from 'lucide-react';
import styles from '../styles/JobDetails.module.css';
import JobEmailHistory from './JobEmailHistory';

const JobNotificationModal = ({ isOpen, onClose, onConfirm, status }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3 className={styles.modalTitle}>Send Status Update Notification?</h3>
        <p>Would you like to send an email notification to the client about this status change to "{status}"?</p>
        <div className={styles.buttonGroup}>
          <button 
            className={`${styles.button} ${styles.buttonSecondary}`}
            onClick={() => onConfirm(false)}
          >
            Skip Email
          </button>
          <button 
            className={`${styles.button} ${styles.buttonPrimary}`}
            onClick={() => onConfirm(true)}
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
};

const JobDetails = ({ 
  job, 
  getStatusDetails, 
  onReopenJob, 
  customJobStatuses = [],
  onStatusChange,
  onOpenEmailPreview,
  onUpdate,
  handleUpdateStatus,
}) => {
  const [cleaners, setCleaners] = useState([]);
  const [loadingCleaners, setLoadingCleaners] = useState(true);
  const [cleanerPayRates, setCleanerPayRates] = useState({});
  const [calculatedDuration, setCalculatedDuration] = useState(null);
  const [scheduledDuration, setScheduledDuration] = useState(null);
  const [efficiency, setEfficiency] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(job?.status || '');
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(!job);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);
  const { formatInOrgTz } = useTimezone();
  const [serviceAddOns, setServiceAddOns] = useState([]);
  const [isAddingServices, setIsAddingServices] = useState(false);
  const [showAddServicesModal, setShowAddServicesModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [expandedSections, setExpandedSections] = useState({
    rooms: false,
    notes: false,
    addOns: false,
    emails: false
  });

  // Combine default and custom statuses
  const allStatuses = [
    // Default statuses with their colors
    {
      value: JOB_STATUSES.PENDING,
      label: JOB_STATUSES.PENDING,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.PENDING],
      isDefault: true
    },
    {
      value: JOB_STATUSES.IN_PROGRESS,
      label: JOB_STATUSES.IN_PROGRESS,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.IN_PROGRESS],
      isDefault: true
    },
    {
      value: JOB_STATUSES.COMPLETED,
      label: JOB_STATUSES.COMPLETED,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.COMPLETED],
      isDefault: true
    },
    {
      value: JOB_STATUSES.CANCELLED,
      label: JOB_STATUSES.CANCELLED,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.CANCELLED],
      isDefault: true
    },
    // Custom statuses
    ...customJobStatuses.map(status => ({
      value: status.name,
      label: status.name,
      color: status.color,
      isDefault: false
    }))
  ];

  const fetchCleanerData = async () => {
    if (!job?.cleaners || !Array.isArray(job.cleaners) || !job?.organizationId) {
      setLoadingCleaners(false);
      return;
    }
  
    try {
      const db = getFirestore();
      const cleanersCollection = collection(db, 'organizations', job.organizationId, 'cleaners');
  
      // Filter out any non-string cleaner IDs
      const validCleanerIds = job.cleaners.filter(id => typeof id === 'string');
  
      // Use Promise.all to fetch multiple docs in parallel
      const cleanerPromises = validCleanerIds.map(async (cleanerId) => {
        try {
          const cleanerRef = doc(cleanersCollection, cleanerId);
          const cleanerSnap = await getDoc(cleanerRef);
  
          if (cleanerSnap.exists()) {
            const data = cleanerSnap.data();
            return {
              id: cleanerSnap.id,
              name: `${data.firstName || ''} ${data.lastName || ''}`.trim(),
              email: data.email,
              ...data
            };
          }
        } catch (error) {
          console.error(`Error fetching cleaner ${cleanerId}:`, error);
        }
        return null;
      });
  
      const cleanerDocs = await Promise.all(cleanerPromises);
      // Filter out any nulls (in case a doc doesn't exist or there was an error)
      const validCleaners = cleanerDocs.filter((c) => c !== null);
  
      setCleaners(validCleaners);
    } catch (error) {
      console.error('Error fetching cleaner data:', error);
    } finally {
      setLoadingCleaners(false);
    }
  };

  useEffect(() => {
    if (job) {
      fetchCleanerData();
    }
  }, [job]);

  useEffect(() => {
    if (job?.status) {
      // Normalize the status to ensure consistent casing
      const normalizedStatus = normalizeJobStatus(job.status);
      setSelectedStatus(normalizedStatus);
    }
  }, [job?.status]);

  const formatDuration = (duration) => {
    const durationInMs = duration < 1000 ? duration * 60 * 60 * 1000 : duration;
    
    const totalSeconds = Math.floor(durationInMs / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    // Convert to decimal hours
    const decimalHours = hours + (minutes / 60) + (seconds / 3600);
    return `${decimalHours.toFixed(2)} hrs`;
  };

  const calculateEfficiency = (scheduledMs, actualMs) => {
    const efficiency = (scheduledMs / actualMs) * 100;
    return Math.round(efficiency * 100) / 100; // Round to 2 decimal places
  };

  const getEfficiencyColor = (rating) => {
    if (rating >= 100) return '#4caf50'; // Green for 100% or better
    if (rating >= 90) return '#ff9800';  // Orange for 90-99%
    return '#f44336'; // Red for below 90%
  };

  const formatDateTime = (timeValue) => {
    if (!timeValue) return null;
    
    // If it's a Firestore Timestamp
    if (timeValue.toDate && typeof timeValue.toDate === 'function') {
      return timeValue.toDate();
    }
    
    // If it's already a Date object
    if (timeValue instanceof Date) {
      return timeValue;
    }
    
    // If it's a time string like "08:30 AM"
    if (typeof timeValue === 'string') {
      // Get today's date and combine with the time
      const [time, meridiem] = timeValue.split(' ');
      const [hours, minutes] = time.split(':');
      const date = new Date();
      
      // Convert to 24-hour format if needed
      let hour = parseInt(hours);
      if (meridiem === 'PM' && hour !== 12) hour += 12;
      if (meridiem === 'AM' && hour === 12) hour = 0;
      
      date.setHours(hour);
      date.setMinutes(parseInt(minutes));
      date.setSeconds(0);
      date.setMilliseconds(0);
      
      return date;
    }

    // If it's a number (timestamp)
    if (typeof timeValue === 'number') {
      return new Date(timeValue);
    }
    
    return null;
  };

  const formatTimeForDisplay = (timeValue) => {
    if (!timeValue) return 'Not set';
    
    try {
      const date = formatDateTime(timeValue);
      if (!date) return 'Invalid date';
      const formatted = formatInOrgTz(date, 'LLLL');
      return formatted;
    } catch (err) {
      console.error('Error formatting time for display:', err, timeValue);
      return 'Invalid date';
    }
  };

  useEffect(() => {
    setCalculatedDuration(null);
    setScheduledDuration(null);
    setEfficiency(null);
    
    if (job.appointmentDate && job.scheduledEndTime) {
      const start = formatDateTime(job.appointmentDate);
      const end = formatDateTime(job.scheduledEndTime);
      if (start && end) {
        const scheduledDur = end.getTime() - start.getTime();
        setScheduledDuration(scheduledDur);
      }
    }
    
    // Updated to use actualStartTime and actualEndTime
    if (normalizeJobStatus(job.status) === JOB_STATUSES.COMPLETED && 
        job.startTime && job.endTime) {
      const start = formatDateTime(job.startTime);
      const end = formatDateTime(job.endTime);
      
      if (start && end) {
        const actualDur = end.getTime() - start.getTime();
        setCalculatedDuration(actualDur);
  
        if (scheduledDuration && actualDur) {
          const efficiencyValue = calculateEfficiency(scheduledDuration, actualDur);
          setEfficiency(efficiencyValue);
        }
      }
    }
  }, [job, scheduledDuration])

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSaveStatus = async () => {
    if (!job?.id || selectedStatus === job.status) return;
    
    setIsSaving(true);
    setError(null);

    try {
      await handleUpdateStatus(job.id, selectedStatus);
      setNotificationModalOpen(true);
      
      if (onStatusChange) {
        onStatusChange(selectedStatus);
      }
    } catch (error) {
      setError('Failed to update status. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleSection = (section) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

    // Calculate total addon cost
    const calculateAddonTotal = () => {
      if (!job.addOns || job.addOns.length === 0) return 0;
      return job.addOns.reduce((total, addon) => {
        const quantity = addon.quantity || 1;
        const price = addon.price || 0;
        return total + (price * quantity);
      }, 0);
    };
  
    const addonTotal = calculateAddonTotal();
    const baseServiceCost = job.serviceCost - addonTotal;

  const handleNotificationConfirm = (sendEmail) => {
    setNotificationModalOpen(false);
    if (sendEmail && onOpenEmailPreview) {
      onOpenEmailPreview();
    }
  };

  // Render ratings component
  const RatingDisplay = ({ value, label }) => (
    <div className="flex items-center gap-2 mb-2">
      <span className="text-sm font-medium">{label}:</span>
      <div className="flex items-center">
        {[1, 2, 3, 4, 5].map((star) => (
          <Star
            key={star}
            size={16}
            className={star <= value ? "fill-yellow-400 text-yellow-400" : "text-gray-300"}
          />
        ))}
      </div>
      <span className="text-sm ml-2">({value}/5)</span>
    </div>
  );

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity
    });
  };

  const fetchServiceAddOns = async () => {
    if (!job?.organizationId) return;
    
    try {
      const db = getFirestore();
      const orgRef = doc(db, 'organizations', job.organizationId);
      const orgSnap = await getDoc(orgRef);
      
      if (orgSnap.exists()) {
        const orgData = orgSnap.data();
        const addOns = orgData.clientPortalSettings?.serviceAddOns || [];
        const activeAddOns = addOns.filter(addOn => addOn.active);
        setServiceAddOns(activeAddOns);
      }
    } catch (error) {
      console.error('Error fetching service add-ons:', error);
      showSnackbar('Failed to load available services. Please try again.', 'error');
    }
  };
  
  const handleAddServices = async ({ selectedAddOns, notes, totalCost }) => {
    if (!job?.id || !job?.organizationId) return;
    
    setIsAddingServices(true);
    try {
      const db = getFirestore();
      const jobRef = doc(db, 'organizations', job.organizationId, 'jobs', job.id);
      
      const existingAddOns = job.addOns || [];
      const updatedAddOns = [...existingAddOns, ...selectedAddOns];
      
      const existingNotes = job.addOnNotes || '';
      const updatedNotes = existingNotes 
        ? `${existingNotes}\n\n${notes}`
        : notes;
      
      await updateDoc(jobRef, {
        addOns: updatedAddOns,
        addOnNotes: updatedNotes,
        serviceCost: totalCost,
        updatedAt: serverTimestamp()
      });
      
      showSnackbar('Services added successfully.');
      setShowAddServicesModal(false);
      
      // Call onUpdate if it exists
      if (onUpdate) {
        onUpdate();
      }
    } catch (error) {
      console.error('Error adding services:', error);
      showSnackbar('Failed to add services. Please try again.', 'error');
    } finally {
      setIsAddingServices(false);
    }
  };

  const getServiceCostLabel = () => {
    if (!job) return 'Service Cost';
    
    const type = job.pricingType === 'hourly' ? 'Hourly' : 'Flat';
    const per = job.pricingPer === 'cleaner' ? 'per Cleaner' : 'per Job';
    return `${type} Rate (${per})`;
  };

  // Helper function to calculate and format total cost
  const getTotalCostDisplay = () => {
    if (!job) return '$0.00';
    
    const baseCost = parseFloat(job.serviceCost) || 0;
    const multiplier = job.pricingPer === 'cleaner' ? (job.numberOfCleaners || 1) : 1;
    const totalCost = baseCost * multiplier;
    
    return `$${totalCost.toFixed(2)}`;
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({
      ...prev,
      open: false
    }));
  };
  
  // Add this useEffect to fetch service add-ons when component mounts
  useEffect(() => {
    if (job?.organizationId) {
      fetchServiceAddOns();
    }
  }, [job?.organizationId]);

  // Add function to fetch cleaner pay rates
  const fetchCleanerPayRates = async () => {
    if (!job?.cleanersActualTimes) return;
    
    try {
      const db = getFirestore();
      const rates = {};
      for (const cleaner of job.cleanersActualTimes) {
        const cleanerRef = doc(db, 'organizations', job.organizationId, 'cleaners', cleaner.cleanerId);
        const cleanerDoc = await getDoc(cleanerRef);
        if (cleanerDoc.exists()) {
          rates[cleaner.cleanerId] = parseFloat(cleanerDoc.data().payRate) || 0;
        }
      }
      setCleanerPayRates(rates);
    } catch (error) {
      console.error('Error fetching cleaner pay rates:', error);
    }
  };

  // Add helper function for duration calculation
  const calculateDuration = (startTime, endTime) => {
    if (!startTime || !endTime) return 0;
    const start = startTime.toDate ? startTime.toDate() : new Date(startTime);
    const end = endTime.toDate ? endTime.toDate() : new Date(endTime);
    return (end.getTime() - start.getTime()) / (1000 * 60 * 60); // Convert to hours
  };

  // Add helper function for labor cost calculation
  const calculateLaborCost = (duration, cleanerId) => {
    const payRate = cleanerPayRates[cleanerId] || 0;
    return duration * payRate;
  };

  useEffect(() => {
    if (job) {
      fetchCleanerPayRates();
    }
  }, [job]);

  if (isLoading) {
    return <div className={styles.loading}>Loading...</div>;
  }

  // Get status details for styling
  const status = normalizeJobStatus(job.status);
  const statusConfig = getStatusDetails(status);

  return (
    <div className={styles.jobDetails}>
      <h2 className={styles.title}>Job Details</h2>
      {job.initialClean && (
          <div className={styles.section}>
            <div className={styles.initialCleanBadge}>
              Initial Clean
            </div>
          </div>
        )}
      <div className={styles.divider} />

      <div className={styles.grid}>
        {/* Customer & Address Section */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Customer</h3>
          <p>{job.customerName}</p>
        </div>
        
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Address</h3>
          <p>{job.address}</p>
        </div>

        {/* Scheduled Times Section */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Scheduled Start</h3>
          <p>{formatTimeForDisplay(job.appointmentDate)}</p>
        </div>

        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Scheduled End</h3>
          <p>{formatTimeForDisplay(job.scheduledEndTime)}</p>
        </div>

        {/* Duration & Service Details */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Scheduled Duration</h3>
          <p>{scheduledDuration ? formatDuration(scheduledDuration) : 'N/A'}</p>
        </div>

        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Service Type</h3>
          <p>{job.serviceType === 'one-time' ? 'One Time Service' : 'Recurring Service'}</p>
        </div>

        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>{getServiceCostLabel()}</h3>
          <div className={styles.costBreakdown}>
            <p>Base Rate: ${Number(job.serviceCost || 0).toFixed(2)}</p>
            {job.pricingPer === 'cleaner' && (
              <p>Number of Cleaners: {job.numberOfCleaners || 1}</p>
            )}
            <p className={styles.totalCost}>Total: {getTotalCostDisplay()}</p>
          </div>
        </div>

        {/* Status Section */}
        <div className={styles.section}>
          <h3 className={styles.sectionTitle}>Status</h3>
          <div className={styles.statusContainer}>
            <select
              value={selectedStatus}
              onChange={handleStatusChange}
              className={styles.select}
            >
              {allStatuses.map(status => (
                <option key={status.value} value={status.value}>
                  {status.label} {status.isDefault ? '(Default)' : ''}
                </option>
              ))}
            </select>
            
            <button
              className={`${styles.button} ${styles.buttonPrimary}`}
              onClick={handleSaveStatus}
              disabled={isSaving || selectedStatus === job?.status}
            >
              {isSaving ? 'Saving...' : 'Save Status'}
            </button>
            
            {error && <p className={styles.error}>{error}</p>}

            {selectedStatus === JOB_STATUSES.COMPLETED && (
              <div className={styles.reopenContainer}>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const confirmed = window.confirm(
                          'Are you sure you want to reopen this job? This will clear the completion status and actual times.'
                        );
                        if (confirmed && onReopenJob) {
                          onReopenJob();
                        }
                        e.target.checked = false;
                      }
                    }}
                  />
                  <span>Reopen Job?</span>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Cleaners Section */}
      <div className={styles.cleanersSelection}>
        <h3 className={styles.sectionTitle}>Cleaners</h3>
        <div className={styles.cleanersList}>
          {job.cleanerDetails ? (
            job.cleanerDetails.map((cleaner) => (
              <div key={cleaner.id} className={styles.cleanerItem}>
                {cleaner.name}
              </div>
            ))
          ) : (
            <p className={styles.noCleaners}>No cleaners assigned</p>
          )}
        </div>
      </div>

      <div className={styles.divider} />

      {/* Rooms Section */}
      {job.rooms && job.rooms.length > 0 && (
        <div className={styles.expandableSection}>
          <div 
            className={styles.expandableHeader}
            onClick={() => toggleSection('rooms')}
          >
            <h3 className={styles.sectionTitle}>Rooms</h3>
            <span className={styles.expandIcon}>
              {expandedSections.rooms ? '−' : '+'}
            </span>
          </div>
          
          {expandedSections.rooms && (
            <div className={styles.expandableContent}>
              {job.rooms.map((room, index) => (
                <div key={index} className={styles.roomItem}>
                  <div className={styles.roomHeader}>
                    <h4>{room.name} ({room.quantity})</h4>
                  </div>
                  {room.tasks?.length > 0 && (
                    <div className={styles.taskList}>
                      {room.tasks.map((task, taskIndex) => (
                        <div 
                          key={taskIndex} 
                          className={`${styles.taskItem} ${task.completed ? styles.taskCompleted : ''}`}
                        >
                          • {task.description}
                          <span className={styles.taskStatus}>
                            {task.completed ? 'Done' : 'Not Done'}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Add-ons Section */}

      <div className={styles.addonSection}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setShowAddServicesModal(true)}
        >
          Add Services to Job
        </Button>
      </div>
      {(job.addOns?.length > 0 || job.otherAddOnRequest) && (
        <div className={styles.expandableSection}>
          <div 
            className={styles.expandableHeader}
            onClick={() => toggleSection('addons')}
          >
            <h3 className={styles.sectionTitle}>Additional Services</h3>
            <span className={styles.expandIcon}>
              {expandedSections.addons ? '−' : '+'}
            </span>
          </div>
          
          {expandedSections.addons && (
            <div className={styles.expandableContent}>
              {job.addOns?.length > 0 && (
                <div className={styles.addonsList}>
                  {job.addOns.map((addon, index) => (
                    <div key={index} className={styles.addonItem}>
                      <div className={styles.addonHeader}>
                        <h4>{addon.name}</h4>
                        <div className={styles.addonDetails}>
                          <span className={styles.quantity}>
                            Quantity: {addon.quantity || 1}
                          </span>
                          <span className={styles.price}>
                            ${((addon.price || 0) * (addon.quantity || 1)).toFixed(2)}
                          </span>
                        </div>
                      </div>
                      {addon.description && (
                        <p className={styles.addonDescription}>
                          {addon.description}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
        
              {job.otherAddOnRequest && (
                <div className={styles.customAddon}>
                  <h4 className={styles.customAddonTitle}>Custom Service Request</h4>
                  <p className={styles.customAddonDescription}>
                    {job.otherAddOnRequest}
                  </p>
                </div>
              )}

              {job.addOnNotes && (
                <div className={styles.addonNotes}>
                  <h4 className={styles.notesTitle}>Additional Notes</h4>
                  <p className={styles.notesText}>
                    {job.addOnNotes}
                  </p>
                </div>
              )}

              <div className={styles.divider} />
              <div className={styles.addonSummary}>
                <div className={styles.costBreakdown}>
                  <span>Base Service Cost:</span> <span>${baseServiceCost.toFixed(2)}</span>
                  <span>Additional Services:</span> <span>${addonTotal.toFixed(2)}</span>
                  <span className={styles.totalCost}>Total:</span> <span>${Number(job.serviceCost || 0).toFixed(2)}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Notes Section */}
      {(job.notes || job.importantNotes) && (
        <div className={styles.expandableSection}>
          <div 
            className={styles.expandableHeader}
            onClick={() => toggleSection('notes')}
          >
            <h3 className={styles.sectionTitle}>Notes</h3>
            <span className={styles.expandIcon}>
              {expandedSections.notes ? '−' : '+'}
            </span>
          </div>
          
          {expandedSections.notes && (
            <div className={styles.expandableContent}>
              {job.notes && (
                <div className={styles.notesSection}>
                  <h4 className={styles.notesTitle}>Notes</h4>
                  <p className={styles.notesText}>
                    {job.notes.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              )}
              
              {job.importantNotes && (
                <div className={styles.notesSection}>
                  <h4 className={styles.notesTitle}>Important Notes</h4>
                  <p className={styles.notesText}>
                    {job.importantNotes.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <div className={styles.divider} />

      {/* Email History Section */}
      <div className={styles.expandableSection}>
        <div 
          className={styles.expandableHeader}
          onClick={() => toggleSection('emails')}
        >
          <h3 className={styles.sectionTitle}>Email History</h3>
          <span className={styles.expandIcon}>
            {expandedSections.emails ? '−' : '+'}
          </span>
        </div>
        
        {expandedSections.emails && (
          <div className={styles.expandableContent}>
            <JobEmailHistory 
              jobId={job.id} 
              organizationId={job.organizationId} 
            />
          </div>
        )}
      </div>

      <div className={styles.divider} />

      {/* Completion Details Section */}
      {selectedStatus === JOB_STATUSES.COMPLETED && (
        <div className={styles.section}>
          <h3 className={styles.completionTitle}>Completion Details</h3>
          <div className={styles.completionGrid}>
            {/* Summary Section */}
            <div className={styles.completionSummary}>
              <h4 className={styles.completionLabel}>Job Summary</h4>
              <div className={styles.summaryDetails}>
                <div className={styles.summaryRow}>
                  <span>Total Duration:</span>
                  <span>
                    {formatDuration(
                      job.cleanersActualTimes?.reduce((total, ct) => {
                        return total + calculateDuration(ct.actualStartTime, ct.actualEndTime);
                      }, 0) * 1000 * 60 * 60 || 0
                    )}
                  </span>
                </div>
                <div className={styles.summaryRow}>
                  <span>Total Labor Cost:</span>
                  <span>
                    ${job.cleanersActualTimes?.reduce((total, ct) => {
                      const duration = calculateDuration(ct.actualStartTime, ct.actualEndTime);
                      return total + calculateLaborCost(duration, ct.cleanerId);
                    }, 0).toFixed(2) || '0.00'}
                  </span>
                </div>
                <div className={styles.summaryRow}>
                  <span>Service Revenue:</span>
                  <span>${(job.serviceCost || 0).toFixed(2)}</span>
                </div>
                <div className={styles.summaryRow}>
                  <span>Profit Amount:</span>
                  <span>
                    ${(job.serviceCost - (job.cleanersActualTimes?.reduce((total, ct) => {
                      const duration = calculateDuration(ct.actualStartTime, ct.actualEndTime);
                      return total + calculateLaborCost(duration, ct.cleanerId);
                    }, 0) || 0)).toFixed(2)}
                  </span>
                </div>
                <div className={styles.summaryRow}>
                  <span>Profit Margin:</span>
                  <span>
                    {job.serviceCost && job.cleanersActualTimes ? 
                      `${((job.serviceCost - job.cleanersActualTimes.reduce((total, ct) => {
                        const duration = calculateDuration(ct.actualStartTime, ct.actualEndTime);
                        return total + calculateLaborCost(duration, ct.cleanerId);
                      }, 0)) / job.serviceCost * 100).toFixed(2)}%` : 
                      'N/A'}
                  </span>
                </div>
                <div className={styles.summaryRow}>
                    <span>Total Efficiency:</span>
                    <span>
                    {(() => {
                      const totalActualDuration = job.cleanersActualTimes?.reduce((total, ct) => {
                        return total + calculateDuration(ct.actualStartTime, ct.actualEndTime);
                      }, 0) || 0;
                      const avgCleanerDuration = totalActualDuration / (job.cleanersActualTimes?.length || 1);
                      const scheduledDuration = job.scheduledDuration / (1000 * 60 * 60); // Convert to hours
                      const totalEfficiency = (scheduledDuration / avgCleanerDuration * 100).toFixed(2);
                      return <div className={styles.efficiencyChip} style={{ backgroundColor: getEfficiencyColor(totalEfficiency) }}>{totalEfficiency}%</div>;
                    })()}
                  </span>
                </div>
              </div>
            </div>

            {/* Cleaners Section */}
            <div className={styles.cleanersSection}>
              {job.cleanersActualTimes?.map((cleanerTime) => {
                const duration = calculateDuration(cleanerTime.actualStartTime, cleanerTime.actualEndTime);
                const laborCost = calculateLaborCost(duration, cleanerTime.cleanerId);
                const efficiency = calculateEfficiency(
                  job.scheduledDuration / (job.cleanerActualTimes?.duration || 1),
                  duration * (1000 * 60 * 60)
                );

                return (
                  <div key={cleanerTime.cleanerId} className={styles.cleanerCompletionCard}>
                    <h4 className={styles.cleanerName}>{cleanerTime.cleanerName}</h4>
                    
                    <div className={styles.cleanerTimeDetails}>
                      <div>
                        <span>Duration:</span>
                        <span>{duration.toFixed(2)} hrs</span>
                      </div>
                      <div>
                        <span>Labor Cost:</span>
                        <span>${laborCost.toFixed(2)}</span>
                      </div>
                      <div>
                        <span>Efficiency:</span>
                        <div 
                          className={styles.efficiencyChip}
                          style={{ backgroundColor: getEfficiencyColor(efficiency) }}
                        >
                          {efficiency}%
                        </div>
                      </div>
                    </div>

                    {/* Cleaner's Notes */}
                    {cleanerTime.cleanerNotes && (
                      <div className={styles.cleanerNotes}>
                        <h5>Notes:</h5>
                        <p>{cleanerTime.cleanerNotes}</p>
                      </div>
                    )}

                    {/* Ratings Display */}
                    <div className={styles.ratingsContainer}>
                      <RatingDisplay
                        value={cleanerTime.jobDifficultyRating}
                        label="Job Difficulty"
                      />
                      <RatingDisplay
                        value={cleanerTime.jobCleanlinessRating}
                        label="Cleanliness Rating"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

        {/* Completion Photos */}
        <div className={styles.completionNotes}>
          <div className="w-full">
            <EnhancedPhotoViewer job={job} />
          </div>
        </div>

      <AdminServiceAddOnsModal
        open={showAddServicesModal}
        onClose={() => setShowAddServicesModal(false)}
        serviceAddOns={serviceAddOns}
        currentServiceCost={job.serviceCost}
        onSubmit={handleAddServices}
        loading={isAddingServices}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <JobNotificationModal
        isOpen={notificationModalOpen}
        onClose={() => setNotificationModalOpen(false)}
        onConfirm={handleNotificationConfirm}
        status={selectedStatus}
      />
    </div>
  );
};

export default JobDetails;