// src/components/CreateJobForm.js

import React, { useState, useEffect } from 'react';
import { Routes, Route, NavLink, useSearchParams, useLocation, Navigate } from 'react-router-dom';
import {
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  writeBatch,
  deleteDoc,
  setDoc
} from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged, getAuth} from 'firebase/auth';
import { Plus, Edit2, Trash2, User, MapPin, Clock, DollarSign, Home, FileText, Users, ClipboardList, Briefcase } from 'lucide-react';
import { rrulestr } from 'rrule';
import CreateRoomModal from './CreateRoomModal';
import EditRoomModal from './EditRoomModal';
import AddRoomsModal from './AddRoomsModal';
import CreateCustomerModal from './CreateCustomerModal';
import JobsOverview from './JobsOverview';
import CustomerManagement from './CustomerManagement';
import CustomerProfileManagement from './CustomerProfileManagement';
import UploadRoomsTasksCSV from './UploadRoomsTasksCSV';
import RecurrenceSelect from './RecurrenceSelect';
import styles from '../styles/CreateJobForm.module.css';
import CleanersReporting from './CleanersReporting';
import { useTimezone } from '../context/TimeZoneContext';
import SelectCleanersModal from './SelectCleanersModal';
import CleanerProfile from './CleanerProfile';
import { fetchGeocodeData } from '../utils/api';
import { notificationsApi } from '../utils/api'; 
import EmailPreviewModal from './EmailPreviewModal';
import RoomsTasksManagement from './RoomsTasksManagement';

const JobNotificationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h3 className={styles.modalTitle}>Send Job Notification?</h3>
        <p>Would you like to send an email notification to the client about this new job?</p>
        <div className={styles.buttonGroup}>
          <button 
            className={`${styles.button} ${styles.buttonSecondary}`}
            onClick={() => {
              onConfirm(false);
              onClose();
            }}
          >
            Skip Email
          </button>
          <button 
            className={`${styles.button} ${styles.buttonPrimary}`}
            onClick={() => {
              onConfirm(true);
              onClose();
            }}
          >
            Send Email
          </button>
        </div>
      </div>
    </div>
  );
};

// Reusable components
const FormSection = ({ title, icon, children, highlight = false }) => (
  <div className={`${styles.formSection} ${highlight ? styles.highlight : ''}`}>
    <div className={styles.sectionHeader}>
      {icon}
      <h3 className={styles.sectionTitle}>{title}</h3>
    </div>
    {children}
  </div>
);

const Input = ({ label, type = "text", required = false, ...props }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
      {label}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
    <input
      type={type}
      className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded-md 
                 bg-white dark:bg-gray-700 
                 text-gray-900 dark:text-gray-100
                 focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                 placeholder-gray-400 dark:placeholder-gray-500"
      {...props}
    />
  </div>
);

const TabButton = ({ to, children }) => (
  <NavLink
    to={to}
    className={({ isActive }) => `
      px-4 py-2 rounded-md transition-colors duration-200
      flex items-center gap-2 text-sm font-medium
      ${isActive 
        ? 'bg-blue-500 text-white' 
        : 'text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'}
    `}
  >
    {children}
  </NavLink>
);

const CreateJobForm = () => {
  // Core state
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [address, setAddress] = useState('');
  const [notes, setNotes] = useState('');
  const [importantNotes, setImportantNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('');
  const [user, setUser] = useState(null);
  const { convertFromOrgTz, timezone } = useTimezone();
  const [orgId, setOrgId] = useState(null);

  // Room management state
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [openCreateRoomModal, setOpenCreateRoomModal] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingRoom, setEditingRoom] = useState(null);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [roomAccordionOpen, setRoomAccordionOpen] = useState({});

  // Cleaner management state
  const [cleaners, setCleaners] = useState([]);
  const [selectedCleaners, setSelectedCleaners] = useState([]);
  const [openAddCleanerModal, setOpenAddCleanerModal] = useState(false);
  const [openSelectCleanersModal, setOpenSelectCleanersModal] = useState(false);
  
  // Customer management state
  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);
  
  // Form state
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentEndDate, setAppointmentEndDate] = useState('');
  const [accordionOpen, setAccordionOpen] = useState({});
  const [recurring, setRecurring] = useState(false);
  const [recurrenceRule, setRecurrenceRule] = useState('');
  const [serviceType, setServiceType] = useState('one-time'); // 'one-time' or 'recurring'
  const [jobType, setJobType] = useState('residential'); // 'residential' or 'commercial'
  const [serviceCost, setServiceCost] = useState('');
  const [totalSize, setTotalSize] = useState('');
  
  // Autocomplete state
  const [customerInput, setCustomerInput] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [cleanerInput, setCleanerInput] = useState('');

  // Show Email Modal
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [jobDataForEmail, setJobDataForEmail] = useState(null);
  const [organizationInfo, setOrganizationInfo] = useState(null);

  // Show new calendar version
  const [searchParams] = useSearchParams();
  const newVersion = searchParams.get('new-version') === 'true';

  // Additional state for recurring services
  const [hasInitialClean, setHasInitialClean] = useState(false);
  const [initialCleanPricingType, setInitialCleanPricingType] = useState('hourly');
  const [initialCleanCleaners, setInitialCleanCleaners] = useState('1');
  const [initialCleanCost, setInitialCleanCost] = useState('');
  const [pricingType, setPricingType] = useState('hourly');
  const [numberOfCleaners, setNumberOfCleaners] = useState('1');

  // Add new state for initial clean cleaners
  const [initialCleanSelectedCleaners, setInitialCleanSelectedCleaners] = useState([]);
  const [openInitialCleanSelectCleanersModal, setOpenInitialCleanSelectCleanersModal] = useState(false);

  // Add new state variables at the top
  const [pricingPer, setPricingPer] = useState('job'); // 'job' or 'cleaner'
  const [initialCleanPricingPer, setInitialCleanPricingPer] = useState('job'); // 'job' or 'cleaner'

  // Authentication effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserOrgId(currentUser.uid);
      } else {
        setLoading(false);
        setError('User not authenticated');
      }
    });

    return () => unsubscribe();
  }, []);

  const getToken = async () => {
    const auth = getAuth();
    return auth.currentUser?.getIdToken(true);
  };

  useEffect(() => {
    if (!orgId) return;
    const fetchOrgInfo = async () => {
      try {
        const orgRef = doc(db, 'organizations', orgId);
        const orgSnap = await getDoc(orgRef);
        if (orgSnap.exists()) {
          setOrganizationInfo(orgSnap.data());
        }
      } catch (err) {
        console.error('Failed to fetch org info:', err);
      }
    };
    fetchOrgInfo();
  }, [orgId]);

  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return end.getTime() - start.getTime();
  };

  // Data fetching functions
  const fetchUserOrgId = async (userId) => {
    try {
      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }
      const userOrgId = userDoc.data().orgId;
      setOrgId(userOrgId);
      fetchData(userOrgId);
    } catch (err) {
      console.error("Error fetching user's organization ID:", err);
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchData = async (organizationId) => {
    setLoading(true);
    setError(null);
    try {
  
      // Fetch customers
      const customersRef = collection(db, 'organizations', organizationId, 'customers');
      const customersSnapshot = await getDocs(customersRef);
      const customersList = customersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customersList);
  
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', organizationId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomTypesList = roomTypesSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setAvailableRooms(roomTypesList);
  
      // Fetch cleaners from users collection instead
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const cleanersList = usersSnapshot.docs
        .map(doc => ({ 
          id: doc.id,  // Make sure we're getting the id
          ...doc.data() 
        }))
        .filter(user => 
          user.orgId === organizationId && 
          user.role === 'cleaner' &&
          user.isActive !== false
        );
      setCleaners(cleanersList);
  
      await fetchEmailTemplates(organizationId);
    } catch (err) {
      console.error("Error in fetchData:", err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fetch email templates
  const fetchEmailTemplates = async (organizationId) => {
    try {
      if (!organizationId) {
        return;
      }
      
      const templatesRef = collection(db, 'organizations', organizationId, 'emailTemplates');
      const templatesSnap = await getDocs(templatesRef);
      const templates = templatesSnap.docs.map(doc => ({
        documentId: doc.id,
        ...doc.data()
      }));
      setEmailTemplates(templates);
    } catch (error) {
      console.error('Error fetching email templates:', error);
    }
  };

    // Add geocoding function
    const geocodeAddress = async (address) => {
      try {
        const response = await fetchGeocodeData(address);
        return {
          latitude: response.lat,
          longitude: response.lng,
          formattedAddress: response.formatted_address || address
        };
      } catch (error) {
        console.error('Geocoding error:', error);
        // Return null to handle the error gracefully in handleSubmit
        return null;
      }
    };

  // Event handlers
  const handleCustomerInputChange = (e) => {
    const value = e.target.value;
    setCustomerInput(value);
    if (value.length > 1) {
      const filtered = customers.filter(customer =>
        customer.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCustomers(filtered);
      setShowCustomerDropdown(true);
    } else {
      setShowCustomerDropdown(false);
    }
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setCustomerInput(customer.name);
    setAddress(customer.address || '');
    setShowCustomerDropdown(false);
  };

  const toggleAccordion = (index) => {
    setAccordionOpen(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };


// Task management
const handleTaskChange = (roomIndex, taskIndex, completed) => {
  setSelectedRooms(prevRooms => {
    const updatedRooms = [...prevRooms];
    if (updatedRooms[roomIndex] && updatedRooms[roomIndex].tasks) {
      updatedRooms[roomIndex].tasks[taskIndex].completed = completed;
    }
    return updatedRooms;
  });
};

// Room management

const fetchAvailableRooms = async () => {
  try {
    const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
    const roomTypesSnapshot = await getDocs(roomTypesRef);
    const roomTypesList = roomTypesSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .sort((a, b) => a.name.localeCompare(b.name));
    setAvailableRooms(roomTypesList);
  } catch (err) {
    console.error('Error fetching room types:', err);
  }
};
const handleCreateRoom = async (newRoom) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
    const docRef = await addDoc(roomTypesRef, newRoom);
    await fetchData(orgId);
    setSuccess("Room created successfully!");
    setOpenCreateRoomModal(false);
  } catch (error) {
    setError("Failed to create new room type");
  }
};

const handleEditRoomClick = (room) => {
  setEditingRoom(room);
  setIsEditModalOpen(true);
};

const handleCloseEditModal = () => {
  setIsEditModalOpen(false);
  setEditingRoom(null);
};

const handleEditRoom = async (updatedRoom) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', updatedRoom.id);
    await updateDoc(roomDocRef, {
      name: updatedRoom.name,
      tasks: updatedRoom.tasks,
      updatedAt: new Date()
    });
    await fetchData(orgId);
    setSuccess("Room updated successfully!");
    handleCloseEditModal();
  } catch (error) {
    setError("Failed to edit room type");
  }
};

const handleDeleteRoom = async (roomId) => {
  const confirmDelete = window.confirm("Are you sure you want to delete this room?");
  if (!confirmDelete) return;

  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    const roomDocRef = doc(db, 'organizations', orgId, 'roomTypes', roomId);
    await deleteDoc(roomDocRef);
    await fetchData(orgId);
    setSuccess("Room deleted successfully!");
  } catch (error) {
    setError("Failed to delete room type");
  }
};


  // Handle adding rooms from AddRoomsModal
  const handleAddRooms = (roomsToAdd) => {
    setSelectedRooms(prevRooms => {
      const updatedRooms = [...prevRooms];
      roomsToAdd.forEach(newRoom => {
        // Ensure tasks array exists and is properly formatted
        const formattedTasks = (newRoom.tasks || []).map(task => {
          if (typeof task === 'string') {
            return { description: task, completed: false };
          }
          // If it's already an object, ensure it has all required properties
          return {
            description: task.description || '',
            completed: task.completed || false,
            ...task // preserve any other properties
          };
        });
  
        const roomToAdd = {
          ...newRoom,
          tasks: formattedTasks,
          quantity: newRoom.quantity || 1
        };
        
        // Find existing room
        const existingIndex = updatedRooms.findIndex(room => room.name === newRoom.name);
        if (existingIndex !== -1) {
          // Merge existing room with new room data
          const existingRoom = updatedRooms[existingIndex];
          updatedRooms[existingIndex] = {
            ...existingRoom,
            ...roomToAdd,
            // Preserve existing tasks if new room has no tasks
            tasks: roomToAdd.tasks.length > 0 ? roomToAdd.tasks : existingRoom.tasks,
            // Preserve existing quantity if new room has none
            quantity: roomToAdd.quantity || existingRoom.quantity || 1
          };
        } else {
          updatedRooms.push(roomToAdd);
        }
      });
      
      return updatedRooms;
    });
  };

// Customer management
const handleCreateCustomer = async (newCustomer) => {
  try {
    if (!orgId) throw new Error("Organization ID not found");
    
    // Format customer data
    const customerData = {
      ...newCustomer,
      createdAt: new Date(),
      updatedAt: new Date(),
      accountStatus: 'active',
      // If addresses array exists, use it, otherwise create an empty array
      addresses: newCustomer.addresses || [],
      // Format address fields
      address: newCustomer.serviceAddress || '',
      serviceAddress: newCustomer.serviceAddress || '',
      billingAddress: newCustomer.billingAddress || newCustomer.serviceAddress || '',
      // Ensure we have a properly formatted name
      name: `${newCustomer.firstName} ${newCustomer.lastName}`.trim(),
      // Additional metadata
      userAccountId: user?.uid, // Add the creating user's ID if available
      organizationId: orgId
    };

    const customersRef = collection(db, 'organizations', orgId, 'customers');
    await addDoc(customersRef, customerData);
    
    // Refresh the customers list
    await fetchData(orgId);
    
    setSuccess("Customer created successfully!");
    setOpenCreateCustomerModal(false);
  } catch (error) {
    console.error('Error creating customer:', error);
    setError(error.message || "Failed to create new customer");
  }
};

// CSV handling
const handleCSVUpload = (roomsFromCSV) => {
  setSelectedRooms(prevRooms => [...prevRooms, ...roomsFromCSV]);
  setSuccess("Rooms imported from CSV successfully!");
};

const resetForm = () => {
  setSelectedCustomer(null);
  setCustomerInput('');
  setAddress('');
  setSelectedRooms([]);
  setNotes('');
  setImportantNotes('');
  setSelectedCleaners([]);
  setCleanerInput('');
  setAppointmentDate('');
  setAppointmentEndDate('');
  setServiceType('one-time');
  setRecurring(false);
  setRecurrenceRule('');
  setServiceCost('');
  setJobType('residential');
  setTotalSize('');
  setHasInitialClean(false);
  setInitialCleanPricingType('hourly');
  setInitialCleanCleaners('1');
  setInitialCleanCost('');
  setPricingType('hourly');
  setNumberOfCleaners('1');
};


const handleSubmit = async (event) => {
  event.preventDefault();
  setError(null);
  try {
    // Basic validation
    if (!selectedCustomer) throw new Error("Please select a customer");
    if (selectedCleaners.length === 0) throw new Error("Please assign at least one cleaner");
    if (!appointmentDate) throw new Error("Please set an appointment start date");
    if (!appointmentEndDate) throw new Error("Please set an appointment end date");
    if (!serviceCost) throw new Error("Please enter the service cost");
    if (new Date(appointmentEndDate) <= new Date(appointmentDate)) {
      throw new Error("End time must be after start time");
    }
    if (!orgId) throw new Error("Organization ID not found");
    
    // Validate recurrence for recurring jobs
    if (serviceType === 'recurring') {
      if (!recurrenceRule || recurrenceRule === '') {
        throw new Error("Please select a recurrence pattern for recurring jobs");
      }
      
      // Validate that the recurrence rule is properly formatted
      if (!recurrenceRule.startsWith('FREQ=')) {
        throw new Error("Invalid recurrence pattern format");
      }
    }

    // Generate a unique ID for recurring jobs - now we'll use this as the document ID
    const recurrenceGroupId = serviceType === 'recurring' ? `rec_${new Date().getTime()}` : null;

    // Calculate duration
    const duration = calculateDuration(appointmentDate, appointmentEndDate);

    // 2. references
    const customerRef = doc(db, 'organizations', orgId, 'customers', selectedCustomer.id);
    const customerSnap = await getDoc(customerRef);
    const customerData = customerSnap.exists() ? customerSnap.data() : {};
    const addresses = customerData.addresses || [];

    // 3. See if we already have this address stored
    //    Compare in a case-insensitive way or whatever logic you want
    let existingAddress = addresses.find(
      (addr) => addr.formattedAddress?.toLowerCase() === address.toLowerCase()
    );

    let locationData = null;

    if (existingAddress) {
      // If we already have it, we can skip geocode
      locationData = {
        latitude: existingAddress.latitude,
        longitude: existingAddress.longitude,
        formattedAddress: existingAddress.formattedAddress
      };
      
      existingAddress.lastUpdated = new Date();

    } else {
      // If address is new, we geocode
      try {
        locationData = await geocodeAddress(address);
      } catch (geocodeError) {
        console.error('Geocoding error:', geocodeError);
        // Continue without geocoding if it fails or handle the error
      }

      if (locationData) {
        // Add it to the addresses array
        const addressData = {
          formattedAddress: locationData.formattedAddress || address,
          latitude: locationData.latitude,
          longitude: locationData.longitude,
          lastUpdated: new Date()
        };
        addresses.push(addressData);

        // Update the customer doc
        await updateDoc(customerRef, {
          addresses,
          updatedAt: new Date()
        });
      }
    }

    // Add validation for initial clean cleaners
    if (serviceType === 'recurring' && hasInitialClean && initialCleanSelectedCleaners.length === 0) {
      throw new Error("Please select cleaners for the initial clean");
    }

    // Base job data with updated pricing information
    const baseJobData = {
      customerId: selectedCustomer.id,
      customerName: selectedCustomer.name,
      address,
      rooms: selectedRooms.map(room => ({
        ...room,
        tasks: room.tasks.map(task => ({
          description: typeof task === 'string' ? task : task.description,
          completed: false
        }))
      })),
      notes,
      importantNotes,
      status: 'Pending',
      createdAt: new Date(),
      updatedAt: new Date(),
      organizationId: orgId,
      cleaners: selectedCleaners.map((cleaner) => cleaner.id),
      cleanerDetails: selectedCleaners.map((cleaner) => ({
        id: cleaner.id,
        name:
          cleaner.firstName && cleaner.lastName
            ? `${cleaner.firstName} ${cleaner.lastName}`
            : cleaner.email,
        email: cleaner.email,
      })),
      serviceType,
      recurring: serviceType === 'recurring',
      recurrenceRule: serviceType === 'recurring' ? recurrenceRule : null,
      recurrenceGroupId,
      appointmentDate: convertFromOrgTz(appointmentDate).toDate(),
      scheduledEndTime: convertFromOrgTz(appointmentEndDate).toDate(),
      timezone: timezone,
      scheduledDuration: duration,
      pricingType,
      pricingPer,
      numberOfCleaners: parseInt(numberOfCleaners),
      serviceCost: parseFloat(serviceCost),
      pricePerHour: pricingType === 'hourly' ? parseFloat(serviceCost) : null,
      flatRate: pricingType === 'flat' ? parseFloat(serviceCost) : null,
      totalCost: pricingPer === 'cleaner' 
        ? parseFloat(serviceCost) * parseInt(numberOfCleaners)
        : parseFloat(serviceCost),
      totalSize: parseFloat(totalSize),
      jobType,
      latitude: locationData?.latitude,
      longitude: locationData?.longitude,
      formattedAddress: locationData?.formattedAddress || address,
      initialClean: false, // Default to false for all jobs
    };

    setJobDataForEmail({
      customerName: selectedCustomer?.name,
      customerEmail: selectedCustomer?.email,
      address,
      organizationId: orgId,
      cleaners: selectedCleaners.map((cleaner) => cleaner.id),
      cleanerDetails: selectedCleaners.map((cleaner) => ({
        id: cleaner.id,
        name:
          cleaner.firstName && cleaner.lastName
            ? `${cleaner.firstName} ${cleaner.lastName}`
            : cleaner.email,
        email: cleaner.email,
      })),
      appointmentDate: convertFromOrgTz(appointmentDate).toDate(),
      scheduledEndTime: convertFromOrgTz(appointmentEndDate).toDate(),
      serviceCost: parseFloat(serviceCost) || 0,
      serviceType,
      notes,
      importantNotes,
      organizationId: orgId,
      jobType,
    });

    // Reference to collections
    const jobsRef = collection(db, 'organizations', orgId, 'jobs');
    const recurringServicesRef = collection(db, 'organizations', orgId, 'recurringServices');

    if (!totalSize || totalSize <= 0) {
      throw new Error("Please enter a valid total size in square feet.");
    }

    if (serviceType === 'recurring') {
      if (!recurrenceRule) {
        throw new Error('Recurrence rule is empty');
      }
    
      const dtstart = convertFromOrgTz(appointmentDate).toDate();
      const dtstartStr = dtstart.toISOString().replace(/[-:]/g, '').split('.')[0] + 'Z';
      
    
      const rruleString = `DTSTART:${dtstartStr}\nRRULE:${recurrenceRule}`;
      
      try {
        const rule = rrulestr(rruleString);
        
        const dates = rule.between(
          dtstart,
          new Date(dtstart.getTime() + (180 * 24 * 60 * 60 * 1000)),
          true
        ).slice(0, 6);
    
        // First create recurring service
        const recurringServiceData = {
          ...baseJobData,
          recurringStatus: "Active",
          recurringStartDate: convertFromOrgTz(appointmentDate).toDate(),
          lastUpdated: new Date(),
          recurrenceRule: recurrenceRule,
          recurrenceGroupId,
          cleanerId: selectedCleaners[0]?.id,
          cleanerEmail: selectedCleaners[0]?.email,
          cleanerName: selectedCleaners[0]?.firstName && selectedCleaners[0]?.lastName ? 
            `${selectedCleaners[0].firstName} ${selectedCleaners[0].lastName}` : 
            selectedCleaners[0]?.email,
          price: parseFloat(serviceCost),
          scheduledDuration: duration,
          scheduledEndTime: convertFromOrgTz(appointmentEndDate).toDate(), // Added timezone conversion
          recurring: true
        };
    
        await setDoc(doc(recurringServicesRef, recurrenceGroupId), recurringServiceData);
    
        // Then create individual jobs
        for (const date of dates) {
          const jobRef = doc(jobsRef);
          const jobData = {
            ...baseJobData,
            appointmentDate: convertFromOrgTz(date.toISOString()).toDate(), // Convert each recurring date
            scheduledEndTime: convertFromOrgTz(new Date(date.getTime() + duration).toISOString()).toDate(), // Convert end time
            status: "Pending",
            createdAt: new Date(),
            recurrenceGroupId,
            recurrenceRule,
            recurring: true,
            cleanerId: selectedCleaners[0]?.id,
            cleanerEmail: selectedCleaners[0]?.email,
            cleanerName: selectedCleaners[0]?.firstName && selectedCleaners[0]?.lastName ? 
              `${selectedCleaners[0].firstName} ${selectedCleaners[0].lastName}` : 
              selectedCleaners[0]?.email,
          };
          await setDoc(jobRef, jobData);
        }
    
      } catch (error) {
        console.error('Error details:', {
          code: error.code,
          message: error.message,
          stack: error.stack
        });
        throw new Error(`Failed to create recurring jobs: ${error.message}`);
      }
    } else {
      // Create single job
      await addDoc(jobsRef, baseJobData);
    }
    
    if (serviceType === 'recurring' && hasInitialClean) {
      // Create initial clean job with its own pricing
      const initialCleanJob = {
        ...baseJobData,
        initialClean: true,
        pricingType: initialCleanPricingType,
        pricingPer: initialCleanPricingPer,
        numberOfCleaners: initialCleanSelectedCleaners.length,
        serviceCost: parseFloat(initialCleanCost),
        pricePerHour: initialCleanPricingType === 'hourly' ? parseFloat(initialCleanCost) : null,
        flatRate: initialCleanPricingType === 'flat' ? parseFloat(initialCleanCost) : null,
        totalCost: initialCleanPricingPer === 'cleaner'
          ? parseFloat(initialCleanCost) * initialCleanSelectedCleaners.length
          : parseFloat(initialCleanCost),
        appointmentDate: convertFromOrgTz(appointmentDate).toDate(),
        scheduledEndTime: convertFromOrgTz(appointmentEndDate).toDate(),
        cleaners: initialCleanSelectedCleaners.map((cleaner) => cleaner.id),
        cleanerDetails: initialCleanSelectedCleaners.map((cleaner) => ({
          id: cleaner.id,
          name:
            cleaner.firstName && cleaner.lastName
              ? `${cleaner.firstName} ${cleaner.lastName}`
              : cleaner.email,
          email: cleaner.email,
        })),
      };

      // Add initial clean job
      await addDoc(collection(db, 'organizations', orgId, 'jobs'), initialCleanJob);
    }
    
    setSuccess('Job created successfully!');
    setShowNotificationModal(true);
    
  } catch (error) {
    console.error('Error creating job:', error);
    setError(error.message);
    setJobDataForEmail(null);
  }
};

  // Email handling function
  const handleEmailSend = async (emailData) => {
    try {
      const token = await getToken();
      
      const customerRef = doc(db, 'organizations', orgId, 'customers', selectedCustomer.id);
      const customerSnap = await getDoc(customerRef);
      
      if (!customerSnap.exists()) {
        throw new Error('Customer not found');
      }
      
      const customerData = customerSnap.data();
      
      if (!customerData.email) {
        throw new Error('Customer email not found');
      }
  
      // Match the exact pattern from JobDialog
      const appointmentDateObj = jobDataForEmail.appointmentDate?.toDate?.() || new Date(jobDataForEmail.appointmentDate);
      const endDateObj = jobDataForEmail.scheduledEndTime?.toDate?.() || new Date(jobDataForEmail.scheduledEndTime);
  
      const jobData = {
        appointmentDate: appointmentDateObj,
        scheduledStartTime: appointmentDateObj,
        scheduledEndTime: endDateObj,
        customerName: customerData.name || `${customerData.firstName} ${customerData.lastName}`,
        customerEmail: customerData.email,
        customerPhone: customerData.phone || customerData.phoneNumber,
        address,
        cleanerDetails: selectedCleaners.map((cleaner) => ({
          id: cleaner.id,
          name: cleaner.firstName && cleaner.lastName
            ? `${cleaner.firstName} ${cleaner.lastName}`
            : cleaner.email,
          email: cleaner.email,
        })),
        serviceCost: parseFloat(serviceCost),
        serviceType,
        status: 'Pending',
        totalSize: parseFloat(totalSize),
        notes,
        importantNotes,
        timezone,
      };
  
      await notificationsApi.sendEmail(token, {
        orgId,
        documentId: emailData.documentId,
        recipientEmail: customerData.email,
        recipientName: customerData.name || customerData.firstName,
        subject: emailData.subject,
        content: emailData.content,
        jobData,
      });
  
      setSuccess('Job created and email sent successfully!');
      setShowEmailModal(false);
      resetForm();
    } catch (error) {
      console.error('Error sending email:', error);
      setError('Failed to send email: ' + error.message);
    }
  };

  const handleNotificationResponse = async (sendEmail) => {
    if (sendEmail) {
      setShowEmailModal(true);
    } else {
      resetForm();
    }
    setShowNotificationModal(false);
  };

  // Loading and error states
  if (loading) {
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.errorContainer}>
        <div className={styles.errorMessage}>{error}</div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className={styles.errorContainer}>
        Please sign in to create a job.
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.nav}>
        <div className={styles.navContent}>
          <NavLink
            to="create"
            className={({ isActive }) => `${styles.tabButton} ${isActive ? styles.active : ''}`}
          >
            <Plus className={styles.buttonIcon} />
            Create Job
          </NavLink>
          <NavLink
            to="jobs-overview"
            className={({ isActive }) => `${styles.tabButton} ${isActive ? styles.activeTab : ''}`}
          >
            Jobs Overview
          </NavLink>
          <NavLink
            to="rooms-tasks"
            className={({ isActive }) => `${styles.tabButton} ${isActive ? styles.activeTab : ''}`}
          >
            Rooms/Tasks
          </NavLink>
          <NavLink
            to="clients"
            className={({ isActive }) => `${styles.tabButton} ${isActive ? styles.activeTab : ''}`}
          >
            Clients
          </NavLink>
          <NavLink
            to="cleaners"
            className={({ isActive }) => `${styles.tabButton} ${isActive ? styles.activeTab : ''}`}
          >
            Cleaners
          </NavLink>
        </div>
      </div>

      <Routes>
        <Route path="/" element={<Navigate to="create" replace />} />
        <Route path="create" element={
          <div className={styles.contentArea}>
            <div className={styles.pageHeader}>
              <h1 className={styles.pageTitle}>Create New Job</h1>
              <button
                type="button"
                onClick={() => setOpenCreateCustomerModal(true)}
                className={styles.actionButton}
              >
                Create Customer
              </button>
            </div>

            <form onSubmit={handleSubmit} className={styles.form}>
              <FormSection
                title="Customer Information"
                icon={<User className={styles.sectionIcon} />}
                highlight={true}
              >
                <div className={styles.autocompleteContainer}>
                  <input
                    type="text"
                    placeholder="Search For A Customer"
                    value={customerInput}
                    onChange={handleCustomerInputChange}
                    className={styles.input}
                    required
                  />
                  {showCustomerDropdown && (
                    <div className={styles.dropdown}>
                      {filteredCustomers.map(customer => (
                        <div
                          key={customer.id}
                          className={styles.dropdownItem}
                          onClick={() => handleCustomerSelect(customer)}
                        >
                          {customer.name}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </FormSection>

              <FormSection
                title="Location Details"
                icon={<MapPin className={styles.sectionIcon} />}
              >
                <input
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  className={styles.input}
                  required
                />
              </FormSection>

              <FormSection
                title="Job Type"
                icon={<FileText className={styles.sectionIcon} />}
              >
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Job Type</label>
                  <select
                    value={jobType}
                    onChange={(e) => setJobType(e.target.value)}
                    className={styles.select}
                    required
                  >
                    <option value="residential">Residential</option>
                    <option value="commercial">Commercial</option>
                  </select>
                </div>
              </FormSection>

              <FormSection
                title="Cleaner Selection"
                icon={<Users className={styles.sectionIcon} />}
              >
                <div className={styles.cleanerList}>
                  {selectedCleaners.map(cleaner => (
                    <div key={cleaner.id} className={styles.cleanerItem}>
                      <span>
                        {cleaner.firstName && cleaner.lastName
                          ? `${cleaner.firstName} ${cleaner.lastName}`
                          : cleaner.email}
                      </span>
                      <button
                        type="button"
                        onClick={() => setSelectedCleaners(prev => 
                          prev.filter(c => c.id !== cleaner.id)
                        )}
                        className={styles.removeButton}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
                
                <button
                  type="button"
                  onClick={() => setOpenSelectCleanersModal(true)}
                  className={styles.button}
                >
                  {selectedCleaners.length === 0 ? 'Select Cleaners' : 'Modify Cleaners'}
                </button>
              </FormSection>

              <FormSection
                title="Date and Time"
                icon={<Clock className={styles.sectionIcon} />}
              >
                <div className={styles.dateTimeContainer}>
                  <div className={styles.dateSelectGroup}>
                    <label className={styles.label}>Appointment Date</label>
                    <input
                      type="date"
                      value={appointmentDate.split('T')[0]}
                      onChange={(e) => {
                        const date = e.target.value;
                        const time = appointmentDate.split('T')[1] || '00:00';
                        setAppointmentDate(`${date}T${time}`);
                        const endTime = appointmentEndDate.split('T')[1] || '00:00';
                        setAppointmentEndDate(`${date}T${endTime}`);
                      }}
                      className={styles.input}
                      required
                    />
                  </div>

                  <div className={styles.timeSelectGroup}>
                    <label className={styles.label}>Start Time</label>
                    <select
                      value={appointmentDate.split('T')[1]?.slice(0, -3) || '09:00'}
                      onChange={(e) => {
                        const date = appointmentDate.split('T')[0] || new Date().toISOString().split('T')[0];
                        setAppointmentDate(`${date}T${e.target.value}:00`);
                      }}
                      className={styles.select}
                      required
                    >
                      {Array.from({ length: 97 }, (_, i) => {
                        const hour24 = Math.floor(i / 4);
                        const minute = (i % 4) * 15;
                        const hour12 = hour24 % 12 || 12;
                        const ampm = hour24 < 12 ? 'AM' : 'PM';
                        const time24 = `${hour24.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                        const displayTime = `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
                        return (
                          <option key={time24} value={time24}>
                            {displayTime}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className={styles.timeSelectGroup}>
                    <label className={styles.label}>End Time</label>
                    <select
                      value={appointmentEndDate.split('T')[1]?.slice(0, -3) || '10:00'}
                      onChange={(e) => {
                        const date = appointmentDate.split('T')[0] || new Date().toISOString().split('T')[0];
                        setAppointmentEndDate(`${date}T${e.target.value}:00`);
                      }}
                      className={styles.select}
                      required
                    >
                      {Array.from({ length: 97 }, (_, i) => {
                        const hour24 = Math.floor(i / 4);
                        const minute = (i % 4) * 15;
                        const hour12 = hour24 % 12 || 12;
                        const ampm = hour24 < 12 ? 'AM' : 'PM';
                        const time24 = `${hour24.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                        const displayTime = `${hour12}:${minute.toString().padStart(2, '0')} ${ampm}`;
                        if (time24 <= (appointmentDate.split('T')[1]?.slice(0, -3) || '00:00')) {
                          return null;
                        }
                        return (
                          <option key={time24} value={time24}>
                            {displayTime}
                          </option>
                        );
                      }).filter(Boolean)}
                    </select>
                  </div>
                </div>
              </FormSection>

              <FormSection
                title="Service Type and Cost"
                icon={<DollarSign className={styles.sectionIcon} />}
              >
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Service Type</label>
                  <select
                    value={serviceType}
                    onChange={(e) => {
                      setServiceType(e.target.value);
                      setRecurring(e.target.value === 'recurring');
                      if (e.target.value === 'one-time') {
                        setRecurrenceRule('');
                        setHasInitialClean(false);
                      }
                    }}
                    className={styles.select}
                    required
                  >
                    <option value="one-time">One Time Service</option>
                    <option value="recurring">Recurring Service</option>
                  </select>
                </div>

                <div className={styles.inputGroup}>
                  <label className={styles.label}>Pricing Type</label>
                  <select
                    value={pricingType}
                    onChange={(e) => setPricingType(e.target.value)}
                    className={styles.select}
                    required
                  >
                    <option value="hourly">Hourly Rate</option>
                    <option value="flat">Flat Rate</option>
                  </select>
                </div>

                <div className={styles.inputGroup}>
                  <label className={styles.label}>Price Per</label>
                  <select
                    value={pricingPer}
                    onChange={(e) => setPricingPer(e.target.value)}
                    className={styles.select}
                    required
                  >
                    <option value="job">Job</option>
                    <option value="cleaner">Cleaner</option>
                  </select>
                </div>

                <div className={styles.inputGroup}>
                  <label className={styles.label}>
                    {pricingType === 'hourly' 
                      ? `${pricingPer === 'job' ? 'Job' : 'Per Cleaner'} Hourly Rate ($)`
                      : `${pricingPer === 'job' ? 'Job' : 'Per Cleaner'} Flat Rate ($)`}
                  </label>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={serviceCost}
                    onChange={(e) => setServiceCost(e.target.value)}
                    className={styles.input}
                    placeholder={`Enter ${pricingType === 'hourly' ? 'hourly' : 'flat'} rate`}
                    required
                  />
                </div>

                {serviceType === 'recurring' && (
                  <div className={styles.initialCleanSection}>
                    <div className={styles.checkboxGroup}>
                      <input
                        type="checkbox"
                        id="hasInitialClean"
                        checked={hasInitialClean}
                        onChange={(e) => {
                          setHasInitialClean(e.target.checked);
                          if (!e.target.checked) {
                            setInitialCleanSelectedCleaners([]); // Clear selected cleaners if unchecked
                          }
                        }}
                        className={styles.checkbox}
                      />
                      <label htmlFor="hasInitialClean">Include Initial Clean</label>
                    </div>

                    {hasInitialClean && (
                      <>
                        <div className={styles.inputGroup}>
                          <label className={styles.label}>Initial Clean Pricing Type</label>
                          <select
                            value={initialCleanPricingType}
                            onChange={(e) => setInitialCleanPricingType(e.target.value)}
                            className={styles.select}
                            required
                          >
                            <option value="hourly">Hourly Rate</option>
                            <option value="flat">Flat Rate</option>
                          </select>
                        </div>

                        <div className={styles.inputGroup}>
                          <label className={styles.label}>Initial Clean Price Per</label>
                          <select
                            value={initialCleanPricingPer}
                            onChange={(e) => setInitialCleanPricingPer(e.target.value)}
                            className={styles.select}
                            required
                          >
                            <option value="job">Job</option>
                            <option value="cleaner">Cleaner</option>
                          </select>
                        </div>

                        <div className={styles.inputGroup}>
                          <label className={styles.label}>
                            {initialCleanPricingType === 'hourly'
                              ? `Initial Clean ${initialCleanPricingPer === 'job' ? 'Job' : 'Per Cleaner'} Hourly Rate ($)`
                              : `Initial Clean ${initialCleanPricingPer === 'job' ? 'Job' : 'Per Cleaner'} Flat Rate ($)`}
                          </label>
                          <input
                            type="number"
                            min="0"
                            step="0.01"
                            value={initialCleanCost}
                            onChange={(e) => setInitialCleanCost(e.target.value)}
                            className={styles.input}
                            placeholder={`Enter initial clean ${initialCleanPricingType === 'hourly' ? 'hourly' : 'flat'} rate`}
                            required={hasInitialClean}
                          />
                        </div>

                        <div className={styles.inputGroup}>
                          <label className={styles.label}>Initial Clean Cleaners</label>
                          <div className={styles.cleanerList}>
                            {initialCleanSelectedCleaners.map(cleaner => (
                              <div key={cleaner.id} className={styles.cleanerItem}>
                                <span>
                                  {cleaner.firstName && cleaner.lastName
                                    ? `${cleaner.firstName} ${cleaner.lastName}`
                                    : cleaner.email}
                                </span>
                                <button
                                  type="button"
                                  onClick={() => setInitialCleanSelectedCleaners(prev => 
                                    prev.filter(c => c.id !== cleaner.id)
                                  )}
                                  className={styles.removeButton}
                                >
                                  Remove
                                </button>
                              </div>
                            ))}
                          </div>
                          
                          <button
                            type="button"
                            onClick={() => setOpenInitialCleanSelectCleanersModal(true)}
                            className={styles.button}
                          >
                            {initialCleanSelectedCleaners.length === 0 ? 'Select Initial Clean Cleaners' : 'Modify Initial Clean Cleaners'}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}

                {serviceType === 'recurring' && (
                  <div className={styles.recurrenceContainer}>
                    <RecurrenceSelect
                      value={recurrenceRule}
                      onChange={setRecurrenceRule}
                    />
                  </div>
                )}
              </FormSection>

              <FormSection
                title="Total Size (SqFt)"
                icon={<Home className={styles.sectionIcon} />}
              >
                <div className={styles.inputGroup}>
                  <label className={styles.label}>Total Size (SqFt)</label>
                  <input
                    type="number"
                    min="0"
                    step="1"
                    value={totalSize}
                    onChange={(e) => setTotalSize(e.target.value)}
                    className={styles.input}
                    placeholder="Enter the total size in square feet"
                    required
                  />
                </div>
              </FormSection>

              <FormSection
                title="Rooms"
                icon={<ClipboardList className={styles.sectionIcon} />}
              >
                <div className={styles.sectionHeader}>
                  <h3 className={styles.sectionTitle}>Rooms</h3>
                  <button
                    type="button"
                    onClick={() => setOpenAddRoomsModal(true)}
                    className={styles.button}
                  >
                    <Plus size={16} /> Add Rooms
                  </button>
                </div>

                <div className={styles.roomsList}>
                  {selectedRooms.map((room, index) => (
                    <div key={index} className={styles.roomCard}>
                      <div
                        className={styles.roomHeader}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h4 className={styles.roomName}>{room.name}</h4>
                        <span>{accordionOpen[index] ? '-' : '+'}</span>
                      </div>
                      {accordionOpen[index] && (
                        <div className={styles.tasksList}>
                          {room.tasks?.map((task, taskIndex) => (
                            <div key={taskIndex} className={styles.taskItem}>
                              <label className={styles.checkboxLabel}>
                                <input
                                  type="checkbox"
                                  checked={task.completed || false}
                                  onChange={(e) => handleTaskChange(index, taskIndex, e.target.checked)}
                                  className={styles.checkbox}
                                />
                                <span>{task.description}</span>
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </FormSection>

              <FormSection
                title="Notes"
                icon={<FileText className={styles.sectionIcon} />}
              >
                <div className={styles.textareaGroup}>
                  <label className={styles.label}>Notes</label>
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    className={styles.textarea}
                    rows={3}
                  />
                </div>
              </FormSection>

              <FormSection
                title="Important Notes"
                icon={<FileText className={styles.sectionIcon} />}
              >
                <div className={styles.textareaGroup}>
                  <label className={styles.label}>Important Notes</label>
                  <textarea
                    value={importantNotes}
                    onChange={(e) => setImportantNotes(e.target.value)}
                    className={styles.textarea}
                    rows={3}
                  />
                </div>
              </FormSection>

              {success && (
                <div className={styles.successMessage}>{success}</div>
              )}

              <div className={styles.formActions}>
                <button
                  type="submit"
                  className={`${styles.actionButton} ${styles.submitButton}`}
                  disabled={loading}
                >
                  {loading ? 'Creating...' : 'Create Job'}
                </button>
              </div>
            </form>
          </div>
        } />
        
        <Route path="jobs-overview" element={
          <div className={styles.formContent}>
            <JobsOverview orgId={orgId} newVersion={newVersion}/>
          </div>
        } />
        
        <Route path="rooms-tasks" element={
          <div className={styles.formContent}>
            <RoomsTasksManagement orgId={orgId} />
          </div>
        } />
        
        <Route path="clients" element={
    <div className={styles.formContent}>
      <CustomerManagement orgId={orgId} />
    </div>
      } />
      <Route path="clients/:customerId" element={
        <div className={styles.formContent}>
          <CustomerProfileManagement />
        </div>
      } />

      <Route path="cleaners" element={
          <div className={styles.formContent}>
            <CleanersReporting orgId={orgId} />
          </div>
        } />
      <Route path="/organizations/:orgId/cleaners/:cleanerId" element={<CleanerProfile />} />
      </Routes>

  
      {/* Modals */}
      {openCreateRoomModal && (
        <CreateRoomModal
          open={openCreateRoomModal}
          handleClose={() => setOpenCreateRoomModal(false)}
          handleCreateRoom={handleCreateRoom}
        />
      )}
  
      {isEditModalOpen && (
        <EditRoomModal
          open={isEditModalOpen}
          handleClose={handleCloseEditModal}
          roomData={editingRoom}
          handleEditRoom={handleEditRoom}
        />
      )}
  
      {openAddRoomsModal && (
        <AddRoomsModal
          open={openAddRoomsModal}
          handleClose={() => setOpenAddRoomsModal(false)}
          availableRooms={availableRooms}
          handleAddRooms={handleAddRooms}
          existingRooms={selectedRooms}
          orgId={orgId}
          fetchAvailableRooms={fetchAvailableRooms}
        />
      )}
  
      {openCreateCustomerModal && (
        <CreateCustomerModal
          open={openCreateCustomerModal}
          handleClose={() => setOpenCreateCustomerModal(false)}
          handleCreateCustomer={handleCreateCustomer}
          customFields={[]}
          isEditing={false}
          initialData={null}
          existingCustomers={customers}
        />
      )}
  
      {openSelectCleanersModal && (
      <SelectCleanersModal
        open={openSelectCleanersModal}
        handleClose={() => setOpenSelectCleanersModal(false)}
        cleaners={cleaners}
        selectedCleaners={selectedCleaners}
        setSelectedCleaners={setSelectedCleaners}
      />
      )}

      <JobNotificationModal 
          isOpen={showNotificationModal}
          onClose={() => setShowNotificationModal(false)}
          onConfirm={handleNotificationResponse}
        />

        {showEmailModal && (
          <EmailPreviewModal
            open={showEmailModal}
            onClose={() => setShowEmailModal(false)}
            jobData={{
              // Use the already converted dates from jobDataForEmail
              appointmentDate: jobDataForEmail.appointmentDate,
              scheduledStartTime: jobDataForEmail.appointmentDate,
              scheduledEndTime: jobDataForEmail.scheduledEndTime,

              // Rest stays the same
              customerName: selectedCustomer?.name,
              customerEmail: selectedCustomer?.email,
              customerPhone: selectedCustomer?.phone,
              address,

              cleanerDetails: selectedCleaners.map(cleaner => ({
                id: cleaner.id,
                name:
                  cleaner.firstName && cleaner.lastName
                    ? `${cleaner.firstName} ${cleaner.lastName}`
                    : cleaner.email,
                email: cleaner.email,
              })),

              organizationName: organizationInfo?.name,
              organizationLogo: organizationInfo?.companyInfo?.logoUrl,
              organizationPhone: organizationInfo?.companyInfo?.phoneNumber,
              organizationEmail: organizationInfo?.companyInfo?.email,

              serviceCost: parseFloat(serviceCost) || 0,
              serviceType,
              status: 'Pending',
              totalSize: parseFloat(totalSize) || 0,
              notes,
              importantNotes,

              recurringStatus: recurring ? 'Active' : '',
              recurrenceRule: recurring ? recurrenceRule : '',
              timezone,
            }}
            onSend={handleEmailSend}
            templates={emailTemplates}
          />
        )}

        {openInitialCleanSelectCleanersModal && (
          <SelectCleanersModal
            open={openInitialCleanSelectCleanersModal}
            handleClose={() => setOpenInitialCleanSelectCleanersModal(false)}
            cleaners={cleaners}
            selectedCleaners={initialCleanSelectedCleaners}
            setSelectedCleaners={setInitialCleanSelectedCleaners}
          />
        )}

    </div>
  );
};

export default CreateJobForm;