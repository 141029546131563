// /src/components/JobsOverview.jsx

// Availability Cards Still Show the wrong time zone
// CustomerProfileManagement shows the correct Time Zone as a display - job.updatedAt.toDate is not a function
// When clicking on a job to display jobDetails it shows the correct time format, but in EditJobForm in CustomerProfileManagement, it shows 24hr format, and when editing the timezone does not work.
// In EditRecurringServices it does not display the correct time zone
// When editing the time in EditRecurringServices it does not have the correct permissions, and I think that anything to save with in here needs the correct permissions.
// We need to refresh the available status upon saving.






import React, { useState, useEffect } from 'react';
import { collection, deleteField, query, where, orderBy, getDocs, doc, updateDoc, deleteDoc, addDoc, writeBatch, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getAuth } from 'firebase/auth';
import { serverTimestamp } from 'firebase/firestore';
import { auth } from '../firebase';
import { rrulestr } from 'rrule';
import JobDialog from './JobDialog';
import { notificationsApi } from '../utils/api';
import { useTimezone } from '../context/TimeZoneContext';
import { useJobContext, JOB_STATUSES, normalizeJobStatus, DEFAULT_STATUS_COLORS } from '../context/JobContext';
import { DEFAULT_JOB_STATUSES } from '../constants/statuses';
import AddRoomsModal from './AddRoomsModal';
import WeeklyJobsView from './WeeklyJobsView';
import { formatCurrency } from '../utils/formatCurrency';
import styles from '../styles/JobsOverview.module.css';
import moment from 'moment';
import { useAuth } from '../context/AuthContext';
import Calendar from './JobsOverview/Calendar';
import ActionCenter from './JobsOverview/ActionCenter/ActionCenter';
import FilterCenter from './JobsOverview/FilterCenter';
import { JobOverviewProvider } from '../context/JobOverviewContext';
import AvailabilityModal from './AvailabilityModal';

const JobsOverview = ({ orgId, newVersion = false }) => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [selectedJob, setSelectedJob] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedJob, setEditedJob] = useState(null);
  const [error, setError] = useState(null);
  const [openAddRoomsModal, setOpenAddRoomsModal] = useState(false);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [showingRecurringSeries, setShowingRecurringSeries] = useState(false);
  const [recurringInstances, setRecurringInstances] = useState([]);
  const [applyToRecurring, setApplyToRecurring] = useState(false);
  const [success, setSuccess] = useState('');
  const { formatInOrgTz, convertToOrgTz, convertFromOrgTz } = useTimezone();
  const [availableTimeBlocks, setAvailableTimeBlocks] = useState([]);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [customJobStatuses, setCustomJobStatuses] = useState([]);
  const [availabilityStatuses, setAvailabilityStatuses] = useState([]);
  const [isAvailabilityModalOpen, setIsAvailabilityModalOpen] = useState(false);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [updating, setUpdating] = useState(false);

  const [cleaners, setCleaners] = useState([]);
  const [timeOffRequests, setTimeOffRequests] = useState([]);
  const [scheduleSettings, setScheduleSettings] = useState({
    minimumGapNotification: 180,
    driveTimeGap: 30
  });

  const {
    fetchCustomStatuses
  } = useJobContext();

  const { user } = useAuth();

  // Status filtering state - initialize with all available statuses
  const [selectedStatuses, setSelectedStatuses] = useState(Object.values(JOB_STATUSES));

  // Available statuses with labels and colors
  const availableStatuses = [
    // Default statuses with their colors
    {
      value: JOB_STATUSES.PENDING,
      label: JOB_STATUSES.PENDING,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.PENDING],
      chipColor: DEFAULT_STATUS_COLORS[JOB_STATUSES.PENDING]
    },
    {
      value: JOB_STATUSES.IN_PROGRESS,
      label: JOB_STATUSES.IN_PROGRESS,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.IN_PROGRESS],
      chipColor: DEFAULT_STATUS_COLORS[JOB_STATUSES.IN_PROGRESS]
    },
    {
      value: JOB_STATUSES.COMPLETED,
      label: JOB_STATUSES.COMPLETED,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.COMPLETED],
      chipColor: DEFAULT_STATUS_COLORS[JOB_STATUSES.COMPLETED]
    },
    {
      value: JOB_STATUSES.CANCELLED,
      label: JOB_STATUSES.CANCELLED,
      color: DEFAULT_STATUS_COLORS[JOB_STATUSES.CANCELLED],
      chipColor: DEFAULT_STATUS_COLORS[JOB_STATUSES.CANCELLED]
    },
    // Custom statuses
    ...customJobStatuses.map(status => ({
      value: status.name,
      label: status.name,
      color: status.color,
      chipColor: status.color
    }))
  ];

  // Helper function to get status details
  const getStatusDetails = (status) => {
    // First check custom statuses
    const customStatus = customJobStatuses.find(s => s.name === status);
    if (customStatus) {
      return {
        value: customStatus.name,
        label: customStatus.name,
        color: customStatus.color,
        chipColor: customStatus.color
      };
    }

    // If no custom status found, return default status
    const defaultStatus = DEFAULT_JOB_STATUSES.find(s => s.name === status);
    return defaultStatus || DEFAULT_JOB_STATUSES[0];
  };

  const findAvailabilityGaps = (day, dayJobs) => {
    const getStatusForTimeBlock = (cleanerId, start, end, availabilityStatuses = {}) => {
      if (!cleanerId || !start || !end) return null;
      const startInOrgTz = convertToOrgTz(start);
      const endInOrgTz = convertToOrgTz(end);
      const availabilityKey = `${startInOrgTz.format('YYYY-MM-DD-HH-mm')}-${endInOrgTz.format('YYYY-MM-DD-HH-mm')}`;
      const cleanerStatuses = availabilityStatuses[cleanerId] || {};
      return cleanerStatuses[availabilityKey]?.status || null;
    };
  
    const gaps = [];
    const dayName = day.format('dddd').toLowerCase();
  
    cleaners.forEach(cleaner => {
      // Get all jobs for this cleaner (including those where they're in the cleaners array)
      const cleanerJobs = dayJobs.filter(job => {
        const isAssignedAsPrimary = job.cleanerId === cleaner.id;
        const isAssignedInArray = Array.isArray(job.cleaners) &&
          job.cleaners.some(c => c.id === cleaner.id || c.cleanerId === cleaner.id);
        const isAssignedByName = job.cleanerName === cleaner.name ||
          job.cleanerName === `${cleaner.firstName} ${cleaner.lastName}`;
  
        return isAssignedAsPrimary || isAssignedInArray || isAssignedByName;
      });
  
      // Create job-like objects from time off requests
      const cleanerTimeOff = timeOffRequests[cleaner.id] || {};
      const timeOffJobs = Object.entries(cleanerTimeOff)
        .filter(([_, request]) => {
          const requestDate = convertToOrgTz(moment(request.date.toDate()));
          return request.status === 'approved' && requestDate.isSame(day, 'day');
        })
        .map(([id, request]) => ({
          id,
          appointmentDate: { toDate: () => request.startTime.toDate() },
          scheduledEndTime: { toDate: () => request.endTime.toDate() },
          status: 'active'  // Treat time off like an active job
        }));
  
      // Combine regular jobs and time off "jobs"
      const allJobs = [...cleanerJobs, ...timeOffJobs];
  
      // Skip if cleaner has any non-cancelled jobs
      const hasActiveJobs = cleanerJobs.some(job =>
        job.status?.toLowerCase() !== 'cancelled' &&
        job.status?.toLowerCase() !== 'completed'
      );
  
      if (hasActiveJobs) {
        return; // Skip creating availability blocks for this cleaner
      }
  
      // Check if cleaner has working hours set for this day
      if (!cleaner.availability?.[dayName] ||
        !cleaner.workingHours?.[dayName]) {
        return;
      }
  
      const dayWorkingHours = cleaner.workingHours[dayName];
  
      // Skip if working hours aren't properly set
      if (!dayWorkingHours.start || !dayWorkingHours.end ||
        dayWorkingHours.start === "" || dayWorkingHours.end === "") {
        return;
      }
  
      // Set up work day start and end times
      const workStart = convertToOrgTz(moment(day))
        .set('hour', parseInt(dayWorkingHours.start.split(':')[0]))
        .set('minute', parseInt(dayWorkingHours.start.split(':')[1]))
        .set('second', 0);
  
      const workEnd = convertToOrgTz(moment(day))
        .set('hour', parseInt(dayWorkingHours.end.split(':')[0]))
        .set('minute', parseInt(dayWorkingHours.end.split(':')[1]))
        .set('second', 0);
  
      // Process time blocks or entire working period
      if (scheduleSettings.timeBlocks && scheduleSettings.timeBlocks.length > 0) {
        scheduleSettings.timeBlocks.forEach(block => {
          if (!cleaner.workingHours?.[dayName]) return;

          const [startHour, startMinute] = block.start.split(':').map(Number);
          const [endHour, endMinute] = block.end.split(':').map(Number);

          const blockStart = convertToOrgTz(moment(day))
            .set('hour', startHour)
            .set('minute', startMinute)
            .set('second', 0);

          const blockEnd = convertToOrgTz(moment(day))
            .set('hour', endHour)
            .set('minute', endMinute)
            .set('second', 0);

          // Get all overlapping items (jobs and time off requests)
          const overlappingItems = [
            ...allJobs.map(job => ({
              start: convertToOrgTz(moment(job.appointmentDate.toDate())).subtract(scheduleSettings.driveTimeGap, 'minutes'),
              end: convertToOrgTz(moment(job.scheduledEndTime.toDate())).add(scheduleSettings.driveTimeGap, 'minutes'),
              type: 'job'
            })),
            ...timeOffRequests
              .filter(request => 
                (request.cleanerId === cleaner.id || request.createdBy === cleaner.id) &&
                request.status === 'approved' &&
                convertToOrgTz(moment(request.startTime.toDate())).isSame(day, 'day')
              )
              .map(request => ({
                start: convertToOrgTz(moment(request.startTime.toDate())).subtract(scheduleSettings.driveTimeGap, 'minutes'),
                end: convertToOrgTz(moment(request.endTime.toDate())).add(scheduleSettings.driveTimeGap, 'minutes'),
                type: 'timeOff'
              }))
          ].sort((a, b) => a.start.valueOf() - b.start.valueOf());

          if (overlappingItems.length > 0) {
            overlappingItems.forEach(item => {
              // If item starts after block start and ends before block end,
              // we might have two smaller blocks
              if (item.start.isAfter(blockStart) && item.end.isBefore(blockEnd)) {
                // Check first portion
                const firstDuration = item.start.diff(blockStart, 'minutes');
                if (firstDuration >= scheduleSettings.minimumGapNotification) {
                  gaps.push({
                    cleaner,
                    start: blockStart.clone(),
                    end: item.start.clone(),
                    duration: firstDuration,
                    status: getStatusForTimeBlock(cleaner.id, blockStart, item.start, availabilityStatuses),
                    isTimeBlock: true,
                    blockRange: `${block.start}-${item.start.add(scheduleSettings.driveTimeGap, 'minutes').format('HH:mm')}`
                  });
                }

                // Check second portion
                const secondDuration = blockEnd.diff(item.end, 'minutes');
                if (secondDuration >= scheduleSettings.minimumGapNotification) {
                  gaps.push({
                    cleaner,
                    start: item.end.clone(),
                    end: blockEnd.clone(),
                    duration: secondDuration,
                    status: getStatusForTimeBlock(cleaner.id, item.end, blockEnd, availabilityStatuses),
                    isTimeBlock: true,
                    blockRange: `${item.end.subtract(scheduleSettings.driveTimeGap, 'minutes').format('HH:mm')}-${block.end}`
                  });
                }
              }
              // If item completely overlaps the block, skip it
              else if (item.start.isSameOrBefore(blockStart) && item.end.isSameOrAfter(blockEnd)) {
                return;
              }
              // If item starts in the middle of the block
              else if (item.start.isAfter(blockStart) && item.start.isBefore(blockEnd)) {
                const duration = item.start.diff(blockStart, 'minutes');
                if (duration >= scheduleSettings.minimumGapNotification) {
                  gaps.push({
                    cleaner,
                    start: blockStart.clone(),
                    end: item.start.clone(),
                    duration,
                    status: getStatusForTimeBlock(cleaner.id, blockStart, item.start, availabilityStatuses),
                    isTimeBlock: true,
                    blockRange: `${block.start}-${item.start.add(scheduleSettings.driveTimeGap, 'minutes').format('HH:mm')}`
                  });
                }
              }
              // If item ends in the middle of the block
              else if (item.end.isAfter(blockStart) && item.end.isBefore(blockEnd)) {
                const duration = blockEnd.diff(item.end, 'minutes');
                if (duration >= scheduleSettings.minimumGapNotification) {
                  gaps.push({
                    cleaner,
                    start: item.end.clone(),
                    end: blockEnd.clone(),
                    duration,
                    status: getStatusForTimeBlock(cleaner.id, item.end, blockEnd, availabilityStatuses),
                    isTimeBlock: true,
                    blockRange: `${item.end.subtract(scheduleSettings.driveTimeGap, 'minutes').format('HH:mm')}-${block.end}`
                  });
                }
              }
            });
          } else {
            // No overlaps and meets minimum duration, add the full block
            const blockDuration = blockEnd.diff(blockStart, 'minutes');
            if (blockDuration >= scheduleSettings.minimumGapNotification) {
              gaps.push({
                cleaner,
                start: blockStart.clone(),
                end: blockEnd.clone(),
                duration: blockDuration,
                status: getStatusForTimeBlock(cleaner.id, blockStart, blockEnd, availabilityStatuses),
                isTimeBlock: true,
                blockRange: `${block.start}-${block.end}`
              });
            }
          }
        });
      } else {
        // Use entire working period if no time blocks defined
        // First check if there are any blocks affecting the whole day
        const affectingBlocks = allJobs.filter(job => {
          const jobStart = convertToOrgTz(moment(job.appointmentDate.toDate()));
          const jobEnd = convertToOrgTz(moment(job.scheduledEndTime.toDate()));
          
          const workStartWithDrive = moment(workStart).subtract(scheduleSettings.driveTimeGap, 'minutes');
          const workEndWithDrive = moment(workEnd).add(scheduleSettings.driveTimeGap, 'minutes');
          
          return (
            jobStart.isBetween(workStartWithDrive, workEndWithDrive, undefined, '[]') ||
            jobEnd.isBetween(workStartWithDrive, workEndWithDrive, undefined, '[]') ||
            (jobStart.isSameOrBefore(workStartWithDrive) && jobEnd.isSameOrAfter(workEndWithDrive))
          );
        }).sort((a, b) => {
          return convertToOrgTz(moment(a.appointmentDate.toDate())).diff(
            convertToOrgTz(moment(b.appointmentDate.toDate()))
          );
        });
  
        if (affectingBlocks.length === 0) {
          const duration = workEnd.diff(workStart, 'minutes');
          if (duration >= scheduleSettings.minimumGapNotification) {
            const status = getStatusForTimeBlock(cleaner.id, workStart, workEnd, availabilityStatuses);
            gaps.push({
              cleaner,
              start: workStart.clone(),
              end: workEnd.clone(),
              duration,
              status
            });
          }
        } else {
          // Process gaps between blocks
          let currentTime = workStart.clone();
          
          affectingBlocks.forEach((block, index) => {
            const blockStart = convertToOrgTz(moment(block.appointmentDate.toDate()));
            const blockEnd = convertToOrgTz(moment(block.scheduledEndTime.toDate()));
  
            // Check gap before block
            if (currentTime.isBefore(blockStart)) {
              const gapStart = currentTime.clone();
              const gapEnd = moment(blockStart).subtract(scheduleSettings.driveTimeGap, 'minutes');
              const duration = gapEnd.diff(gapStart, 'minutes');
  
              if (duration >= scheduleSettings.minimumGapNotification) {
                const status = getStatusForTimeBlock(cleaner.id, gapStart, gapEnd, availabilityStatuses);
                gaps.push({
                  cleaner,
                  start: gapStart,
                  end: gapEnd,
                  duration,
                  status
                });
              }
            }
  
            currentTime = moment(blockEnd).add(scheduleSettings.driveTimeGap, 'minutes');
  
            // Check for gap after last block
            if (index === affectingBlocks.length - 1 && currentTime.isBefore(workEnd)) {
              const duration = workEnd.diff(currentTime, 'minutes');
              if (duration >= scheduleSettings.minimumGapNotification) {
                const status = getStatusForTimeBlock(cleaner.id, currentTime, workEnd, availabilityStatuses);
                gaps.push({
                  cleaner,
                  start: currentTime.clone(),
                  end: workEnd.clone(),
                  duration,
                  status
                });
              }
            }
          });
        }
      }
  
      // Process gaps between cancelled jobs
      const cancelledJobs = allJobs.filter(job => job.status?.toLowerCase() === 'cancelled');
      let currentTime = workStart.clone();
  
      cancelledJobs.forEach((job, index) => {
        const jobStart = convertToOrgTz(moment(job.appointmentDate.toDate()));
        const jobEnd = convertToOrgTz(moment(job.scheduledEndTime.toDate()));
  
        // Check gap before cancelled job
        if (currentTime.isBefore(jobStart)) {
          const gapBeforeJob = jobStart.diff(currentTime, 'minutes');
  
          if (gapBeforeJob >= scheduleSettings.minimumGapNotification + scheduleSettings.driveTimeGap) {
            const gapEnd = moment(jobStart).subtract(scheduleSettings.driveTimeGap, 'minutes');
            const status = getStatusForTimeBlock(cleaner.id, currentTime, gapEnd, availabilityStatuses);
  
            gaps.push({
              cleaner,
              start: currentTime.clone(),
              end: gapEnd,
              duration: gapBeforeJob - scheduleSettings.driveTimeGap,
              status
            });
          }
        }
  
        currentTime = moment(jobEnd).add(scheduleSettings.driveTimeGap, 'minutes');
  
        // Check for gap after last cancelled job
        if (index === cancelledJobs.length - 1 && currentTime.isBefore(workEnd)) {
          const finalGap = workEnd.diff(currentTime, 'minutes');
          if (finalGap >= scheduleSettings.minimumGapNotification) {
            const status = getStatusForTimeBlock(cleaner.id, currentTime, workEnd, availabilityStatuses);
  
            gaps.push({
              cleaner,
              start: currentTime.clone(),
              end: workEnd.clone(),
              duration: finalGap,
              status
            });
          }
        }
      });
    });
  
    return gaps;
  };
  
  useEffect(() => {
    if (orgId) {
      fetchJobs();
      fetchCustomStatuses(orgId); // Fetch custom statuses when component mounts
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      fetchJobs();
      fetchCleanersAndTimeOff();
    }
  }, [orgId]);

  const fetchCleanersAndTimeOff = async () => {
    if (!orgId) {
      return;
    }

    try {
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnapshot = await getDocs(cleanersRef);

      const cleanersData = [];
      const timeOffData = [];

      for (const cleanerDoc of cleanersSnapshot.docs) {
        const cleanerData = cleanerDoc.data();

        if (cleanerData.isActive) {
          cleanersData.push({
            ...cleanerData,
            id: cleanerDoc.id
          });

          const timeOffRef = collection(db, 'organizations', orgId, 'cleaners', cleanerDoc.id, 'timeOffRequests');
          const timeOffSnapshot = await getDocs(timeOffRef);

          timeOffSnapshot.forEach(timeOffDoc => {
            timeOffData.push({
              id: timeOffDoc.id,
              cleaner: cleanerData,
              ...timeOffDoc.data()
            })
          });
        }
      }

      timeOffData.sort((a, b) => b.startTime.seconds - a.startTime.seconds);

      setCleaners(cleanersData);
      setTimeOffRequests(timeOffData);

      const orgDocRef = doc(db, 'organizations', orgId);
      const orgDocSnap = await getDoc(orgDocRef);
      if (orgDocSnap.exists() && orgDocSnap.data().scheduleSettings) {
        setScheduleSettings(orgDocSnap.data().scheduleSettings);
      }
    } catch (error) {
      console.error('Error fetching cleaners:', error);
    }
  };

  // Helper function to synchronize timestamps
  const synchronizeTimestamps = (jobData) => {
    const syncedData = { ...jobData };

    // Sync start times
    if (syncedData.startTime && !syncedData.actualStartTime) {
      syncedData.actualStartTime = syncedData.startTime;
    } else if (syncedData.actualStartTime && !syncedData.startTime) {
      syncedData.startTime = syncedData.actualStartTime;
    }

    // Sync end times
    if (syncedData.endTime && !syncedData.actualEndTime) {
      syncedData.actualEndTime = syncedData.endTime;
    } else if (syncedData.actualEndTime && !syncedData.endTime) {
      syncedData.endTime = syncedData.actualEndTime;
    }

    return syncedData;
  };

  const fetchJobs = async () => {
    try {
      // First fetch all cleaners to get their proper names
      const cleanersRef = collection(db, 'organizations', orgId, 'cleaners');
      const cleanersSnapshot = await getDocs(cleanersRef);
      const cleanersMap = {};
      
      cleanersSnapshot.docs.forEach(doc => {
        const cleanerData = doc.data();
        cleanersMap[doc.id] = {
          id: doc.id,
          firstName: cleanerData.firstName || '',
          lastName: cleanerData.lastName || '',
          email: cleanerData.email || '',
          displayName: cleanerData.displayName || ''
        };
      });
  
      // Fetch room types
      const roomTypesRef = collection(db, 'organizations', orgId, 'roomTypes');
      const roomTypesSnapshot = await getDocs(roomTypesRef);
      const roomsList = roomTypesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setAvailableRooms(roomsList);
  
      // Fetch jobs
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const jobsQuery = query(
        jobsRef,
        orderBy('appointmentDate', 'asc')
      );
  
      const jobsSnapshot = await getDocs(jobsQuery);
  
      const processedJobs = jobsSnapshot.docs.map(doc => {
        const jobData = {
          id: doc.id,
          ...doc.data()
        };
  
        // Process cleaners array and cleanerDetails
        if (Array.isArray(jobData.cleanerDetails)) {
          jobData.cleanerDetails = jobData.cleanerDetails.map(cleaner => {
            const cleanerInfo = cleanersMap[cleaner.id];
            if (cleanerInfo) {
              return {
                ...cleaner,
                name: cleanerInfo.firstName && cleanerInfo.lastName 
                  ? `${cleanerInfo.firstName} ${cleanerInfo.lastName}`
                  : cleanerInfo.displayName || cleaner.email
              };
            }
            return cleaner;
          });
        }
  
        if (Array.isArray(jobData.cleaners)) {
          jobData.cleaners = jobData.cleaners.map(cleaner => {
            const cleanerId = typeof cleaner === 'string' ? cleaner : cleaner.id;
            const cleanerInfo = cleanersMap[cleanerId];
            if (cleanerInfo) {
              return {
                id: cleanerId,
                name: cleanerInfo.firstName && cleanerInfo.lastName 
                  ? `${cleanerInfo.firstName} ${cleanerInfo.lastName}`
                  : cleanerInfo.displayName || cleanerInfo.email,
                email: cleanerInfo.email
              };
            }
            return cleaner;
          });
          jobData.cleanerNames = jobData.cleaners.map(c => c.name).join(', ');
        } else if (jobData.cleanerId && cleanersMap[jobData.cleanerId]) {
          const cleanerInfo = cleanersMap[jobData.cleanerId];
          jobData.cleanerName = cleanerInfo.firstName && cleanerInfo.lastName 
            ? `${cleanerInfo.firstName} ${cleanerInfo.lastName}`
            : cleanerInfo.displayName || cleanerInfo.email;
        }
  
        // Convert all dates to org timezone consistently
        if (jobData.appointmentDate) {
          const appointmentDate = convertToOrgTz(jobData.appointmentDate);
          jobData.appointmentDate = appointmentDate;
          jobData.formattedAppointmentDate = formatInOrgTz(jobData.appointmentDate, 'LLLL');
        }
  
        if (jobData.scheduledEndTime) {
          const endTime = convertToOrgTz(jobData.scheduledEndTime);
          jobData.scheduledEndTime = endTime;
          jobData.formattedEndTime = formatInOrgTz(jobData.scheduledEndTime, 'LLLL');
        }
  
        // Process the rest of the data
        jobData.status = normalizeJobStatus(jobData.status);
        jobData.formattedServiceCost = formatCurrency(jobData.serviceCost);
        jobData.serviceCost = parseFloat(jobData.serviceCost) || 0;
        jobData.price = parseFloat(jobData.price) || 0;
        jobData.totalSize = jobData.totalSize || 0;
  
        // Process recurring info
        jobData.serviceType = jobData.serviceType || 'one-time';
        jobData.recurring = jobData.serviceType === 'recurring';
  
        if (jobData.serviceType === 'recurring' && jobData.recurrenceRule) {
          jobData.isRecurring = true;
          jobData.formattedRecurrence = formatRecurrenceRule(jobData.recurrenceRule);
        } else {
          jobData.isRecurring = false;
          jobData.formattedRecurrence = null;
        }
  
        // Process rooms
        if (jobData.rooms) {
          jobData.rooms = jobData.rooms.map(room => ({
            ...room,
            tasks: Array.isArray(room.tasks) ? room.tasks.map(task => ({
              description: typeof task === 'string' ? task : task.description,
              completed: task.completed || false
            })) : []
          }));
        }
  
        return jobData;
      });
  
      setJobs(processedJobs);
  
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setError("An error occurred while fetching jobs. Please try again later.");
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleDateChange = (event) => {
    setFilterDate(event.target.value);
  };

  const filterJobs = (jobs) => {

    return jobs.filter(job => {
      const statusMatch = selectedStatuses.includes(job.status);

      const searchMatch = (job.customerName?.toLowerCase().includes(searchTerm) ||
        job.cleanerName?.toLowerCase().includes(searchTerm));

      const dateMatch = !filterDate ||
        job.appointmentDate.toDate().toISOString().split('T')[0] === filterDate;

      return statusMatch && searchMatch && dateMatch;
    });
  };

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setIsDialogOpen(true);
  };

  const handleAvailabilityClick = (availability) => {
    setSelectedAvailability(availability);
    setIsAvailabilityModalOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedJob(null);
    setIsEditing(false);
    setEditedJob(null);
    setRecurringInstances([]);
    setShowingRecurringSeries(false);
    setSuccess('');
    setError(null);
    setApplyToRecurring(false);  // Add this line
  };

  const handleMarkCompleted = async () => {
    if (selectedJob) {
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);

        const now = new Date();
        // Use existing times or current time
        const actualStartTime = selectedJob.actualStartTime || selectedJob.startTime || now;
        const actualEndTime = selectedJob.actualEndTime || selectedJob.endTime || now;

        await updateDoc(jobRef, {
          status: JOB_STATUSES.COMPLETED,
          startTime: actualStartTime,
          endTime: actualEndTime,
          actualStartTime: actualStartTime,
          actualEndTime: actualEndTime,
          updatedAt: now
        });

        await fetchJobs();
        handleCloseDialog();
        setSuccess('Job marked as completed successfully');
      } catch (error) {
        console.error('Error marking job as completed:', error);
        setError('Failed to mark job as completed');
      }
    }
  };

  // Initialize edit mode
  const handleEditClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
  
    try {
      // Handle appointment date
      let appointmentDateTime;
      if (selectedJob.appointmentDate?.toDate) {
        appointmentDateTime = convertToOrgTz(selectedJob.appointmentDate.toDate());
      } else if (selectedJob.appointmentDate instanceof Date) {
        appointmentDateTime = convertToOrgTz(selectedJob.appointmentDate);
      } else {
        appointmentDateTime = convertToOrgTz(moment(selectedJob.appointmentDate));
      }
  
      // Format for datetime-local input
      const formattedAppointmentDate = appointmentDateTime.format('YYYY-MM-DDTHH:mm');
  
      // Handle end time conversion
      let endDateTime;
      if (selectedJob.scheduledEndTime) {
        if (selectedJob.scheduledEndTime?.toDate) {
          endDateTime = convertToOrgTz(selectedJob.scheduledEndTime.toDate());
        } else if (selectedJob.scheduledEndTime instanceof Date) {
          endDateTime = convertToOrgTz(selectedJob.scheduledEndTime);
        } else {
          endDateTime = convertToOrgTz(moment(selectedJob.scheduledEndTime));
        }
      } else {
        endDateTime = appointmentDateTime.clone().add(2, 'hours');
      }
  
      const formattedEndTime = endDateTime.format('YYYY-MM-DDTHH:mm');
  
      // Convert cleaners array to proper format
      let cleanerDetails = [];
      if (selectedJob.cleanerDetails) {
        cleanerDetails = selectedJob.cleanerDetails;
      } else if (Array.isArray(selectedJob.cleaners)) {
        cleanerDetails = selectedJob.cleaners.map(cleaner => ({
          id: typeof cleaner === 'string' ? cleaner : cleaner.id,
          name: getCleanerDisplayName(cleaner),
          email: cleaner.email || ''
        }));
      } else if (selectedJob.cleanerId) {
        cleanerDetails = [{
          id: selectedJob.cleanerId,
          name: selectedJob.cleanerName,
          email: selectedJob.cleanerEmail || ''
        }];
      }
  
      // Process cleanersActualTimes
      const cleanersActualTimes = selectedJob.cleanersActualTimes || [];
  
      setEditedJob({
        ...selectedJob,
        customerName: selectedJob.customerName,
        customerId: selectedJob.customerId,
        cleanerDetails,
        cleanersActualTimes,
        address: selectedJob.address,
        totalSize: selectedJob.totalSize || '',
        notes: selectedJob.notes || '',
        importantNotes: selectedJob.importantNotes || '',
        appointmentDate: formattedAppointmentDate,
        scheduledEndTime: formattedEndTime,
        recurring: selectedJob.recurring,
        recurrenceRule: selectedJob.recurrenceRule,
        rooms: selectedJob.rooms || [],
        serviceCost: selectedJob.serviceCost || selectedJob.price || 0,
        price: selectedJob.price || selectedJob.serviceCost || 0,
        pricingType: selectedJob.pricingType || 'hourly',
        pricingPer: selectedJob.pricingPer || 'job'
      });
    } catch (error) {
      console.error("Error during handleEditClick:", error);
    }
  };

  // Cancel edit mode
  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedJob(null);
  };

  // Handle input changes in edit mode
  const handleEditInputChange = (field, value) => {
    setEditedJob(prev => {
      const updates = { ...prev };

      switch (field) {
        case 'appointmentDate':
          // Only update if the value actually changed
          if (value !== updates.appointmentDate) {
            updates.appointmentDate = value;

            // If no end time is set, update it to be 2 hours after start by default
            if (!updates.scheduledEndTime) {
              const startDate = new Date(value);
              const endDate = new Date(startDate.getTime() + (2 * 60 * 60 * 1000));
              updates.scheduledEndTime = endDate.toISOString().slice(0, 16);
            }
          }
          break;

        case 'scheduledEndTime':
          // Only update if the value actually changed
          if (value !== updates.scheduledEndTime) {
            updates.scheduledEndTime = value;

            // Calculate duration only if we have both start and end times
            if (updates.appointmentDate && value) {
              const startDate = new Date(updates.appointmentDate);
              const endDate = new Date(value);
              updates.scheduledDuration = endDate.getTime() - startDate.getTime();
            }
          }
          break;

        default:
          updates[field] = value;
      }

      return updates;
    });
  };

  const getCleanerDisplayName = (cleaner) => {
    if (!cleaner) return '';
    
    // Check for firstName/lastName combination
    if (cleaner.firstName && cleaner.lastName) {
      return `${cleaner.firstName} ${cleaner.lastName}`;
    }
    
    // Check for displayName that's not an email
    if (cleaner.displayName && !cleaner.displayName.includes('@')) {
      return cleaner.displayName;
    }
    
    // Check for name that's not an email
    if (cleaner.name && !cleaner.name.includes('@')) {
      return cleaner.name;
    }
  
    // Get name from database for this cleaner ID
    const cleanerData = cleaners[cleaner.id];
    if (cleanerData) {
      if (cleanerData.firstName && cleanerData.lastName) {
        return `${cleanerData.firstName} ${cleanerData.lastName}`;
      }
      if (cleanerData.displayName && !cleanerData.displayName.includes('@')) {
        return cleanerData.displayName;
      }
    }
    
    // Default to email if no better name is available
    return cleaner.email || 'Unknown Cleaner';
  };

  const handleSaveEdit = async () => {
    try {
      // Convert appointment date and end time from org timezone to UTC
      const appointmentDate = convertFromOrgTz(moment(editedJob.appointmentDate)).toDate();
      const scheduledEndTime = convertFromOrgTz(moment(editedJob.scheduledEndTime)).toDate();
      const scheduledDuration = scheduledEndTime.getTime() - appointmentDate.getTime();

      // Process cleaners and their actual times
      let cleanersActualTimes = editedJob.cleanersActualTimes || [];
      cleanersActualTimes = cleanersActualTimes.map(cleaner => {
        try {
          // Ensure we're dealing with valid timestamps
          const startTime = cleaner.actualStartTime ? 
            (cleaner.actualStartTime.toDate ? 
              cleaner.actualStartTime.toDate() : 
              new Date(cleaner.actualStartTime)
            ) : null;

          const endTime = cleaner.actualEndTime ? 
            (cleaner.actualEndTime.toDate ? 
              cleaner.actualEndTime.toDate() : 
              new Date(cleaner.actualEndTime)
            ) : null;
          
          let duration = null;
          let laborCost = 0;

          if (startTime && endTime) {
            duration = (endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60); // Convert to hours
            if (editedJob.pricingType === 'hourly' && editedJob.pricingPer === 'cleaner') {
              laborCost = duration * (editedJob.pricePerHour || editedJob.serviceCost);
            }
          }

          return {
            ...cleaner,
            actualStartTime: startTime,
            actualEndTime: endTime,
            duration,
            laborCost
          };
        } catch (error) {
          console.error('Error processing cleaner times:', error);
          return cleaner;
        }
      });

      // Calculate total duration and costs
      const totalDuration = cleanersActualTimes.reduce((total, cleaner) => {
        return total + (cleaner.duration || 0);
      }, 0);

      // Find earliest start and latest end times
      const jobStartTime = cleanersActualTimes.reduce((earliest, ct) => {
        if (!ct.actualStartTime) return earliest;
        if (!earliest || ct.actualStartTime < earliest) {
          return ct.actualStartTime;
        }
        return earliest;
      }, null);

      const jobEndTime = cleanersActualTimes.reduce((latest, ct) => {
        if (!ct.actualEndTime) return latest;
        if (!latest || ct.actualEndTime > latest) {
          return ct.actualEndTime;
        }
        return latest;
      }, null);

      // Calculate total cost based on pricing type
      let totalCost = editedJob.serviceCost; // Default to flat rate
      if (editedJob.pricingType === 'hourly') {
        if (editedJob.pricingPer === 'cleaner') {
          totalCost = cleanersActualTimes.reduce((total, ct) => total + (ct.laborCost || 0), 0);
        } else {
          totalCost = totalDuration * (editedJob.pricePerHour || editedJob.serviceCost);
        }
      }

      // Prepare base update data
      const baseUpdateData = {
        customerName: editedJob.customerName,
        address: editedJob.address,
        notes: editedJob.notes || '',
        importantNotes: editedJob.importantNotes || '',
        totalSize: parseFloat(editedJob.totalSize) || 0,
        serviceCost: totalCost,
        price: totalCost,
        finalServiceCost: totalCost,
        rooms: editedJob.rooms || [],
        serviceType: editedJob.serviceType || 'one-time',
        recurring: editedJob.serviceType === 'recurring',
        recurrenceRule: editedJob.serviceType === 'recurring' ? editedJob.recurrenceRule : null,
        updatedAt: new Date(),
        organizationId: orgId,
        customerId: editedJob.customerId,
        cleanerDetails: editedJob.cleanerDetails,
        appointmentDate,
        scheduledEndTime,
        scheduledDuration,
        cleanersActualTimes,
        totalDuration,
        pricingType: editedJob.pricingType || 'hourly',
        pricingPer: editedJob.pricingPer || 'job',
        pricePerHour: editedJob.pricePerHour || editedJob.serviceCost,
        jobExpenses: cleanersActualTimes.reduce((total, ct) => total + (ct.laborCost || 0), 0),
        startTime: jobStartTime,
        endTime: jobEndTime,
        actualStartTime: jobStartTime,
        actualEndTime: jobEndTime
      };

      // Check if all cleaners have completed their times
      const allCompleted = cleanersActualTimes.every(cleaner => 
        cleaner.actualStartTime && cleaner.actualEndTime
      );

      if (allCompleted) {
        baseUpdateData.status = JOB_STATUSES.COMPLETED;
      }

      const batch = writeBatch(db);

      if (editedJob.recurring && editedJob.recurrenceGroupId) {
        if (editedJob.applyToRecurring) {
          // Update recurring service
          const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', editedJob.recurrenceGroupId);
          const recurringServiceData = {
            ...baseUpdateData,
            recurringStatus: "Active",
            lastUpdated: new Date(),
            customerId: editedJob.customerId
          };
          batch.update(recurringServiceRef, recurringServiceData);

          // Update all future instances
          const jobsRef = collection(db, 'organizations', orgId, 'jobs');
          const currentDate = new Date();
          const recurringJobsQuery = query(
            jobsRef,
            where('recurrenceGroupId', '==', editedJob.recurrenceGroupId),
            where('appointmentDate', '>=', convertFromOrgTz(moment(editedJob.appointmentDate)).toDate())
          );

          const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
          recurringJobsSnapshot.docs.forEach(doc => {
            const instanceData = doc.data();
            if (!instanceData.status || instanceData.status.toLowerCase() === 'pending') {
              batch.update(doc.ref, baseUpdateData);
            }
          });
        } else {
          // Only update the current job
          const jobRef = doc(db, 'organizations', orgId, 'jobs', editedJob.id);
          batch.update(jobRef, baseUpdateData);
        }
      } else {
        // Non-recurring job update
        const jobRef = doc(db, 'organizations', orgId, 'jobs', editedJob.id);
        batch.update(jobRef, baseUpdateData);
      }

      await batch.commit();
      await fetchJobs();

      setIsEditing(false);
      setEditedJob(null);
      handleCloseDialog();
      setSuccess(`Job${editedJob.applyToRecurring ? 's' : ''} updated successfully.`);

    } catch (error) {
      console.error('Failed to save job:', error);
      setError("Failed to update job: " + error.message);
    }
  };

  const handleRevertJobStatus = async () => {
    try {
      if (!selectedJob || !orgId) {
        return;
      }

      const confirmReopen = window.confirm(
        'Are you sure you want to reopen this job? This will clear the completion status and all time records.'
      );

      if (!confirmReopen) return;

      const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);

      await updateDoc(jobRef, {
        status: JOB_STATUSES.PENDING,
        startTime: null,
        endTime: null,
        actualStartTime: null,
        actualEndTime: null,
        actualDuration: null,
        updatedAt: new Date()
      });

      await fetchJobs();
      handleCloseDialog();
      setSuccess('Job reopened successfully');
    } catch (error) {
      console.error('Error reopening job:', error);
      setError('Failed to reopen job');
    }
  };


  // Add a useEffect to force re-render when jobs change
  useEffect(() => {
  }, [jobs]);

  const handleDeleteJob = async () => {
    if (selectedJob) {
      const confirmDelete = window.confirm("Are you sure you want to delete this job?");
      if (!confirmDelete) return;
      try {
        const jobRef = doc(db, 'organizations', orgId, 'jobs', selectedJob.id);
        await deleteDoc(jobRef);
        fetchJobs();
        handleCloseDialog();
      } catch (error) {
        console.error("Error deleting job:", error);
        setError("Failed to delete job.");
      }
    }
  };

  const handleDeleteRecurringJob = async (recurrenceGroupId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all instances of this recurring job?"
    );
  
    if (!confirmDelete) return;
  
    try {
      const batch = writeBatch(db);
  
      // 1. Update the recurring service status to canceled
      const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', recurrenceGroupId);
      const recurringServiceDoc = await getDoc(recurringServiceRef);
      
      if (recurringServiceDoc.exists()) {
        batch.update(recurringServiceRef, {
          recurringStatus: 'Cancelled',
          lastUpdated: new Date(),
          updatedAt: new Date(),
          cancellationDate: new Date(),
          status: 'Cancelled'  // Update status field as well
        });
      }
  
      // 2. Get and delete all associated jobs (regardless of status)
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringJobsQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId)
      );
  
      const recurringJobsSnapshot = await getDocs(recurringJobsQuery);
  
      // Add each job deletion to the batch
      recurringJobsSnapshot.docs.forEach(doc => {
        batch.delete(doc.ref);
      });
  
      // Commit all changes in one batch
      await batch.commit();
  
      // Refresh the jobs list and close dialog
      await fetchJobs();
      handleCloseDialog();
      setSuccess('Recurring job series cancelled and instances deleted successfully.');
  
    } catch (error) {
      console.error("Error handling recurring job deletion:", error);
      setError("Failed to process recurring job deletion: " + error.message);
    }
  };

  const handleApplyToRecurringChange = (checked) => {
    setApplyToRecurring(checked);
    setEditedJob(prev => ({
      ...prev,
      applyToRecurring: checked
    }));
  };

  // Add/Edit Rooms for Jobs
  const handleAddRooms = (roomsToAdd) => {
    setEditedJob(prev => ({
      ...prev,
      rooms: roomsToAdd
    }));
  };

  // Add this handler for room deletion
  const handleDeleteRoom = (roomIndex) => {
    setEditedJob(prev => ({
      ...prev,
      rooms: prev.rooms.filter((_, index) => index !== roomIndex)
    }));
  };

  const fetchRecurringInstances = async (recurrenceGroupId) => {
    try {
      const jobsRef = collection(db, 'organizations', orgId, 'jobs');
      const recurringQuery = query(
        jobsRef,
        where('recurrenceGroupId', '==', recurrenceGroupId),
        orderBy('appointmentDate', 'asc')
      );

      const snapshot = await getDocs(recurringQuery);
      const instances = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setRecurringInstances(instances);
      setShowingRecurringSeries(true);
    } catch (error) {
      console.error("Error fetching recurring instances:", error);
      setError("Failed to fetch recurring instances.");
    }
  };

  const generateMoreInstances = async (job) => {
    try {
      if (!job.recurring || !job.recurrenceRule) return;
  
      let rruleString = job.recurrenceRule;
      const dtstart = job.appointmentDate.toDate();
      const rule = rrulestr(rruleString, { dtstart });
      
      const lastInstance = recurringInstances[recurringInstances.length - 1];
      const startDate = lastInstance.appointmentDate.toDate();
      const endDate = new Date(startDate);
      endDate.setMonth(endDate.getMonth() + 3);
      const newDates = rule.between(startDate, endDate);
  
      const batch = writeBatch(db);
  
      const baseJobData = {
        address: job.address,
        billingAddress: job.billingAddress,
        cleanerDetails: job.cleanerDetails,
        cleanerEmail: job.cleanerEmail,
        cleanerId: job.cleanerId,
        cleanerName: job.cleanerName,
        cleaners: job.cleaners,
        customerId: job.customerId,
        customerName: job.customerName,
        formattedAddress: job.formattedAddress,
        importantNotes: job.importantNotes || '',
        jobType: job.jobType,
        latitude: job.latitude,
        longitude: job.longitude,
        notes: job.notes || '',
        organizationId: job.organizationId,
        price: job.price,
        recurrenceGroupId: job.recurrenceGroupId,
        recurrenceRule: job.recurrenceRule,
        recurring: true,
        recurringStatus: "Active",
        recurringStartDate: job.recurringStartDate,
        rooms: job.rooms || [],
        scheduledDuration: job.scheduledDuration,
        serviceAddress: job.serviceAddress,
        serviceCost: job.serviceCost,
        serviceType: "recurring",
        status: "Pending",
        timezone: job.timezone,
        totalSize: job.totalSize,
        updatedAt: new Date()
      };
  
      // Update recurring service
      const recurringServiceRef = doc(db, 'organizations', orgId, 'recurringServices', job.recurrenceGroupId);
      const recurringDoc = await getDoc(recurringServiceRef);
  
      if (!recurringDoc.exists()) {
        batch.set(recurringServiceRef, {
          ...baseJobData,
          lastUpdated: new Date(),
        });
      } else {
        batch.update(recurringServiceRef, {
          ...baseJobData,
          lastUpdated: new Date(),
        });
      }
  
      // Create new instances
      newDates.forEach((date) => {
        const jobRef = doc(collection(db, 'organizations', orgId, 'jobs'));
        const instanceDate = convertToOrgTz(date);
        const newInstanceStart = instanceDate
          .hour(dtstart.getHours())
          .minute(dtstart.getMinutes());
  
        const scheduledEndTime = moment(newInstanceStart)
          .add(job.scheduledDuration, 'milliseconds');
  
        batch.set(jobRef, {
          ...baseJobData,
          appointmentDate: convertFromOrgTz(newInstanceStart).toDate(),
          scheduledEndTime: convertFromOrgTz(scheduledEndTime).toDate(),
          createdAt: new Date(),
          updatedAt: new Date()
        });
      });
  
      await batch.commit();
      await fetchRecurringInstances(job.recurrenceGroupId);
      setSuccess('Generated new instances successfully.');
    } catch (error) {
      console.error('Error generating more instances:', error);
      console.error('Error stack:', error.stack);
      setError('Failed to generate more instances: ' + error.message);
    }
  };

  const formatRecurrenceRule = (rule) => {
    if (!rule) return 'No recurrence pattern';

    const frequency = rule.match(/FREQ=([^;]*)/)?.[1];
    const interval = rule.match(/INTERVAL=([^;]*)/)?.[1] || '1';
    const byDay = rule.match(/BYDAY=([^;]*)/)?.[1];

    let description = '';

    switch (frequency) {
      case 'DAILY':
        description = `Every ${interval === '1' ? 'day' : `${interval} days`}`;
        break;
      case 'WEEKLY':
        if (byDay) {
          const days = byDay.split(',').map(day => {
            const dayNames = {
              MO: 'Monday', TU: 'Tuesday', WE: 'Wednesday',
              TH: 'Thursday', FR: 'Friday', SA: 'Saturday', SU: 'Sunday'
            };
            return dayNames[day];
          });
          description = `Every ${interval === '1' ? 'week' : `${interval} weeks`} on ${days.join(', ')}`;
        } else {
          description = `Every ${interval === '1' ? 'week' : `${interval} weeks`}`;
        }
        break;
      case 'MONTHLY':
        description = `Every ${interval === '1' ? 'month' : `${interval} months`}`;
        break;
      case 'YEARLY':
        description = `Every ${interval === '1' ? 'year' : `${interval} years`}`;
        break;
      default:
        description = rule;
    }

    return description;
  };

  // Function to fetch email templates
  const fetchEmailTemplates = async () => {
    try {
      const templatesRef = collection(db, 'organizations', orgId, 'emailTemplates');
      const templatesSnap = await getDocs(templatesRef);
      const templates = templatesSnap.docs.map(doc => {
        const data = {
          documentId: doc.id,
          ...doc.data()
        };
        return data;
      });
      setEmailTemplates(templates);
    } catch (error) {
      console.error('Error fetching email templates:', error);
      setError('Failed to load email templates');
    }
  };

  const getAuthToken = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken(true);
      return token;
    } catch (err) {
      console.error('Error getting auth token:', err);
      throw err;
    }
  };

  // Function to send email
  const handleSendEmail = async (emailData) => {
    try {
      if (!selectedJob) {
        throw new Error('No job selected');
      }
  
      const token = await getAuthToken();
  
      // Fetch customer data from the customers collection
      const customerRef = doc(db, 'organizations', selectedJob.organizationId, 'customers', selectedJob.customerId);
      const customerSnap = await getDoc(customerRef);
  
      if (!customerSnap.exists()) {
        throw new Error('Customer not found');
      }
  
      const customerData = customerSnap.data();
  
      if (!customerData.email) {
        throw new Error('Customer email not found');
      }
  
      // Create the email in the job's mail subcollection
      const mailRef = collection(
        db, 
        'organizations', 
        selectedJob.organizationId, 
        'jobs', 
        selectedJob.id, 
        'mail'
      );
  
      // Add the email to the job's mail subcollection
      await addDoc(mailRef, {
        to: customerData.email,
        recipientName: customerData.name || customerData.firstName,
        subject: emailData.subject,
        content: emailData.content,
        templateId: emailData.documentId,
        status: 'sent',
        createdAt: serverTimestamp(),
        createdBy: auth.currentUser.uid,
        tracking: {
          opens: 0,
          linkClicks: [],
          lastOpened: null
        }
      });
  
      // Send the actual email through your notification service
      await notificationsApi.sendEmail(token, {
        orgId: selectedJob.organizationId,
        documentId: emailData.documentId,
        recipientEmail: customerData.email,
        recipientName: customerData.name || customerData.firstName,
        subject: emailData.subject,
        content: emailData.content,
        jobData: {
          jobId: selectedJob.id,
          appointmentDate: selectedJob.appointmentDate?.toDate?.() || selectedJob.appointmentDate,
          scheduledStartTime: selectedJob.scheduledStartTime?.toDate?.() || selectedJob.scheduledStartTime,
          scheduledEndTime: selectedJob.scheduledEndTime?.toDate?.() || selectedJob.scheduledEndTime,
          customerName: customerData.name || `${customerData.firstName} ${customerData.lastName}`,
          customerEmail: customerData.email,
          customerPhone: customerData.phone || customerData.phoneNumber,
          address: selectedJob.address,
          cleanerDetails: selectedJob.cleanerDetails || [],
          serviceCost: selectedJob.serviceCost,
          serviceType: selectedJob.serviceType,
          status: selectedJob.status,
          totalSize: selectedJob.totalSize,
          notes: selectedJob.notes,
          importantNotes: selectedJob.importantNotes,
        },
      });
  
      setSuccess('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
      }
      setError(`Failed to send email: ${error.message}`);
    }
  };

  // Use useEffect to fetch templates when component mounts
  useEffect(() => {
    fetchEmailTemplates();
  }, [orgId]);

  useEffect(() => {
    const fetchCustomStatuses = async () => {
      if (!orgId) return;

      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const scheduleSettings = orgDoc.data().scheduleSettings;
          if (scheduleSettings?.jobStatuses) {
            setCustomJobStatuses(scheduleSettings.jobStatuses);
          }
        }
      } catch (error) {
        console.error('Error fetching custom statuses:', error);
      }
    };

    fetchCustomStatuses();
  }, [orgId]);

  const handleUpdateStatus = async (jobId, newStatus) => {
    try {
      const token = await getAuthToken();

      await notificationsApi.updatedJobStatus(token, {
        orgId,
        jobId,
        status: newStatus,
      });

      await fetchJobs();
      setSuccess('Status updated successfully!');
    } catch (error) {
      console.error('Error updating status:', error);
      setError(`Failed to update status: ${error.message}`);
    }
  };

  const handleUpdate = async () => {
    await fetchJobs();
  };

  const handleAvailabilityStatusChange = async (availabilityData) => {
    try {
      setUpdating(true);

      const cleanerRef = doc(db, 'organizations', orgId, 'cleaners', availabilityData.cleaner.id);
      const startInOrgTz = convertToOrgTz(availabilityData.start);
      const endInOrgTz = convertToOrgTz(availabilityData.end);
      const availabilityKey = `${startInOrgTz.format('YYYY-MM-DD-HH-mm')}-${endInOrgTz.format('YYYY-MM-DD-HH-mm')}`;

      if (availabilityData.status) {
        await updateDoc(cleanerRef, {
          [`availabilityStatuses.${availabilityKey}`]: {
            status: availabilityData.status,
            startTime: convertFromOrgTz(availabilityData.start).toDate(),
            endTime: convertFromOrgTz(availabilityData.end).toDate(),
            duration: availabilityData.duration,
            updatedAt: new Date(),
            updatedBy: user.uid
          }
        });

        setAvailabilityStatuses(prev => ({
          ...prev,
          [availabilityData.cleaner.id]: {
            ...(prev[availabilityData.cleaner.id] || {}),
            [availabilityKey]: {
              status: availabilityData.status,
              startTime: convertFromOrgTz(availabilityData.start).toDate(),
              endTime: convertFromOrgTz(availabilityData.end).toDate(),
              duration: availabilityData.duration,
              updatedAt: new Date(),
              updatedBy: user.uid
            }
          }
        }));
      } else {
        await updateDoc(cleanerRef, {
          [`availabilityStatuses.${availabilityKey}`]: deleteField()
        });

        const newStatuses = { ...availabilityStatuses };
        if (newStatuses[availabilityData.cleaner.id]) {
          delete newStatuses[availabilityData.cleaner.id][availabilityKey];
        }
        setAvailabilityStatuses(newStatuses);
      }

      setIsAvailabilityModalOpen(false);
      setSelectedAvailability(null);

    } catch (error) {
      console.error('Error updating availability status:', error);
    } finally {
      setUpdating(false);
    }
  };

  if (newVersion) {
    return (
      <>
        {/* Error and Success Messages */}
        {error && (
          <div className={styles.errorMessage}>{error}</div>
        )}
        {success && (
          <div className={styles.successMessage}>{success}</div>
        )}

        <JobOverviewProvider
          jobs={jobs}
          timeOffRequests={timeOffRequests}
          findAvailabilityGaps={findAvailabilityGaps}
          cleaners={cleaners}
        >
          <div className={styles.container}>
            <ActionCenter
              timeOffRequests={timeOffRequests}
              availabilityStatuses={availabilityStatuses}
              onAvailabilityClick={handleAvailabilityClick}
            />
            <Calendar
              jobs={jobs}
              timeOffRequests={timeOffRequests}
              onJobClick={handleJobClick}
              onAvailabilityClick={handleAvailabilityClick}
            />
            <FilterCenter /> 
          </div>
        </JobOverviewProvider>

        {/* Availability Dialog */}
        <AvailabilityModal
          open={isAvailabilityModalOpen}
          onClose={() => {
            setIsAvailabilityModalOpen(false);
            setSelectedAvailability(null);
          }}
          availabilityData={selectedAvailability}
          customJobStatuses={customJobStatuses}
          onStatusChange={handleAvailabilityStatusChange}
          loading={updating}
        />

        {/* Job Dialog */}
        {isDialogOpen && (
          <div className={styles.modalOverlay}>
            <JobDialog
              open={isDialogOpen}
              onClose={handleCloseDialog}
              job={selectedJob}
              isEditing={isEditing}
              handleEditClick={handleEditClick}
              handleDeleteJob={handleDeleteJob}
              handleMarkCompleted={handleMarkCompleted}
              handleDeleteRecurringJob={handleDeleteRecurringJob}
              fetchRecurringInstances={fetchRecurringInstances}
              showingRecurringSeries={showingRecurringSeries}
              recurringInstances={recurringInstances}
              generateMoreInstances={generateMoreInstances}
              handleSaveEdit={handleSaveEdit}
              handleCancelEdit={handleCancelEdit}
              editedJob={editedJob}
              handleEditInputChange={handleEditInputChange}
              handleAddRooms={handleAddRooms}
              handleDeleteRoom={handleDeleteRoom}
              openAddRoomsModal={openAddRoomsModal}
              setOpenAddRoomsModal={setOpenAddRoomsModal}
              availableRooms={availableRooms}
              formatRecurrenceRule={formatRecurrenceRule}
              getStatusDetails={getStatusDetails}
              handleApplyToRecurringChange={handleApplyToRecurringChange}
              applyToRecurring={applyToRecurring}
              handleRevertJobStatus={handleRevertJobStatus}
              cleaners={cleaners}
              emailTemplates={emailTemplates}
              onSendEmail={handleSendEmail}
              customJobStatuses={customJobStatuses}
              defaultJobStatuses={DEFAULT_JOB_STATUSES}
              handleUpdateStatus={handleUpdateStatus}
              onUpdate={handleUpdate}
            />
          </div>
        )}

        {/* Add Rooms Modal */}
        {openAddRoomsModal && (
          <div className={styles.modalOverlay}>
            <AddRoomsModal
              open={openAddRoomsModal}
              handleClose={() => setOpenAddRoomsModal(false)}
              availableRooms={availableRooms}
              handleAddRooms={handleAddRooms}
              existingRooms={editedJob?.rooms || []}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div className={styles.JobsOverview}>
      {/* Error and Success Messages */}
      {error && (
        <div className={styles.errorMessage}>{error}</div>
      )}
      {success && (
        <div className={styles.successMessage}>{success}</div>
      )}

      {/* Filters Section */}
      <div className={styles.searchSection}>
        <div className={styles.filterContainer}>
          {/* Search Field */}
          <div className={`${styles.inputGroup}`}>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className={styles.input}
              placeholder="Search by Customer or Cleaner"
            />
          </div>

          {/* Date Filter */}
          <div className={`${styles.inputGroup} ${styles.dateGroup}`}>
            <input
              type="date"
              value={filterDate}
              onChange={handleDateChange}
              className={styles.input}
            />
          </div>

          {/* Status Filter */}
          <div className={`${styles.inputGroup} ${styles.statusGroup}`}>
            <div className={styles.statusSelect}>
              {availableStatuses.map((status) => (
                <label key={status.value} className={styles.statusCheckbox}>
                  <input
                    type="checkbox"
                    checked={selectedStatuses.includes(status.value)}
                    onChange={() => {
                      setSelectedStatuses(prev =>
                        prev.includes(status.value)
                          ? prev.filter(s => s !== status.value)
                          : [...prev, status.value]
                      );
                    }}
                  />
                  <span
                    className={styles.statusLabel}
                    style={{ backgroundColor: status.chipColor }}
                  >
                    {status.label}
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Weekly View */}
      <div className={styles.weeklyView}>
        <WeeklyJobsView
          jobs={filterJobs(jobs)}
          onJobClick={handleJobClick}
          statusColors={availableStatuses}
          getStatusDetails={getStatusDetails}
          orgId={orgId}
          availableTimeBlocks={availableTimeBlocks}
          customJobStatuses={customJobStatuses}
          defaultJobStatuses={DEFAULT_JOB_STATUSES}
        />
      </div>

      {/* Job Dialog */}
      {isDialogOpen && (
        <div className={styles.modalOverlay}>
          <JobDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            job={selectedJob}
            isEditing={isEditing}
            handleEditClick={handleEditClick}
            handleDeleteJob={handleDeleteJob}
            handleMarkCompleted={handleMarkCompleted}
            handleDeleteRecurringJob={handleDeleteRecurringJob}
            fetchRecurringInstances={fetchRecurringInstances}
            showingRecurringSeries={showingRecurringSeries}
            recurringInstances={recurringInstances}
            generateMoreInstances={generateMoreInstances}
            handleSaveEdit={handleSaveEdit}
            handleCancelEdit={handleCancelEdit}
            editedJob={editedJob}
            handleEditInputChange={handleEditInputChange}
            handleAddRooms={handleAddRooms}
            handleDeleteRoom={handleDeleteRoom}
            openAddRoomsModal={openAddRoomsModal}
            setOpenAddRoomsModal={setOpenAddRoomsModal}
            availableRooms={availableRooms}
            formatRecurrenceRule={formatRecurrenceRule}
            getStatusDetails={getStatusDetails}
            handleApplyToRecurringChange={handleApplyToRecurringChange}
            applyToRecurring={applyToRecurring}
            handleRevertJobStatus={handleRevertJobStatus}
            cleaners={cleaners}
            emailTemplates={emailTemplates}
            onSendEmail={handleSendEmail}
            customJobStatuses={customJobStatuses}
            defaultJobStatuses={DEFAULT_JOB_STATUSES}
            handleUpdateStatus={handleUpdateStatus}
            onUpdate={handleUpdate}
          />
        </div>
      )}

      {/* Add Rooms Modal */}
      {openAddRoomsModal && (
        <div className={styles.modalOverlay}>
          <AddRoomsModal
            open={openAddRoomsModal}
            handleClose={() => setOpenAddRoomsModal(false)}
            availableRooms={availableRooms}
            handleAddRooms={handleAddRooms}
            existingRooms={editedJob?.rooms || []}
          />
        </div>
      )}
    </div>
  );
};

export default JobsOverview;
