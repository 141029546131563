// src/components/Invoices/Settings/SettingsNavigation.jsx
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Tabs, Tab } from '@mui/material';
import { 
  Settings as SettingsIcon,
  LocalOffer as ProductsIcon,
  Receipt as TaxIcon,
  Email as EmailIcon,
  Description as TemplateIcon
} from '@mui/icons-material';

const SettingsNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Get the base settings path (e.g., /invoices/settings/templates/preview -> templates)
  const getCurrentValue = () => {
    const pathParts = location.pathname.split('/');
    const settingsIndex = pathParts.indexOf('settings');
    if (settingsIndex !== -1 && pathParts[settingsIndex + 1]) {
      return `/invoices/settings/${pathParts[settingsIndex + 1]}`;
    }
    return '/invoices/settings/general';
  };

  const handleChange = (event, newPath) => {
    navigate(newPath);
  };

  const tabs = [
    {
      label: 'General',
      path: '/invoices/settings/general',
      icon: <SettingsIcon />
    },
    {
      label: 'Products',
      path: '/invoices/settings/products',
      icon: <ProductsIcon />
    },
    {
      label: 'Tax',
      path: '/invoices/settings/tax',
      icon: <TaxIcon />
    },
    {
      label: 'Email',
      path: '/invoices/settings/email',
      icon: <EmailIcon />
    },
    {
      label: 'Templates',
      path: '/invoices/settings/templates',
      icon: <TemplateIcon />
    }
  ];

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
      <Tabs 
        value={getCurrentValue()}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        aria-label="invoice settings tabs"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.path}
            label={tab.label}
            value={tab.path}
            icon={tab.icon}
            iconPosition="start"
            sx={{
              minHeight: 48,
              textTransform: 'none',
              fontSize: '0.875rem',
              '& .MuiTab-iconWrapper': {
                marginRight: 1,
                marginBottom: '0 !important'
              }
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default SettingsNavigation;