// /src/components/CustomerManagement.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc, query, where, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';
import { 
  Typography, Button, List, ListItem, ListItemText, Box, CircularProgress, Alert,
  IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Pagination, Paper, Chip
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ClientCSVUpload from './ClientCSVUpload';
import CreateCustomerModal from './CreateCustomerModal';
import styles from '../styles/CustomerManagement.module.css';
import { fetchGeocodeData } from '../utils/api';
import { useCustomerStatus } from '../hooks/useCustomerStatus';

const ITEMS_PER_PAGE = 25;

const CustomerManagement = ({ orgId }) => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  // const [customFields, setCustomFields] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { CUSTOMER_STATUSES, getStatusColor, updateAllCustomerStatuses } = useCustomerStatus();

  useEffect(() => {
    fetchCustomers();
    // fetchCustomFields();
  }, []);

  const handleCustomerClick = (e, customer) => {
    // Prevent triggering click when clicking edit/delete buttons
    if (e.target.closest('.MuiIconButton-root')) {
      e.stopPropagation();
      return;
    }
    navigate(`/jobs/clients/${customer.id}`);
  };

  const fetchCustomers = async () => {
    try {
      // First, update all customer statuses
      await updateAllCustomerStatuses(orgId);
      
      // Then fetch customers as normal
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      const snapshot = await getDocs(customersRef);
      const customerList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCustomers(customerList.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      setError('Error fetching customers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  // const fetchCustomFields = async () => {
  //   try {
  //     const fieldsRef = collection(db, 'organizations', orgId, 'customFields');
  //     const snapshot = await getDocs(fieldsRef);
  //     const fieldsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //     setCustomFields(fieldsList);
  //   } catch (err) {
  //     console.error('Error fetching custom fields:', err);
  //     setCustomFields([]);
  //   }
  // };

  // Filter customers based on search term
  const filteredCustomers = customers.filter(customer => 
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.serviceAddress?.complete?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.billingAddress?.complete?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEditCustomer = (customer) => {
    // Check if billing address is same as service address
    const isSameAddress = customer.serviceAddress === customer.billingAddress;
    
    // Transform customer data to match form structure
    const editData = {
      firstName: customer.firstName || '',
      lastName: customer.lastName || '',
      email: customer.email || '',
      phone: customer.phone || '',
      serviceAddress: {
        line1: customer.serviceAddress?.line1 || '',
        line2: customer.serviceAddress?.line2 || '',
        city: customer.serviceAddress?.city || '',
        state: customer.serviceAddress?.state || '',
        zip: customer.serviceAddress?.zip || ''
      },
      useSameAddress: isSameAddress, // Set based on address comparison
      billingAddress: {
        // If addresses are the same, leave billing empty (it will copy from service)
        // If different, use the billing address
        line1: isSameAddress ? '' : customer.billingAddress?.line1 || '',
        line2: isSameAddress ? '' : customer.billingAddress?.line2 || '',
        city: isSameAddress ? '' : customer.billingAddress?.city || '',
        state: isSameAddress ? '' : customer.billingAddress?.state || '',
        zip: isSameAddress ? '' : customer.billingAddress?.zip || ''
      },
      customFieldValues: customer.customFieldValues || {},
      notes: customer.notes || '',
      isPrivateNote: customer.isPrivateNote || false
    };
    setEditingCustomer({ ...customer, ...editData });
    setOpenEditModal(true);
  };

  const handleUpdateCustomer = async (updatedData) => {
    setLoading(true);
    try {
      const customerRef = doc(db, 'organizations', orgId, 'customers', editingCustomer.id);
      
      const customerData = {
        name: `${updatedData.firstName} ${updatedData.lastName}`.trim(),
        firstName: updatedData.firstName,
        lastName: updatedData.lastName,
        email: updatedData.email || '',
        phone: updatedData.phone || '',
        companyName: updatedData.companyName || '',
        serviceAddress: updatedData.serviceAddress,
        billingAddress: updatedData.useSameAddress ? updatedData.serviceAddress : updatedData.billingAddress,
        customFieldValues: updatedData.customFieldValues || {},
        notes: updatedData.notes || '',
        isPrivateNote: updatedData.isPrivateNote || false,
        useSameAddress: updatedData.useSameAddress,
        updatedAt: new Date()
      };
  
      await updateDoc(customerRef, customerData);
      await fetchCustomers();
      setOpenEditModal(false);
      setEditingCustomer(null);
    } catch (err) {
      console.error('Error updating customer:', err);
      setError('Error updating customer: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    if (window.confirm('Are you sure you want to delete this customer?')) {
      setLoading(true);
      try {
        await deleteDoc(doc(db, 'organizations', orgId, 'customers', customerId));
        await fetchCustomers();
      } catch (err) {
        setError('Error deleting customer: ' + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCustomersCreated = async (newCustomers) => {
    setLoading(true);
    try {
      const customersRef = collection(db, 'organizations', orgId, 'customers');
      for (const customer of newCustomers) {
        await addDoc(customersRef, customer);
      }
      fetchCustomers();
      alert(`${newCustomers.length} customers added successfully!`);
    } catch (err) {
      setError('Error adding customers: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateCustomer = async (newCustomerData) => {
    setLoading(true);
    try {
      const fullName = `${newCustomerData.firstName} ${newCustomerData.lastName}`.trim();
      
      // Basic address formatting without extra location data
      const serviceAddress = newCustomerData.serviceAddress?.line1 ? 
        `${newCustomerData.serviceAddress.line1}${
          newCustomerData.serviceAddress.line2 ? ` ${newCustomerData.serviceAddress.line2}` : ''
        }, ${newCustomerData.serviceAddress.city}, ${newCustomerData.serviceAddress.state} ${newCustomerData.serviceAddress.zip}` : '';

      // Get geolocation data
      let geoData = null;
      if (serviceAddress) {
        try {
          geoData = await fetchGeocodeData(serviceAddress);
        } catch (error) {
          console.error('Geocoding error:', error);
        }
      }

      // Use same address if checked, otherwise use billing address
      const billingAddress = newCustomerData.useSameAddress ? 
        serviceAddress : 
        newCustomerData.billingAddress?.line1 ? 
          `${newCustomerData.billingAddress.line1}${
            newCustomerData.billingAddress.line2 ? ` ${newCustomerData.billingAddress.line2}` : ''
          }, ${newCustomerData.billingAddress.city}, ${newCustomerData.billingAddress.state} ${newCustomerData.billingAddress.zip}` : '';

      const customerData = {
        name: fullName,
        firstName: newCustomerData.firstName || '',
        lastName: newCustomerData.lastName || '',
        email: newCustomerData.email || '',
        phone: newCustomerData.phone || '',
        serviceAddress,
        billingAddress,
        customFieldValues: newCustomerData.customFieldValues || {},
        notes: newCustomerData.notes || '',
        isPrivateNote: newCustomerData.isPrivateNote || false,
        createdAt: new Date(),
        updatedAt: new Date()
      };

      // Add geolocation data if available
      if (geoData) {
        customerData.addresses = [{
          formattedAddress: geoData.formattedAddress,
          latitude: geoData.latitude,
          longitude: geoData.longitude,
          lastUpdated: new Date()
        }];
      }

      const customersRef = collection(db, 'organizations', orgId, 'customers');
      await addDoc(customersRef, customerData);
      await fetchCustomers();
      setOpenCreateModal(false);
    } catch (err) {
      setError('Error adding customer: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <CircularProgress className={styles.loader} />;
  if (error) return <Alert severity="error" className={styles.alert}>{error}</Alert>;

  const paginatedCustomers = filteredCustomers.slice(
    (page - 1) * ITEMS_PER_PAGE,
    page * ITEMS_PER_PAGE
  );

  return (
    <Box className={styles.container}>
      <Typography variant="h4" className={styles.title}>
        Customer Management
      </Typography>
      
      <Box className={styles.actions}>
        <Button 
          variant="contained" 
          onClick={() => setOpenCreateModal(true)}
          className={styles.createButton}
        >
          Create New Customer
        </Button>
        <Box className={styles.uploadSection}>
          <ClientCSVUpload 
            onCustomersCreated={handleCustomersCreated} 
            className={styles.uploadButton}
          />
          <Typography variant="body2" className={styles.csvInfo}>
            (CSV headers: FirstName, LastName, Email, Phone, ServiceAddress, BillingAddress, Notes)
          </Typography>
        </Box>
      </Box>

      <Box className={styles.searchContainer}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search customers..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          className={styles.searchField}
        />
      </Box>

      <Paper className={styles.customersList}>
        <List>
          {paginatedCustomers.map((customer) => (
            <ListItem 
              key={customer.id}
              className={styles.customerItem}
              onClick={(e) => handleCustomerClick(e, customer)}
              sx={{ cursor: 'pointer' }}
              secondaryAction={
                <Box className={styles.itemActions} onClick={e => e.stopPropagation()}>
                  <IconButton 
                    onClick={() => handleEditCustomer(customer)}
                    className={styles.editButton}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    onClick={() => handleDeleteCustomer(customer.id)}
                    className={styles.deleteButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              }
            >
            <ListItemText 
              primary={
                <Box display="flex" alignItems="center" gap={1}>
                  {customer.name}
                  {customer.customerStatus && (
                    <Chip 
                      label={customer.customerStatus}
                      size="small"
                      color={getStatusColor(customer.customerStatus)}
                      variant="outlined"
                    />
                  )}
                </Box>
              }
              secondary={
                <>
                  <Typography component="span" variant="body2" color="textSecondary">
                    {customer.email && `${customer.email} • `}
                    {customer.phone && `${customer.phone} • `}
                    {customer.serviceAddress}
                  </Typography>
                </>
              }
              className={styles.customerText}
            />
          </ListItem>
          ))}
        </List>
        
        <Box className={styles.pagination}>
          <Pagination 
            count={Math.ceil(filteredCustomers.length / ITEMS_PER_PAGE)}
            page={page}
            onChange={(e, value) => setPage(value)}
            color="primary"
          />
        </Box>
      </Paper>

      {/* Create Modal */}
      <CreateCustomerModal
        open={openCreateModal}
        handleClose={() => setOpenCreateModal(false)}
        handleCreateCustomer={handleCreateCustomer}
        // customFields={customFields}
      />

      {/* Edit Modal */}
      <CreateCustomerModal
        open={openEditModal}
        handleClose={() => {
          setOpenEditModal(false);
          setEditingCustomer(null);
        }}
        handleCreateCustomer={handleUpdateCustomer}
        // customFields={customFields}
        isEditing={true}
        initialData={editingCustomer}
      />
      </Box>
  );
};

export default CustomerManagement;